import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';

// React Router
import { useHistory } from 'react-router-dom';

// FormatJS (Internationalization)
import { useIntl } from 'react-intl';

// Store
import { getFilteredCart } from '../../../../store/cart/cart';

// Components
import Cart from './Cart/cart';
import Delivery from './Delivery/delivery';
import Payment from './Payment/payment';

// function getSteps() {
//     // return ['CART', 'DELIVERY', 'PAYMENT'];
//     return [
//         intl.formatMessage({ id: 'stepper.cart', defaultMessage: 'CART' }), 
//         intl.formatMessage({ id: 'stepper.delivery', defaultMessage: 'DELIVERY' }), 
//         intl.formatMessage({ id: 'stepper.payment', defaultMessage: 'PAYMENT' }), 
//     ]
// }

const Checkout = (props) => {
    const history = useHistory();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [totalPrice, setTotalPrice] = useState(0)
    const [orient, setOrientation] = useState('horizontal')
    const steps = [
        intl.formatMessage({ id: 'stepper.cart', defaultMessage: 'CART' }), 
        intl.formatMessage({ id: 'stepper.delivery', defaultMessage: 'DELIVERY' }), 
        intl.formatMessage({ id: 'stepper.payment', defaultMessage: 'PAYMENT' }), 
    ];

    var productsInCart = useSelector(getFilteredCart)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        if(productsInCart.length > 0){
            var tp = productsInCart.reduce((acc, val)=>
                acc + (Math.ceil(parseInt(val.detailsToBuy.price) - (parseInt(val.detailsToBuy.price) * val.detailsToBuy.discount / 100)) * val.qtyToBuy),0
            )
            setTotalPrice(tp)
        } else {
            history.push('/')
        }

        if(props._isMobile) {
            setOrientation('vertical')
        }
    },[productsInCart])

    function isStepOptional(step){
        return step === 1;
    };

    function isStepSkipped(step){
        return skipped.has(step);
    };

    function handleNext(){
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    
    function handleBack(){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    function handleSkip(){
        if (!isStepOptional(activeStep)) {
          // You probably want to guard against something like this,
          // it should never occur unless someone's actively trying to break something.
          throw new Error("You can't skip a step that isn't optional.");
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
          const newSkipped = new Set(prevSkipped.values());
          newSkipped.add(activeStep);
          return newSkipped;
        });
    };
    
    function handleReset(){
        setActiveStep(0);
    };

    function setThisStep(index){
        if(index < activeStep) {
            setActiveStep(index)
        }
    }

    const CheckoutComponent = () =>{
        if(activeStep === 0){
            return <Cart _productsInCart={productsInCart} _handleSetDeliveryPage={handleNext} _totalPrice={totalPrice}></Cart>
        } else if(activeStep === 1) {
            return <Delivery _handleSetPaymentPage={handleNext} _totalPrice={totalPrice}></Delivery>
        } else if(activeStep === 2) {
            return <Payment _mongoClient={props._mongoClient} _productsInCart={productsInCart} _totalPrice={totalPrice}></Payment>
        }
    }

    return <div style={{ marginTop: props._marginTop }}>
        <Grid container justify='center' alignItems='center'>
            <Grid item xs={12} lg={12}>
                <Stepper activeStep={activeStep} orientation={orient}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={index} {...stepProps} onClick={()=>setThisStep(index)} style={{cursor: 'pointer'}}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
        </Grid>
        <CheckoutComponent></CheckoutComponent>
        {/* <Cart _productsInCart={productsInCart} _handleSetDeliveryPage={handleSetDeliveryPage}></Cart> */}
    </div>
}

export default Checkout;