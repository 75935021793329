import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const modelsApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.modelsApiCall.type) return next(action)
    const { client, db, query, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([
            { $match: { webview: true } },
            { "$addFields": {"bikeFits": { "$objectToArray":`$fits.${query}` } } },
            { "$unwind": "$bikeFits" },
            { $group: { 
                '_id': '$bikeFits.k'
            } },
            { $sort: {
                '_id': 1
            } }
        ]).toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.modelsApiCallSucess(result))
        })
        .catch((e)=>{
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.modelsApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default modelsApi;