import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { brandsApiCall } from '../actions';

const slice = createSlice({
    name: 'brands',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        brandsRequested: (state, action) => {
            state.loading = true
        },

        brandsRequestFailed: (state, action) => {
            state.loading = false
        },

        brandsReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, brandsRequested, brandsRequestFailed, brandsReceived } = slice.actions
export default slice.reducer;

export const getFilteredBrands = createSelector(
    state => state.brands,
    (brands) => brands.list,
)

export const loadBrands = (client, db) => (dispatch, getState) => {
    dispatch(
        brandsApiCall({
            client: client,
            db: db,
            onStart: brandsRequested.type,
            onSuccess: brandsReceived.type,
            onError: brandsRequestFailed.type
        })
    )
}