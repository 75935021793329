import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const fitsApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.fitsApiCall.type) return next(action)
    const { client, db, make, model, year, onStart, onSuccess, onError } = action.payload
    var query = `fits.${make}.${model}.${year}`
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').find({
            // webview: true, 
            [query]: true})
        .toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.fitsApiCallSucess(result))
        })
        .catch((e)=>{
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.fitsApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default fitsApi;