import React, { useEffect, useState } from 'react';

// Material UI
import { Snackbar, Button }  from '@material-ui/core/';
import GetAppIcon from '@material-ui/icons/GetApp';

const ImageMenu = (props) => {

    const [prodImg, setImg] = useState('');

    useEffect(()=>{
      if(props._prod.length > 0) {
        setImg(props._prod[0].images[props._imageIndex])
      }
    },[props._prod])

    return  <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={props._openImageMenu}
      onClose={props._handleCloseImageMenu}
      message='Download this image'
      action={
        <React.Fragment>
          <a
            href={prodImg}
            target={'_blank'}
            download
            onClick={()=>props._handleCloseImageMenu()}
          >
            <Button color='secondary' size='small'>
              Download
            </Button>
          </a>
          <GetAppIcon color='secondary' fontSize='small' />
        </React.Fragment>
      }
    />
}

export default ImageMenu;