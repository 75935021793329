import React from 'react';

const BritainFlag = (props) => {
    return     <svg
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
        viewBox="0 0 341.59 203.057"
        width="1em"
        height="1em"
        {...props}
    >
        <switch>
        <g>
            <path fill="#FFF" d="M.063 0h341.47v202.17H.063z" />
            <path fill="#13135F" d="M.063 20v56.5h96z" />
            <path fill="red" d="M109.91 76.5h19.65L.063 0v12z" />
            <path fill="#12145B" d="M37.916 0L147.56 67.486V0z" />
            <path fill="#13135F" d="M341.56 182v-56h-95z" />
            <path fill="red" d="M232.72 126h-19.66l128.5 76.12V190z" />
            <path fill="#12145B" d="M304.68 203l-109.64-67.49V203z" />
            <path fill="#13135F" d="M341.56 20.513V76.5l-95-.068z" />
            <path fill="red" d="M195.06 76.5h22.3L341.56 0h-22.55z" />
            <path fill="#12145B" d="M303.75 0L195.06 66.986V0z" />
            <path fill="#13135F" d="M.071 182.48v-56.5h96z" />
            <path fill="red" d="M147.52 125.97h-22.3L0 203.06h22.572z" />
            <path fill="#12145B" d="M37.87 202.97l109.65-67.49v67.49z" />
            <path fill="red" d="M156.72 0h28.874v203H156.72z" />
            <path fill="red" d="M.063 85.252h341.53v32.672H.063z" />
        </g>
        </switch>
    </svg>
}
  
export default BritainFlag;