import React, { useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Component
import PackageProd from './PackageProd/packageProd';

const useStyles = makeStyles({
    packageCard: {
        maxWidth: 345,
        height: '100%'
    },
});

const Packages = (props) => {
    const classes = useStyles();
    const [defaultProd, setDefaultProd] = useState({ size: 'CHOOSE THE SIZE', discount: 0, price: '0', barcode: 'default', groupid: 'default' });

    function handleSetProd(val, mainProd){
        if(val.groupid !== 'default'){
            props._handlePackPrice(Math.ceil(parseInt(val.price) - (parseInt(val.price) * val.discount / 100)),val, props._packIndex, mainProd)
        }
        // if(defaultProd.groupid !== val.groupid){
        //     props._handlePackPrice(Math.ceil(parseInt(val.price) - (parseInt(val.price) * val.discount / 100)),val, props._packIndex, mainProd)
        // }
        setDefaultProd(val)
    }

    return <Carousel className={classes.packageCard} showIndicators={false}
        renderItem={item => <div style={{ background: 'white', height: '100%' }}>{item}</div>}
        onChange={(val)=>{
            var defProd = { size: 'CHOOSE THE SIZE', discount: 0, price: '0', barcode: 'default' }
            setDefaultProd(defProd)
            props._handlePackPrice(0,defProd, props._packIndex, {})
        }}
    >
        {props._packages.map((prod, i)=>{
            return <PackageProd 
                key={i} 
                _prod={prod} 
                _handleSetProd={handleSetProd}
                _defaultProd={defaultProd}
            ></PackageProd>
        })}
    </Carousel>
}

export default Packages;