import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Material UI
import { makeStyles, Grid, Typography, Card, CardContent, 
    Select, FormControl, CardMedia, CardActions, InputLabel } from '@material-ui/core/';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Components
import BuyPackage from './buyPackage';
import PriceWithDiscount from '../Prices/priceWithDiscount';
import PriceWithoutDiscount from '../Prices/priceWithoutDiscount';
import Packages from '../Packages/packages';

// Store
import { addToCart, product } from '../../../../../store/cart/cart';

// Demon Knight
import demonKnight from '../../../../../assets/icons/knight_demon.png';

const useStyles = makeStyles((theme) => ({
    packageCard: {
        maxWidth: 345,
        height: '100%'
    },
    media: {
        height: '20rem',
        objectFit: 'contain'
    },
    sizes: {
        minWidth: '100%'
    },
    text: {
        textAlign: 'left',
        color: 'black'
    },
}));

const MainPackProd = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [newProd, setNewProd] = useState([])
    const [secodnaryPackPrice, setSecondaryPackPrice] = useState([])
    const [prodDetails, setProdDetails] = useState({});
    const [packProdDetails, setPackProdDetails] = useState([])
    const [totalPackPrice, setTotalPackPrice] = useState(0)
    const [mainPackProd, setMainProd] = useState([])

    const handlePackPrice = (val,pack, i, mainProd) => {
        var newPackPrice = secodnaryPackPrice
        newPackPrice[i] = val
        setSecondaryPackPrice(newPackPrice)
        var totalPack = newPackPrice.reduce((acc, val)=>{ return acc + val }, 0)
        setTotalPackPrice(totalPack)

        var newPackProdDetails = packProdDetails
        var newMainProd = mainPackProd
        if(pack.barcode !== 'default'){
            newPackProdDetails[i] = pack
            newMainProd[i] = mainProd
        }
        setPackProdDetails(newPackProdDetails)
        setMainProd(newMainProd)
    };

    const handleChangeForPack = (event) => {
        var specificProd = JSON.parse(event.target.value)
        setProdDetails(specificProd)
    }

    useEffect(()=>{
        var product = props._newProd
        if(product.length > 0){
            for(var i = 0; i < product[0].sizepricesdiscountqty.length; i++){
                if(parseInt(product[0].sizepricesdiscountqty[i].qty) > 0){
                    setProdDetails(product[0].sizepricesdiscountqty[i])
                    break;
                }
            }
            setNewProd(product)
            // for(var i = 0; i < product[0].sizepricesdiscountqty.length; i++){
            //     if(parseInt(product[0].sizepricesdiscountqty[i].qty) > 0){
            //         setProdDetails(product[0].sizepricesdiscountqty[i])
            //         break;
            //     }
            // }
        }
    },[props._newProd])

    function handleAddToCart(totalPrice, packDiscount){
        console.log('Pack Prod Details: ',packProdDetails)
        console.log('Main Prod: ', mainPackProd)
        var productToCart = JSON.parse(JSON.stringify(newProd[0]))
        // productToCart['detailsToBuy'] = prodDetails

        // var productPackToCart = JSON.parse(JSON.stringify(mainPackProd))
        // productPackToCart['detailsToBuy'] = packProdDetails

        var pack = {}
        if(productToCart.images.length > 0) {
            pack['images'] = [productToCart.images[0]]
        } else {
            pack['images'] = [demonKnight]
        }
        pack['images'] = [productToCart.images[0]]
        pack['description'] = productToCart.description
        pack['brand'] = productToCart.brand
        pack['detailsToBuy'] = {
            barcode: prodDetails.barcode,
            size: prodDetails.size,
            price: totalPrice.toString(),
            discount: packDiscount
        }
        pack['qtyToBuy'] = 1
        pack['pack'] = [prodDetails]
        mainPackProd.forEach((val)=>{
            pack.images.push(val.images[0])
            pack.description += ', ' + val.description
            pack.brand += ', ' + val.brand
        })
        packProdDetails.forEach((val)=>{
            console.log(val)
            pack.detailsToBuy.size += ', ' + val.size
            pack.detailsToBuy.barcode += ', ' + val.barcode
            pack.pack.push(val)
        })
        console.log(pack)
        dispatch(addToCart(pack))
    }

    return <div>
        {newProd.length > 0 ?
            newProd[0].package !== null ? <Grid container justify='center'>
            <Grid container justify='center'>
                <Grid item>
                    <Typography className={classes.text} variant='h4' gutterBottom>BUY PACKAGE, SAVE MONEY!</Typography>
                </Grid>
            </Grid>
            <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                <Grid item>
                    <Card className={classes.packageCard}>
                        <Carousel 
                            showIndicators={false} 
                            showArrows={false}
                            showStatus={false}
                            renderItem={item => <div style={{ background: "white", height: '100%' }}>{item}</div>}
                        >
                            <div>
                                <CardMedia
                                    className={classes.media}
                                    component='img'
                                    image={newProd[0].images.length > 0 ? newProd[0].images[0] : demonKnight}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {newProd[0].description}
                                    </Typography>
                                    {prodDetails.discount > 0 ?
                                        <PriceWithDiscount
                                            _prodDetails={prodDetails}
                                        ></PriceWithDiscount> :
                                        <PriceWithoutDiscount
                                            _prodDetails={prodDetails}
                                        ></PriceWithoutDiscount>
                                    }
                                </CardContent>
                                <CardActions>
                                {newProd[0].sizepricesdiscountqty.length > 1 &&
                                    newProd[0].sizepricesdiscountqty[0].size !== '' ?
                                        <FormControl className={classes.sizes}>
                                        <InputLabel htmlFor='age-native-simple'>SIZE</InputLabel>
                                            <Select
                                                native
                                                defaultValue={prodDetails.size}
                                                onChange={handleChangeForPack}
                                                inputProps={{
                                                    name: 'prodDetails',
                                                    id: 'age-native-simple',
                                                }}
                                            >
                                                {newProd[0].sizepricesdiscountqty.map((item, i)=>{
                                                    if(parseInt(item.qty) > 0){
                                                        return <option key={i}
                                                            value={JSON.stringify(item)}
                                                        >
                                                            {item.size.toUpperCase()}
                                                        </option>
                                                    } 
                                                })}
                                            </Select>
                                        </FormControl> :
                                        <Grid container justify='flex-start'>
                                        {newProd[0].sizepricesdiscountqty[0].size === '' ?
                                            <Typography>SIZE: NO SIZE</Typography> :
                                            <Typography>SIZE: {newProd[0].sizepricesdiscountqty[0].size.toUpperCase()}</Typography>
                                        }
                                        </Grid>
                                }
                                </CardActions>                                                       
                            </div>
                        </Carousel>
                    </Card>
                </Grid>
                {props._uniquePackageSubcats.map((val,i)=>{
                    var arr = []
                    newProd[0].packageProds.forEach((packageProd, j)=>{
                        if(packageProd.subcategory === val){
                            arr.push(packageProd)
                        }
                    })
                    return <Grid item key={i}>
                        <Card className={classes.packageCard}>
                            <Packages _packages={arr} 
                                _handlePackPrice={handlePackPrice}
                                _packIndex={i}
                            ></Packages>
                        </Card>
                    </Grid>
                })}
                <BuyPackage 
                    _packageLength={props._uniquePackageSubcats.length} 
                    _mainItemPrice={prodDetails}
                    _totalPackPrice={totalPackPrice}
                    _mongoClient={props._mongoClient}
                    _secodnaryPackPrice={secodnaryPackPrice}
                    _handleAddToCart={handleAddToCart}
                ></BuyPackage>
            </Grid></Grid> : <div></div>
            : <div></div>
        }
    </div>
}

export default MainPackProd