import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { faqApiCall } from '../actions';

const slice = createSlice({
    name: 'faq',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        faqRequested: (state, action) => {
            state.loading = true
        },

        faqRequestFailed: (state, action) => {
            state.loading = false
        },

        faqReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, faqRequested, faqRequestFailed, faqReceived } = slice.actions
export default slice.reducer;

export const getFilteredFaq = createSelector(
    state => state.faq,
    (faq) => faq.list,
)

export const loadFaq = (client, db) => (dispatch, getState) => {
    dispatch(
        faqApiCall({
            client: client,
            db: db,
            onStart: faqRequested.type,
            onSuccess: faqReceived.type,
            onError: faqRequestFailed.type
        })
    )
}