import React from 'react';

// Material UI
import { makeStyles, Fade, Card, CardActions, CardContent,
        Button, Typography } from '@material-ui/core/';

import pukinnLogo from '../../../assets/pukinnlogo.png';

// Custom Icons
import facebookIcon from '../../../assets/icons/fb.png';
import instagramIcon from '../../../assets/icons/insta.png';

// React Router
import { Link } from 'react-router-dom';

// Component
import Newsletter from '../../Footer/Newsletter/newsletter';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    logo: {
        width: '50%',
    },
    title: {
        fontSize: 14,
    },
    icon: {
        marginRight: '1%'
    },
    aTag: {
        color: 'grey'
    },
    button: {
        color: 'black'
    }
});

const Information = (props) => {
    const classes = useStyles();

    return <Fade in={props._fadeOpen}>
        <Card className={classes.root} variant='outlined'>
            <CardContent>
                <img src={pukinnLogo} className={classes.logo}/>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                    Pukinn.com specializes on sale and services of clothing and protective equipment for people who love motorbikes, bicycles, snowboards and skateboards.
                </Typography>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                    Púkinn EHF. All rights reserved.<br/>
                    Kennitala: 690300-2060<br/>
                    VSK:66699<br/>
                    <a className={classes.aTag} href= 'mailto: pukinn@pukinn.com'>pukinn@pukinn.com</a>
                </Typography>
                <Newsletter _mongoClient={props._mongoClient}></Newsletter>
                <a href='https://www.facebook.com/PukinnN/' target='_blank'><img src={facebookIcon} className={classes.icon}/></a>
                <a href='https://www.instagram.com/pukinn.mork/?hl=en' target='_blank'><img src={instagramIcon}/></a>
            </CardContent>
            <CardActions>
                <Button size='small'><Link to='/termsAndConditions' className={classes.button} onClick={props._closeInformationModal}>Terms and Conditions</Link></Button>
                <Button size='small'><Link to='/faq' className={classes.button} onClick={props._closeInformationModal}>FAQ</Link></Button>
            </CardActions>
        </Card>
    </Fade>
}

export default Information;