import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles, Modal, Fade, Backdrop } from '@material-ui/core/';

//Mongo Stitch
import { RemoteMongoClient, BSON } from 'mongodb-stitch-browser-sdk';

// Moment
import moment from 'moment';

// React Router
import { useLocation, useHistory } from 'react-router-dom';

// Store
import { loadGiftCard, getFilteredGiftCard } from '../../../../store/giftCard/giftCard';

// Components
import MainPage from '../MainPage/mainPage';
import ErrorModal from '../Product/ErrorModal/errorModal';

import pukinnLogo from '../../../../assets/pukinnlogo.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pukinnLogo: {
        display: 'block',
        margin: 'auto',
        width: '50%'
    }
}));

const GiftCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(search);

    var giftCard = useSelector(getFilteredGiftCard)

    const [db, setDB] = useState(null)
    const [client, setClient] = useState(null)
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            var giftCardID = query.get('giftcard')
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            setDB(db)
            setClient(client)
            if(BSON.ObjectId.isValid(giftCardID)) {
                if(props._mongoClient !== null){
                    dispatch(loadGiftCard(client, db, giftCardID))
                } else {
                    setErrorMsg('COULD NOT CONNECT TO DATABASE, PLEASE TRY AGAIN')
                    setErrorMsgBool(true)
                }
            } else {
                setErrorMsg('INVALID GIFTCARD ID')
                setErrorMsgBool(true)
            }
        }
    },[props._mongoClient])

    useEffect(()=>{
        if(Object.keys(giftCard).length > 0){
            var eventdate = moment(giftCard.exp);
            var todaysdate = moment();
            var daysLeft = eventdate.diff(todaysdate, 'days');
            window.localStorage.setItem('pukinn_giftcard', JSON.stringify(giftCard))
            setErrorMsg(`GIFT CARD ACTIVATED! CREDIT: ${giftCard.credit} isk. GIFT CARD EXPIRES AFTER ${daysLeft} DAYS.`)
            setErrorMsgBool(true)  
        } else {
            setErrorMsg('GIFTACRD USED OR EXPIRED')
            setErrorMsgBool(true)  
        }
    }, [giftCard])

    function handleReroute() {
        setErrorMsg('')
        setErrorMsgBool(false)
        history.push('/')
    }

    return <div style={{marginTop: props._marginTop, height: '100vh'}}>
        <Modal
            className={classes.modal}
            open={errorMsgBool}
            onClose={handleReroute}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        <img src={pukinnLogo} className={classes.pukinnLogo}/>
    </div>
}

export default GiftCard;