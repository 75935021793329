import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles, Grid, Card, CardMedia, CardHeader } from '@material-ui/core/';

// Placeholder Image
import knightdemon from '../../../../../assets/icons/knight_demon.png';

// React Card Flip
import ReactCardFlip from 'react-card-flip';

// React Router
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    text: {
        color: 'black',
    },
    media: {
        width: 100,
        margin: 'auto',
    },
    description: {
        cursor: 'pointer',
        color: '#ED1C24'
    },
    card: {
        height: '100%',
    },
}));

const Brand = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isFlipped, setFlip] = useState(false)

    return <Grid item lg={3} xs={12}>
        <Link to={`/shop?brand=${props._brand.name.toLowerCase()}`}>
            <Card className={classes.card}>
                <CardHeader
                    title={props._brand.name.toUpperCase()}
                    // subheader={<Typography className={classes.description} 
                    // onClick={()=>setFlip(true)}>DESCRIPTION</Typography>}
                />
                    
                    <CardMedia
                        className={classes.media}
                        image={props._brand.image.length > 0 ? props._brand.image : knightdemon}
                        title={props._brand.name}
                        component='img'
                    />
            </Card>     
        </Link>      
    </Grid>
}

export default Brand;