import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles, Grid, Typography, Backdrop, Modal,
    Fade } from '@material-ui/core/';

//Mongo Stitch
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Store
import { loadBikeBooking, getFilteredBikeBooking } from '../../../../store/bikeBooking/bikeBooking';

// Componentes
import ReadMore from './ReadMore/readMore';
import Bikes from './Bikes/bikes';

const useStyles = makeStyles((theme) => ({
    blackText: {
        color: 'black'
    },
    redText: {
        color: 'red'
    },
    container: {
        height: '100vh'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const BikeBooking = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [db, setDB] = useState(null)
    const [client, setClient] = useState(null)
    const [showModal, setShowModal] = useState(false)

    var availableBikes = useSelector(getFilteredBikeBooking)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            setDB(db)
            setClient(client)
            dispatch(loadBikeBooking(client, db));
        }
    }, [props._mongoClient])

    return (
        <div style={{marginTop: props._marginTop}}>
            <Modal
                className={classes.modal}
                open={showModal}
                onClose={()=>setShowModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModal}>
                    <ReadMore></ReadMore>
                </Fade>
            </Modal>
            {availableBikes.length > 0 ?
                <Bikes _availableBikes={availableBikes} _isMobile={props._isMobile} _mongoClient={props._mongoClient}></Bikes> :
                <Grid container className={classes.container} direction='column' justify='center' alignItems='center'>
                    <Grid item>
                        <Typography className={classes.blackText} variant='h4' gutterBottom>
                            MÖRKIN 4, 108 REYKJAVÍK
                        </Typography>
                        <Typography className={classes.redText} variant='h6' gutterBottom onClick={()=>setShowModal(true)}>
                            READ MORE
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6' gutterBottom className={classes.blackText}>
                            SEASON FOR BIKE BOOKING IS CLOSED
                            WE ARE OPEN BETWEEN APRIL AND OCTOBER
                        </Typography>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default BikeBooking;