import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { productApiCall } from '../actions';

const slice = createSlice({
    name: 'product',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        productRequested: (state, action) => {
            state.loading = true
        },

        productRequestFailed: (state, action) => {
            state.loading = false
        },

        productReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, productRequested, productRequestFailed, productReceived } = slice.actions
export default slice.reducer;

export const getFilteredProduct = createSelector(
    state => state.product,
    (product) => product.list,
)

export const loadProduct = (client, db, db2, groupid) => (dispatch, getState) => {
    dispatch(
        productApiCall({
            client: client,
            db: db,
            db2: db2,
            groupid: groupid,
            onStart: productRequested.type,
            onSuccess: productReceived.type,
            onError: productRequestFailed.type
        })
    )
}