import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const brandsByProductsApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.brandsByProductsApiCall.type) return next(action)
    const { client, db, query, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([
            { $match: query },
            { $group: {
                '_id': '$brand',
                'brand': { $last: '$brand' }
            } }
        ]).toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.brandsByProductsApiCallSucess(result))
        })
        .catch((e)=>{
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.brandsByProductsApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default brandsByProductsApi;