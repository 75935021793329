import React from 'react';

// Material UI
import { makeStyles, Grid, Typography } from '@material-ui/core/';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    price: {
        color: 'green'
    },
    redPrice: {
        color: 'red',
        textDecoration: 'line-through'
    },
}));

const PriceWithDiscount = (props) => {
    const classes = useStyles();

    return <Grid container direction='row' justify='center' spacing={2}>
        <Grid item>
            <Typography className={classes.redPrice}>
                {format(props._prodDetails.price, {
                    currency: 'isk',
                    showDecimals: 'NEVER',
                    thousandSeparator: ' '
                })}
            </Typography>
        </Grid>
        <Grid item>
            <Typography className={classes.price}>
                {format(Math.ceil(parseInt(props._prodDetails.price) - (parseInt(props._prodDetails.price) * props._prodDetails.discount / 100)), {
                    currency: 'isk',
                    showDecimals: 'NEVER',
                    thousandSeparator: ' '
                })}
            </Typography>
        </Grid>
    </Grid>
}

export default PriceWithDiscount