import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const packagePricingApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.packagePricingApiCall.type) return next(action)
    const { client, db, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('packagepricing').find()
            .toArray().then((result)=>{
                if(onSuccess) dispatch({ type: onSuccess, payload: result})
                else dispatch(actions.packagePricingApiCallSucess(result))
            })
            .catch((e)=>{
                if(e) dispatch({ type: onError, payload: e.message })
                else dispatch(actions.packagePricingApiCallFailed(e.message))
            })
    }); 
    return next(action)
}

export default packagePricingApi;