import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const faqApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.faqApiCall.type) return next(action)
    const { client, db, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('faq').aggregate([
            { '$sort': { '_id': 1 } }
        ]).toArray().then((result)=>{
                if(onSuccess) dispatch({ type: onSuccess, payload: result})
                else dispatch(actions.faqApiCallSucess(result))
            })
            .catch((e)=>{
                if(e) dispatch({ type: onError, payload: e.message })
                else dispatch(actions.faqApiCallFailed(e.message))
            })
    }); 
    return next(action)
}

export default faqApi;