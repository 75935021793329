import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl'; 

// Material UI
import { makeStyles, Grid, Typography, Card, Modal,
    CardContent, Fade, Backdrop, Select, FormControl,
    Button, InputLabel, TextField, Snackbar } from '@material-ui/core/';

//Mongo Stitch
import { RemoteMongoClient, AnonymousCredential } from 'mongodb-stitch-browser-sdk';

// MongoDB Atlas
import { Stitch } from 'mongodb-stitch-browser-sdk';

// React Router
import { useLocation } from 'react-router-dom';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Store
import { loadProduct, getFilteredProduct } from '../../../../store/product/product';
import { addToCart, getFilteredCart, clearCart } from '../../../../store/cart/cart';

// Components
import ErrorModal from './ErrorModal/errorModal';
import PriceWithDiscount from './Prices/priceWithDiscount';
import PriceWithoutDiscount from './Prices/priceWithoutDiscount';
import MainPackProd from './BuyPackage/mainPackProd';
import ImageMenu from './ImageMenu/imageMenu';

// Demon Knight
import demonKnight from '../../../../assets/icons/knight_demon.png';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#212121',
        '&:hover': {
            backgroundColor: '#3a3a3a',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        color: 'white',
        margin: '1.5% 0% 1.5% 0%',
        minWidth: '100%',

    },
    sizes: {
        minWidth: '100%'
    },
    text: {
        textAlign: 'left',
        color: 'black'
    },
    imgContainer: {
        position: 'relative'
    },
    image: {
        height: '20%',
        width: '20%'
    },
    prodImg: {
        backgroundColor: 'white',
    },
    brandDesc: {
        margin: '1.5% 0% 1.5% 0%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    copy: {
        cursor: 'pointer'
    },
    successsnackbar: {
        backgroundColor: 'white'
    }
}));

const Product = (props) => {
    const classes = useStyles();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const query = new URLSearchParams(search);

    const [db, setDB] = useState(null);
    const [db2, setDB2] = useState(null);
    const [client, setClient] = useState(null);
    const [newProd, setNewProd] = useState([])
    const [prodDetails, setProdDetails] = useState({});
    const [prodDetailsForPack, setProdDetailsForPack] = useState({});
    const [quantity, setQuantity] = useState('1')
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [uniquePackageSubcats, setUniquePackageSubcategories] = useState([])

    const [q, setQuery] = useState('')
    const [copySuccess, setCopySuccess] = useState(false)

    const [imageIndex, setImageIndex] = useState(null)
    const [openImageMenu, setOpenImageMenu] = useState(false)
    const [anchor, setAnchor] = useState(null)
 
    var product = useSelector(getFilteredProduct);
    var productsInCart = useSelector(getFilteredCart)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            var groupid = query.get('id')
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            const db2 = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            setQuery(groupid)
            setDB(db)
            setDB2(db2)
            setClient(client)
            dispatch(loadProduct(client, db, db2, groupid));
        }
    },[props._mongoClient])

    useEffect(()=>{
        if(product.length > 0){
            if(product[0].packageProds !== undefined){
                var arrOfSubcats = []
                product[0].packageProds.forEach(val=>arrOfSubcats.push(val.subcategory))
                var uniquePackageSubcategories = [...new Set(arrOfSubcats)]
                setUniquePackageSubcategories(uniquePackageSubcategories)
            }
            setNewProd([...product])
            for(var i = 0; i < product[0].sizepricesdiscountqty.length; i++){
                if(parseInt(product[0].sizepricesdiscountqty[i].qty) > 0){
                    setProdDetails(product[0].sizepricesdiscountqty[i])
                    setProdDetailsForPack(product[0].sizepricesdiscountqty[i])
                    break;
                }
            }
        }
    },[product])

    useEffect(()=>{
        var groupid = query.get('id')
        if(client !== null) {
            if(q !== groupid){
                setQuery(groupid)
                dispatch(loadProduct(client, db, db2, groupid));
            }
        }
    }, [query])

    const handleChange = (event) => {
        var specificProd = JSON.parse(event.target.value)
        setProdDetails(specificProd)
    };

    function handleAddToCart(){
        console.log(product[0])
        var productToCart = JSON.parse(JSON.stringify(product[0]))
        productToCart['detailsToBuy'] = prodDetails
        productToCart['qtyToBuy'] = parseInt(quantity)
        if(parseInt(prodDetails.qty) < 1){
            setErrorMsgBool(true)
            setErrorMsg('THIS SIZE IS OUT OF STOCK.')
        } else if(parseInt(quantity) < 1) {
            setErrorMsgBool(true)
            setErrorMsg('QUANTITY IS LESS THAN 1')
        } else {
            dispatch(addToCart(productToCart))
        }
    }

    function copyToClipBoard(val){
        navigator.clipboard.writeText(val)
        setCopySuccess(true)
    }

    function handleOpenImageMenu(val, item) {
        setAnchor(item)
        setImageIndex(val)
        setOpenImageMenu(true)
    }

    function handleCloseImageMenu() {
        setOpenImageMenu(false)
    }

    return(
        <div style={{marginTop: props._marginTop}}>
            <Modal
                className={classes.modal}
                open={errorMsgBool}
                onClose={()=>setErrorMsgBool(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={errorMsgBool}>
                    <ErrorModal _message={errorMsg}></ErrorModal>
                </Fade>
            </Modal>

            <ImageMenu _imageIndex={imageIndex} _openImageMenu={openImageMenu} _handleCloseImageMenu={handleCloseImageMenu}
                _prod={newProd} _anchor={anchor}></ImageMenu>

            <Grid container justify='center'>
                <Grid item xs={10}>
                        {newProd.length > 0 ?
                            <Grid container spacing={2}>
                                <Grid container>
                                    <Grid item className={classes.imgContainer} lg={6} xs={12}>
                                        <Carousel onClickItem={(e, item)=>handleOpenImageMenu(e, item)}>
                                            {newProd[0].images.length > 0 ?
                                                newProd[0].images.map((image, i)=>{
                                                    return <img className={classes.prodImg} key={i} src={image}/>
                                                }) :
                                                <img src={demonKnight}/>
                                            }
                                        </Carousel>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Card>
                                            {prodDetails.discount > 0 ?
                                                <div className={classes.discount}><p>DISCOUNT: {prodDetails.discount}%</p></div> :
                                                <div></div>
                                            }
                                            <CardContent> 
                                                <Typography variant='h5' component='h2'>
                                                    {newProd[0].description}
                                                </Typography>
                                                <Typography className={classes.copy} onClick={()=>copyToClipBoard(prodDetails.barcode)} variant='subtitle1' color='textSecondary' gutterBottom>
                                                    {prodDetails.barcode}
                                                </Typography>
                                                <Typography variant='h5' gutterBottom>
                                                    {prodDetails.discount > 0 ?
                                                        <PriceWithDiscount _prodDetails={prodDetails}></PriceWithDiscount> :
                                                        <PriceWithoutDiscount _prodDetails={prodDetails}></PriceWithoutDiscount>
                                                    }
                                                </Typography>
                                                {newProd[0].sizepricesdiscountqty.length >= 1 ?
                                                    <FormControl className={classes.sizes}>
                                                    <InputLabel htmlFor='age-native-simple'><FormattedMessage id='product.size' 
                                                    defaultMessage='SIZE'/></InputLabel>
                                                        <Select
                                                            native
                                                            defaultValue={prodDetails.size}
                                                            onChange={handleChange}
                                                            inputProps={{
                                                                name: 'prodDetails',
                                                                id: 'age-native-simple',
                                                            }}
                                                        >
                                                            {newProd[0].sizepricesdiscountqty.map((item, i)=>{
                                                                if(parseInt(item.qty) > 0){
                                                                    return <option key={i}
                                                                        value={JSON.stringify(item)}
                                                                    >
                                                                        {item.size.toUpperCase()}
                                                                    </option>
                                                                } 
                                                            })}
                                                        </Select>
                                                    </FormControl> :
                                                    <Grid container justify='flex-start'>
                                                        {newProd[0].sizepricesdiscountqty[0].size === '' ?
                                                            <Typography>SIZE: NO SIZE</Typography> :
                                                            <Typography>SIZE: {newProd[0].sizepricesdiscountqty[0].size.toUpperCase()}</Typography>
                                                        }
                                                    </Grid>
                                                }
                                                <TextField
                                                    className={classes.sizes}
                                                    label={<FormattedMessage id='fitmybike.quantity' 
                                                    defaultMessage='QUANTITY'/>}
                                                    type='number'
                                                    defaultValue={quantity}
                                                    onChange={(e)=>setQuantity(e.target.value)}
                                                />
                                                <Button className={classes.button}
                                                    onClick={handleAddToCart}
                                                >
                                                    <FormattedMessage id='fitmybike.addtocart' 
                                                    defaultMessage='ADD TO CART'/>
                                                </Button>
                                                {/* <Button className={classes.button}>
                                                <FormattedMessage id='product.buynow' 
                                                    defaultMessage='BUY NOW'/></Button> */}
                                                <Grid container justify='flex-start'>
                                                    {newProd[0].itemdescription.split('°').map((value, index) => {
                                                        return (
                                                            <Typography key={index} variant='body2' component='p' className={classes.text}>
                                                                {value}
                                                                <br />
                                                            </Typography>
                                                        );
                                                    })}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <MainPackProd
                                    _newProd={newProd}
                                    _mongoClient={props._mongoClient}
                                    _uniquePackageSubcats={uniquePackageSubcats}
                                    _groupid={q}
                                    // _prodDetailsForPack={prodDetailsForPack}
                                ></MainPackProd>
                                <Grid container justify='center' alignItems='center' className={classes.brandDesc}>
                                    <Grid item lg={6} xs={12}>
                                        <img className={classes.image} src={newProd[0].brandDetails.image} />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant='body2' component='p' className={classes.text}>
                                            {newProd[0].brandDetails.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid> : <Grid container spacing={2} style={{ marginTop: '50%' }}></Grid>
                        }
                </Grid>
            </Grid>
            <Snackbar message={'Copied to Clipboard!'} open={copySuccess} autoHideDuration={2000} onClose={()=>setCopySuccess(false)}></Snackbar>
        </div>
    )
}

export default Product;