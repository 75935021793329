import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { fitsApiCall } from '../actions';

const slice = createSlice({
    name: 'fits',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        fitsRequested: (state, action) => {
            state.loading = true
        },

        fitsRequestFailed: (state, action) => {
            state.loading = false
        },

        fitsReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, make, model, year, fitsRequested, fitsRequestFailed, fitsReceived } = slice.actions
export default slice.reducer;

export const getFilteredFits = createSelector(
    state => state.fits,
    (fits) => fits.list,
)

export const loadFits = (client, db, make, model, year) => (dispatch, getState) => {
    dispatch(
        fitsApiCall({
            client: client,
            db: db,
            make: make,
            model: model,
            year: year,
            onStart: fitsRequested.type,
            onSuccess: fitsReceived.type,
            onError: fitsRequestFailed.type
        })
    )
}