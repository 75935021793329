import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const categoriesApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.categoriesApiCall.type) return next(action)
    const { client, db, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('categories')
        .aggregate([
            {
                $group: {
                    _id: "$_id",
                    name: { $first: "$name" },
                    nameis: { $first: "$nameis" },
                    subcategories: { $first: "$subcategories" }
                },
            },
            { $sort: { '_id': 1 } },
        ])
        .toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.categoriesApiCallSucess(result))
        })
        .catch((e)=>{
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.categoriesApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default categoriesApi;