import React, { useEffect } from 'react';
import { makeStyles, Grid, Card, CardContent, Typography } from '@material-ui/core/';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: 'black'
    },
    card: {
        height: '100%'
    },
}));

const Location = (props) => {
    const classes = useStyles();

    useEffect(()=>{window.scrollTo(0, 0)})

    return <div className={classes.root} style={{ marginTop: props._marginTop }}>
        <Grid container justify='center'>
            <Grid item xs={10}>
                <Grid container spacing={2} justify='center'>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1740.9111494956728!2d-21.864466283880628!3d64.12950772429085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d674bd254bf19d%3A0x2d52c445085f7345!2zUMO6a2lubg!5e0!3m2!1sen!2sis!4v1610451909715!5m2!1sen!2sis" width="600" height="450" style={{ border: 0 }}></iframe>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    MÖRKIN 4
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.opening' 
                                    defaultMessage='OPENING HOURS'/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.montothu' 
                                    defaultMessage='MON - THU 10:00-18:30'/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.friday' 
                                    defaultMessage='FRIDAYS 10:00 - 18:30'/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.saturday' 
                                    defaultMessage='SATURDAYS 11:00 - 16:00'/>
                                </Typography>
                                <Typography variant="body2" color="textPrimary" component="p">
                                    (+354)566 6820
                                </Typography>
                                <Typography variant="body2" color="textPrimary" component="p">
                                    <a className={classes.link} href= "mailto: pukinn@pukinn.com"><FormattedMessage id='location.email' 
                                    defaultMessage='EMAIL:'/> pukinn@pukinn.com</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d821.5175736805958!2d-18.092004806914005!3d65.68160109139602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d28f0ec0d124eb%3A0x7699d5ce7ecbac9b!2sHafnarstr%C3%A6ti%20101%2C%20Akureyri!5e0!3m2!1sen!2sis!4v1570019075051!5m2!1sen!2sis" width="600" height="450" style={{ border: 0 }}></iframe>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    HAFNARSTRÆTI 22, AKUREYRI
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.opening' 
                                    defaultMessage='OPENING HOURS'/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.montofri' 
                                    defaultMessage='MON - FRI 11:00-18:00'/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage id='location.saturday2' 
                                    defaultMessage='SATURDAYS 12:00-16:00'/>
                                    SATURDAYS 12:00-16:00
                                </Typography>
                                <Typography variant="body2" color="textPrimary" component="p">
                                    (+354)571 3188
                                </Typography>
                                <Typography variant="body2" color="textPrimary" component="p">
                                    <a className={classes.link} href= "mailto: einar@pukinn.com"><FormattedMessage id='location.email' 
                                    defaultMessage='EMAIL:'/> einar@pukinn.com</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
}

export default Location;