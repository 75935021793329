import React, { useState, useEffect } from 'react';

// Calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarBooking = (props) => {
    const [selectedDate, setDate] = useState(new Date());
    const [maximumDate, setMaxDate] = useState(new Date())

    useEffect(()=>{
        var current = new Date();
        current.setMonth(9)
        current.setDate(31)
        setMaxDate(current)
    })

    function handleDateChange(val){
        props._handleDatePick(val)
    }

    return <div>
        <Calendar
            maxDate={maximumDate}
            minDate={new Date()}
            tileDisabled={({activeStartDate, date, view }) => date.getDay() === 0}
            onChange={handleDateChange}
            value={selectedDate}
        />
    </div>
}

export default CalendarBooking;