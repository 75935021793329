import React from 'react';
import { useDispatch } from 'react-redux';

// Material UI
import { makeStyles, Card, CardHeader, IconButton, Typography, 
    CardActions, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Store
import { deleteOneFromCart } from '../../../../../../store/cart/cart';

// Demon Knight
import demonKnight from '../../../../../../assets/icons/knight_demon.png';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
    },
    cartActionText: {
        margin: 'auto',
        color: 'red',
    },
    cardActionsColor: {
        backgroundColor: 'yellow'
    },
    redPrice: {
        color: 'red',
        textDecoration: 'line-through'
    },
    greenPrice: {
        color: 'green'
    },
    prodImg: {
        maxWidth: '50%',
        backgroundColor: 'white'
    }
}));

const ProductInCart = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    return <Card className={classes.root}>
        <CardHeader
            action={
                <IconButton aria-label='settings'>
                    <CancelIcon onClick={()=>dispatch(deleteOneFromCart(props._index))}/>
                </IconButton>
            }
            title={props._product.description}
            subheader={props._product.detailsToBuy.size}
        />
        {props._product.detailsToBuy.discount > 0 ?
            <Grid container direction='row' justify='center' spacing={2}>
                <Grid item>
                    <Typography variant='subtitle1' gutterBottom>{
                        <span className={classes.redPrice}>{format(props._product.detailsToBuy.price, {
                            showDecimals: 'NEVER',
                            thousandSeparator: ' '
                        })}</span>
                    }</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1' gutterBottom>{
                        <span className={classes.greenPrice}>{format(Math.ceil(parseInt(props._product.detailsToBuy.price) - (parseInt(props._product.detailsToBuy.price) * props._product.detailsToBuy.discount / 100)), {
                            currency: 'isk',
                            showDecimals: 'NEVER',
                            thousandSeparator: ' '
                        })}</span>}
                    </Typography>
                </Grid>
            </Grid> :
            <Typography className={classes.greenPrice} variant='subtitle1' gutterBottom>{
            format(props._product.detailsToBuy.price, {
                currency: 'isk',
                showDecimals: 'NEVER',
                thousandSeparator: ' '
            })}</Typography>  
        }
        <Carousel
            renderItem={item => <div style={{ background: 'white' }}>{item}</div>}
            showThumbs={false}
            showIndicators={false}
        >
            {props._product.images.length > 0 ?
                props._product.images.map((image, i)=>{
                    return <img className={classes.prodImg} key={i} src={image}/>
                }) :
                <img src={demonKnight}/>
            }
        </Carousel>
        {props._product.pack !== null && props._product.pack !== undefined ?
            <CardActions disableSpacing className={classes.cardActionsColor}>
                <Typography variant='body2' component='p' className={classes.cartActionText}>
                    PACKAGE
                </Typography>
            </CardActions> :
            <div></div>
        }
    </Card>
}

export default ProductInCart;