import { combineReducers } from 'redux';
import categoriesReducer from './categories/categories';
import faqReducer from './faq/faq';
import workersReducer from './workers/workers';
import membersReducer from './members/members';
import brandsReducer from './brands/brands';
import brandsByProductsReducer from './brandsByProducts/brandsByProducts';
import productsReducer from './products/products';
import productReducer from './product/product';
import cartReducer from './cart/cart';
import deliveryReducer from './delivery/delivery';
import makesReducer from './makes/makes';
import modelsReducer from './models/models';
import yearsReducer from './years/years';
import fitsReducer from './fits/fits';
import searchReducer from './search/search';
import packagePricingReducer from './packagePricing/packagePricing';
import bikeBookingReducer from './bikeBooking/bikeBooking';
import giftCardReducer from './giftCard/giftCard';
import featuredProductsReducer from './featuredProducts/featuredProducts';

export default combineReducers({
    categories: categoriesReducer,
    faq: faqReducer,
    workers: workersReducer,
    members: membersReducer,
    brands: brandsReducer,
    brandsByProducts: brandsByProductsReducer,
    products: productsReducer,
    product: productReducer,
    cart: cartReducer,
    delivery: deliveryReducer,
    makes: makesReducer,
    models: modelsReducer,
    years: yearsReducer,
    fits: fitsReducer,
    search: searchReducer,
    packagePricing: packagePricingReducer,
    bikeBooking: bikeBookingReducer,
    giftCard: giftCardReducer,
    featuredProducts: featuredProductsReducer
})