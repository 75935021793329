import { Provider } from 'react-redux';
import configureStore from './store/configureStore';

import './App.css';

// Components
import Auth from './components/Auth/auth';

function App() {
  const store = configureStore();
  return (
    <div className="App">
      <h1>Testing</h1>
      <header className="App-header">
        <Provider store={store}>
          <Auth></Auth>
        </Provider>
      </header>
    </div>
  );
}

export default App;
