import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

// FormatJS (Internationalization)
import { Context } from '../../../../../languages/Wrapper';

// Store
import { getFilteredCategories } from '../../../../../store/categories/categories';

// Components
import Fit from './Fit/fit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: '3% 0% 3% 0%'
  },
  tabs: {
    backgroundColor: '#212121',
    color: 'white'
  },
  prodtab: {
    backgroundColor: '#F5F5F5',
    color: 'black'
  },
}));

const Fits = (props) => {
  const classes = useStyles();
  const context = useContext(Context);
  var categories = useSelector(getFilteredCategories)

  const [tabIndex, setTabIndex] = useState(0);
  const [subCat, setSubCat] = useState('');
  const [subCats, setSubCats] = useState([])
  const [subCatsIS, setSubCatsIS] = useState([])
  const [prods, setProds] = useState([])

  const handleChangeSubCat = (subCat, index) => {
    setTabIndex(index)
    setSubCat(subCat);
  };

  useEffect(()=>{
    var subCats = []
    props._fits.forEach((val)=>subCats.push(val.subcategory))
    let unique = [...new Set(subCats)];
    setSubCats(unique)
    setSubCat(unique[0])
    setProds(props._fits)
    
    // var arr = []
    // props._fits.forEach((val, i)=>{
    //   categories.forEach((val2, j)=>{
    //     if(val2.name === Object.keys(val.category)[0]){
    //       arr.push(val2.subcategories)
    //     }
    //   })
    // })
    // var flattenArr = arr.flat()
    // var isSubCats = []
    // unique.forEach((val)=>{
    //   flattenArr.forEach((val2)=>{
    //     if(val2.name === val){
    //       isSubCats.push(val2.nameis)
    //     }
    //   })
    // })
    // let uniqueIS = [...new Set(isSubCats)];
    // setSubCatsIS(uniqueIS)
    // console.log(uniqueIS)
  },[props._fits])

  return (
    <div className={classes.root}>
      {prods.length > 0 ?
        <div>
          <AppBar position='static' color='default'>
            <Tabs
              value={tabIndex}
              className={classes.tabs}
              indicatorColor='secondary'
              variant='scrollable'
              scrollButtons='auto'
            >
              {subCats.map((val,i)=> 
                <Tab 
                  key={i} 
                  label={val.toUpperCase()} 
                  onClick={()=>handleChangeSubCat(val, i)}
                />
              )}
            </Tabs> 
          </AppBar>
          <div className={classes.prodtab}>
            {prods.map((prod,i)=>{
              if(prod.subcategory === subCat){
                return <Fit key={i} prod={prod}></Fit>
              }
              return <div></div>
            })}
          </div>
        </div> : <div></div>
      }
    </div>
  );
}

export default Fits;