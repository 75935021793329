// Email Validator
import * as EmailValidator from 'email-validator';

// Axios
import axios from 'axios';

export function _submit(email, body) {
    if(EmailValidator.validate(email)){
        if(body.length > 24) {
            return 'complete'
        } else {
            return 'invalid_body'
        }
    } else {
        return 'invalid_email'
    }
}

export function _send(email, title, body) {
    axios.post('https://us-central1-pukinn2.cloudfunctions.net/sendIdea', {
            email: email,
            title: title,
            body: body
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
}