import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Grid, Typography, Accordion,
        AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Store
import { loadFaq, getFilteredFaq } from '../../../../store/faq/faq';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: '30%',
        alignItems: 'center'
    },
    text: {
        color: 'black',
    },
    loadingContainer: {
        height: '80vh'
    }
}));

const Faq = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const faqs = useSelector(getFilteredFaq)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            dispatch(loadFaq(client, db));
            setLoading(false);
        }
    },[props])

    return <div className={classes.root} style={{ marginTop: props._marginTop }}>
        {loading !== true ?
            <div>
                {faqs.map((faq, i)=>{
                    return <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant='body1' gutterBottom>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                })}
            </div> :
            <Grid container className={classes.loadingContainer} justify='center' alignItems='center' direction='row'>
                <CircularProgress/>
            </Grid>
        }
    </div>
}

export default Faq;