import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const searchApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.searchApiCall.type) return next(action)
    const { client, db, query, skip, onStart, onSuccess, onError } = action.payload

    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([
            { $search: { "text": { query: query, path: ["description", "subcategory", "brand"] } } },
            { $group: {
                    _id: "$groupid",
                    images: { $last: "$images" },
                    description: { $last: "$description" },
                    barcode: { $min: "$barcode" },
                    subcategory: { $last: "$subcategory" }, 
                    discount: { $last: "$discount" },
                    brand: { $last: "$brand" },
                    groupid: { $last: "$groupid" },
                    totalQty: { $sum: { $toInt: "$qty" } },
                    webview: { $last: "$webview" }
                },
            },
            { $match: { 'webview': true, 'totalQty': { $gt: 0 } } } ,
            { $sort: { '_id': 1 } },
            { $skip: skip },
            { $limit: 5 }
        ]).toArray().then(result=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.searchApiCallSucess(result))
        }).catch((e)=>{
            console.log(e)
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.searchApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default searchApi;