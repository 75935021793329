import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { giftCardApiCall } from '../actions';

const slice = createSlice({
    name: 'giftCard',
    initialState: {
        list: {},
        loading: false,
        lastFetch: null
    },
    reducers: {
        giftCardRequested: (state, action) => {
            state.loading = true
        },

        giftCardRequestFailed: (state, action) => {
            state.loading = false
        },

        giftCardReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
        deactivateGiftCard: (state, action) => {
            window.localStorage.removeItem('pukinn_giftcard')
            state.list = {}
            state.loading = false;
        },
    }
})
export const { client, db, _id, giftCardRequested, giftCardRequestFailed, giftCardReceived, deactivateGiftCard } = slice.actions
export default slice.reducer;

export const getFilteredGiftCard = createSelector(
    state => {
        var gCard = window.localStorage.getItem('pukinn_giftcard');
        if(gCard !== null){
            return JSON.parse(gCard)
        } else {
            return state.giftCard
        }
    },
    (giftCard) => {
        var gCard = window.localStorage.getItem('pukinn_giftcard');
        if(gCard !== null){
            return JSON.parse(gCard)
        } else {
            return giftCard.list 
        }
    },
)

export const loadGiftCard = (client, db, _id) => (dispatch, getState) => {
    dispatch(
        giftCardApiCall({
            client: client,
            db: db,
            _id: _id,
            onStart: giftCardRequested.type,
            onSuccess: giftCardReceived.type,
            onError: giftCardRequestFailed.type
        })
    )
}

export const deactivate = () => (dispatch, getState) => {
    dispatch(deactivateGiftCard())
}