import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { membersApiCall } from '../actions';

const slice = createSlice({
    name: 'members',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        membersRequested: (state, action) => {
            state.loading = true
        },

        membersRequestFailed: (state, action) => {
            state.loading = false
        },

        membersReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, membersRequested, membersRequestFailed, membersReceived } = slice.actions
export default slice.reducer;

export const getFilteredMembers = createSelector(
    state => state.members,
    (members) => members.list,
)

export const loadMembers = (client, db) => (dispatch, getState) => {
    dispatch(
        membersApiCall({
            client: client,
            db: db,
            onStart: membersRequested.type,
            onSuccess: membersReceived.type,
            onError: membersRequestFailed.type
        })
    )
}