import React from 'react';

// Material UI
import { makeStyles, Typography } from '@material-ui/core/';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    price: {
        color: 'green'
    },
}));

const PriceWithoutDiscount = (props) => {
    const classes = useStyles();

    return <Typography className={classes.price}>
        {format(props._prodDetails.price, {
            currency: 'isk',
            showDecimals: 'NEVER',
            thousandSeparator: ' '
        })}
    </Typography>
}

export default PriceWithoutDiscount