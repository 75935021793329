import React, { useEffect, useState } from 'react';

// Material UI
import { fade, makeStyles, InputBase, InputAdornment, Button,
    Modal, Fade, Backdrop } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

// MongoDB Atlas
import { RemoteMongoClient, AnonymousCredential } from 'mongodb-stitch-browser-sdk';

// Email Validator
import * as EmailValidator from 'email-validator';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import ErrorModal from '../../LandingPage/Pages/Product/ErrorModal/errorModal';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        // width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    menu: {
        marginTop: '2%',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const Newsletter = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const [client, setClient] = useState(null)
    const [db, setDB] = useState(null)
    const [email, setEmail] = useState('')
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('customers');
            setClient(client)
            setDB(db)
        }
    },[props._mongoClient])

    function subscribe(){
        if(EmailValidator.validate(email)){
            client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
                db.collection('newsletter').updateOne({email: email}, {$set:{email: email}}, {upsert: true})
                    .then((result)=>{
                        if(result.upsertedId === undefined){
                            setEmail('')
                            setErrorMsgBool(true)
                            setErrorMsg('EMAIL ALREADY SUBSCRIBED')
                        } else {
                            setEmail('')
                            setErrorMsgBool(true)
                            setErrorMsg('THANK YOU FOR SUBSCRIBING!')
                        }
                    })
                    .catch((e)=>{
                        console.log(e)
                    })
            }); 
        } else {
            setErrorMsgBool(true)
            // setErrorMsg('EMAIL IS INVALID')
            setErrorMsg(intl.formatMessage({id: 'contactus.emailinvalid', defaultMessage: 'EMAIL IS INVALID'}))
        }
    }

    return <div className={classes.search}>
        <Modal
            className={classes.modal}
            open={errorMsgBool}
            onClose={()=>setErrorMsgBool(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        <div className={classes.searchIcon}>
            <MailIcon />
        </div>
        <InputBase
            placeholder={intl.formatMessage({id: 'footer.column4.subscribeMsg', defaultMessage: 'Subscribe to Newsletter'})}
                classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            endAdornment={
                <InputAdornment position='end'>
                    <Button className={classes.button} variant='contained' color='secondary' size='small' onClick={subscribe}>
                        <FormattedMessage 
                            id='footer.column4.subscribe' 
                            defaultMessage='SUBSCRIBE'
                        />
                    </Button>
                </InputAdornment>
            }
        />
    </div>
}

export default Newsletter;