import React from 'react';

const IcelandFlag = (props) => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 675 486"
        width="1em"
        height="1em"
        {...props}
    >
        <path fillRule="evenodd" fill="#003897" d="M0 0h675v486H0z" />
        <path
            d="M0 189h189V0h108v189h378v108H297v189H189V297H0V189z"
            fillRule="evenodd"
            fill="#fff"
        />
        <path
            d="M0 216h216V0h54v216h405v54H270v216h-54V270H0v-54z"
            fillRule="evenodd"
            fill="#d72828"
        />
    </svg>
}
  
export default IcelandFlag;