import * as actions from '../actions';

const delivery = ({ dispatch }) => next => action => {
    // console.log(action.deliveryCall)
    if(action.type !== actions.deliveryCall.type) return next(action)
    const { delivery, onStart } = action.payload

    // dispatch(actions.deliveryCallSucess(delivery))
    dispatch({ type: onStart, payload: delivery})
    return next(action)
}

export default delivery;