import React, { useState } from 'react';

// Material UI
import { makeStyles, Grid, Typography, Backdrop, Modal, 
    Fade } from '@material-ui/core/';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Components
import Bike from './Bike/bike';
import ReadMore from '../ReadMore/readMore';
import CalendarBooking from '../CalendarBooking/calendarBooking';
import BookingCheckout from '../BookingCheckout/bookingCheckout';

const useStyles = makeStyles((theme) => ({
    packageCard: {
        maxWidth: '800',
        margin: '1%'
        // height: '100%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    blackText: {
        color: 'black'
    },
    redText: {
        color: 'red'
    },
    greyText: {
        color: 'grey'
    }
}));

const Bikes = (props) => {
    const classes = useStyles();
    const [showCalendar, setShowCalendar] = useState(false)
    const [pickedBike, setBike] = useState({})
    const [pickedDate, setDate] = useState(new Date())
    const [checkoutActive, setCheckout] = useState(false)

    function handleBookNow(val){
        setBike(val)
        setShowCalendar(true)
    }

    function handleDatePick(val){
        setDate(val)
        setShowCalendar(false)
        setCheckout(true)
    }

    return <div>
        {checkoutActive ? 
            <BookingCheckout 
                _pickedBike={pickedBike} 
                _pickedDate={pickedDate}>
            </BookingCheckout> :
            <Grid container direction='row' justify='center' alignItems='center'>
                <Modal
                    className={classes.modal}
                    open={showCalendar}
                    onClose={()=>setShowCalendar(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showCalendar}>
                        <CalendarBooking _handleDatePick={handleDatePick}></CalendarBooking>
                    </Fade>
                </Modal>
                <Grid item lg={6} xs={12} style={{padding: '3%'}}>  
                    <Typography className={classes.blackText} variant='h5' gutterBottom>
                        MÖRKIN 4, 108 REYKJAVÍK
                    </Typography>
                    <Typography className={classes.greyText} variand='body2' gutterBottom>
                        {props._availableBikes.length} 
                    <FormattedMessage 
                        id='bikebooking.available' 
                        defaultMessage=' BIKES AVAILABLE'
                    />
                    </Typography>
                    <ReadMore></ReadMore>
                    {props._isMobile ? 
                        <Typography className={classes.greyText} variant='body2' gutterBottom>
                            (SWIPE TO VIEW)
                        </Typography> :
                        <div></div>
                    }
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Carousel
                        className={classes.packageCard}
                        renderItem={item => <div style={{ background: 'white', height: '100%' }}>{item}</div>}
                        showThumbs={false}
                        showIndicators={false}
                    >
                        {props._availableBikes.map((val,i)=>{
                            return <Bike key={i} _bike={val} _handleBookNow={handleBookNow}></Bike>
                        })}
                    </Carousel>
                </Grid>
            </Grid>
        }
    </div>
}

export default Bikes;