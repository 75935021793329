import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const featuredProductsApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.featuredProductsApiCall.type) return next(action)
    const { client, db, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([
            { $match: { priority: { $gt: 4 } } },
            { $group: {
                _id: "$groupid",
                id: { $last: "$_id" },
                images: { $last: "$images" },
                description: { $last: "$description" },
                barcode: { $min: { $toInt: "$barcode" } },
                category: { $last: "$category" },
                subcategory: { $last: "$subcategory" }, 
                discount: { $last: "$discount" },
                priority: { $last: "$priority" },
                itemdescription: { $last: "$itemdescription" },
                totalQty: { $sum: { $toInt: "$qty" } },
                brand: { $last: "$brand" },
                package: { $last: "$package" }
            } },
            { $sort: { priority: 1 } },
            { $sort: { description: 1 } },
            { $limit: 10 }
        ]).toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result})
            else dispatch(actions.featuredProductsApiCallSucess(result))
        })
        .catch((e)=>{
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.featuredProductsApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default featuredProductsApi;