import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cartCall } from '../actions';

const slice = createSlice({
    name: 'cart',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        productAdded: (state, action) => {
            var oldCart = window.localStorage.getItem('pukinn_cart')
            if(oldCart !== null) {
                var parsedCart = JSON.parse(oldCart)
                parsedCart.push(action.payload)
                state.list = parsedCart
                window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                state.loading = false
            } else {
                state.list.push(action.payload)
                window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                state.loading = false
            }
        },

        productDeleted: (state, action) => {
            var oldCart =  window.localStorage.getItem('pukinn_cart', JSON.stringify(state.list));
            if(oldCart !== null) {
                var parsedCart = JSON.parse(oldCart)
                if(parsedCart.length > 0){
                    parsedCart.splice(action.payload, 1)
                    state.list = parsedCart
                    window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                    state.loading = false
                } else {
                    state.list = parsedCart
                    window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                    state.loading = false
                }
            } else {
                if(state.list.length > 0) {
                    state.list.splice(action.payload, 1)
                    window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                    state.loading = false
                } else {
                    window.localStorage.setItem('pukinn_cart', JSON.stringify(state.list));
                    state.loading = false
                }
            }
        },

        cartCleared: (state, action) => {
            console.log('Started')
            state.list = []
            state.loading = false
        },

        cartRequested: (state, action) => {
            state.loading = true
        },

        cartRequestFailed: (state, action) => {
            state.loading = false
        },

        cartReceived: (state, action) => {
            var localCart = window.localStorage.getItem('pukinn_cart');
            if(localCart !== null) {
                state.list = JSON.parse(localCart)
                // if(JSON.parse(localCart).length > 0) {
                //     state.list = JSON.parse(localCart)
                // } else {
                //     state.list = action.payload;
                // }
            } else {
                state.list = action.payload;
            }
            state.loading = false;
        },
    }
})
// export const { product, productAdded, cartRequested, cartRequestFailed, cartReceived } = slice.actions
export const { product, productAdded, productDeleted, cartCleared } = slice.actions
export default slice.reducer;

export const getFilteredCart= createSelector(
    state => {
        var localCart = window.localStorage.getItem('pukinn_cart');
        if(localCart !== null){
            return JSON.parse(localCart)
            // if(JSON.parse(localCart).length > 0) {
            //     return JSON.parse(localCart)
            // } else {
            //     return state.cart
            // }
        } else {
            return state.cart
        }
    },
    (cart) => { 
        var localCart = window.localStorage.getItem('pukinn_cart');
        if(localCart !== null) {
            return JSON.parse(localCart)
            // if(JSON.parse(localCart).length > 0) {
            //     return JSON.parse(localCart)
            // } else {
            //     return cart.list 
            // }
        } else {
            return cart.list 
        }
    },
)

export const addToCart = (product) => (dispatch, getState) => {
    dispatch(
        cartCall({
            product: product,
            onStart: productAdded.type,
        })
    )
}

export const deleteOneFromCart = (product) => (dispatch, getState) => {
    dispatch(
        cartCall({
            product: product,
            onStart: productDeleted.type,
        })
    )
}

export const clearCart = () => (dispatch, getState) => {
    dispatch(
        cartCall({
            onStart: cartCleared.type,
        })
    )
}

// export const loadCart= (client, db) => (dispatch, getState) => {
//     dispatch(
//         cartApiCall({
//             client: client,
//             db: db,
//             onStart: cartRequested.type,
//             onSuccess: cartReceived.type,
//             onError: cartRequestFailed.type
//         })
//     )
// }

