import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Button, Grid } from '@material-ui/core';

// Púkin Logo
import pukinnLogo from '../../../../../../assets/pukinnlogo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3%'
  },
  iFrame: {
    width: '50%',
    height: '75vh',
    position: 'relative',
    border: 0
  },
  wrapper: {
    margin: 'auto',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  media: {
    height: 140,
  },
}));



const ACSForm = (props) => {
    const classes = useStyles();
    const [processed, setProcessed] = useState(false);
    const [message, setMessage] = useState('')

    useEffect(()=> {
        console.log('props._acsForm: ',props._acsForm)
    }, [props._acsForm])

    useEffect(()=> {
        window.addEventListener('message', (e)=>{
          var key = e.message ? 'message' : 'data';
          var data = e[key];
          if(data !== undefined) {
            if(data.AuthenticationStatus !== undefined) {
              var authStatus = data.AuthenticationStatus
              if(authStatus === 'error') {
                setProcessed(true)
                setMessage('NETWORK ERROR!')
              } else if(authStatus.toLowerCase() === 'n') {
                setProcessed(true)
                setMessage('CARD NOT AUTHENTICATED!')
              } else {
                props._borgunPayment(data)
              }
            }
          }
        })
    }, [])

    return <div>
        {props._acsForm.length > 0 ?
          <div className={classes.wrapper}>
            { !processed ?
              <iframe srcDoc={props._acsForm} className={classes.iFrame}></iframe> :
              <Grid className={classes.root} container direction='column' justify='space-evenly' alignItems='center'>
                <Grid item><img src={pukinnLogo} className={classes.media}></img></Grid>
                <Grid item><Typography gutterBottom variant="h5" component="h2">{message}</Typography></Grid>
                <Grid item>
                  <Grid container justify='center' alignItems='center'>
                    <Grid item><Button onClick={()=>props._close()} variant='contained' color='secondary'>CLOSE</Button>
                  </Grid>
                </Grid></Grid>
            </Grid>
            }
          </div> : <div></div>
        }
    </div>
}

export default ACSForm;