import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, IconButton, Divider, List, Typography,
        Button, ClickAwayListener } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Store
import { getFilteredCart } from '../../../store/cart/cart';

// Components
import ProductInCart from './ProductInCart/productInCart';

// React Router
import { Link } from 'react-router-dom';

// Price Format
const { format } = require('number-currency-format');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    zIndex: '10',
    backgroundColor: 'white',
    width: '100%',
    borderBottom: '1px solid #D5D5D5'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  image: {
    width: '10%'
  },
  list: {
    paddingTop: '25%'
  },
  checkout: {
    backgroundColor: 'red',
    color: 'white'
  },
  price: {
    color: 'green'
  },
  emptyCart: {
    marginTop: '5%'
  }
}));

const Cart = (props) =>{
    const classes = useStyles();
    const theme = useTheme();

    var productsInCart = useSelector(getFilteredCart)

    const [totalPrice, setTotalPrice] = useState(0)
    useEffect(()=>{
      if(productsInCart.length > 0){
        var tp = productsInCart.reduce((acc, val)=>
          acc + (Math.ceil(parseInt(val.detailsToBuy.price) - (parseInt(val.detailsToBuy.price) * val.detailsToBuy.discount / 100)) * val.qtyToBuy),0
        )
        setTotalPrice(tp)
      }
    },[productsInCart])

    return <div>
      <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='right'
          open={props._openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
      >
          <div className={classes.drawerHeader}>
            <IconButton onClick={props._handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            {productsInCart.length > 0 ?
              <div>
                <Link to='/checkout'><Button onClick={()=>props._handleDrawerClose()} variant='contained' className={classes.checkout}>
                  <FormattedMessage 
                    id='cart.checkout' 
                    defaultMessage='CHECKOUT '
                  />
                </Button></Link>
                <Typography className={classes.price}><FormattedMessage 
                    id='cart.total' 
                    defaultMessage='TOTAL'
                />: {format(totalPrice,{
                      currency: 'isk',
                      showDecimals: 'NEVER',
                      thousandSeparator: ' '
                  })}
                </Typography>
              </div> :
              <div></div>
            }
          </div>
          <List className={classes.list}>
            {productsInCart.length > 0 ?
              productsInCart.map((product, i) => {
                // return <div></div>
                return <ProductInCart key={i} _product={product} _index={i}></ProductInCart>
              }) :
              <Typography className={classes.emptyCart}>
                <FormattedMessage 
                  id='cart.empty' 
                  defaultMessage='CART IS EMPTY'
                />
              </Typography>
            }
          </List>
          {/* <Divider /> */}
      </Drawer>
    </div>
}

export default Cart;