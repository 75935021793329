import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { packagePricingApiCall } from '../actions';

const slice = createSlice({
    name: 'packagePricing',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        packagePricingRequested: (state, action) => {
            state.loading = true
        },

        packagePricingRequestFailed: (state, action) => {
            state.loading = false
        },

        packagePricingReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, packagePricingRequested, packagePricingRequestFailed, packagePricingReceived } = slice.actions
export default slice.reducer;

export const getFilteredPackagePricing = createSelector(
    state => state.packagePricing,
    (packagePricing) => packagePricing.list,
)

export const loadPackagePricing = (client, db) => (dispatch, getState) => {
    dispatch(
        packagePricingApiCall({
            client: client,
            db: db,
            onStart: packagePricingRequested.type,
            onSuccess: packagePricingReceived.type,
            onError: packagePricingRequestFailed.type
        })
    )
}