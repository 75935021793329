import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const bikeBookingApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.bikeBookingApiCall.type) return next(action)
    const { client, db, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([ { $match: { forbooking: true } } ]).toArray()
            .then((result)=>{
                if(onSuccess) dispatch({ type: onSuccess, payload: result})
                else dispatch(actions.bikeBookingApiCallSucess(result))
            })
            .catch((e)=>{
                if(e) dispatch({ type: onError, payload: e.message })
                else dispatch(actions.bikeBookingApiCallFailed(e.message))
            })
    }); 
    return next(action)
}

export default bikeBookingApi;