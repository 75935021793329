import React, { useEffect, useState, createRef } from 'react';

// Material UI
import { makeStyles, Grid, Card, Button } from '@material-ui/core/';

// React Router
import { useHistory } from 'react-router-dom';

// React to pdf
import Pdf from 'react-to-pdf';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Images
import pukinnLogo from '../../../../assets/pukinnlogo.png';

const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid grey'  
    },
    img: {
        width: '35%',
        alignItems: 'center'
    },
    tr: {
        borderRight: '1px solid grey'
    },
    td: {
        textAlign: 'left',
    }
}));

const Receipt = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const ref = createRef();

    const [customer, setCustomer] = useState({})
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4,2]
    };

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        var c = window.localStorage.getItem('pukinn_customer')
        if(c !== null){
            console.log(JSON.parse(c))
            setCustomer(JSON.parse(c))
        } else {
            history.push('/')
        }
    },[])

    function handleClose(){
        window.localStorage.removeItem('pukinn_customer')
        history.push('/')
    }

    return <div style={{marginTop: props._marginTop}}>
        {Object.keys(customer).length > 0 ?
            <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
                <Grid item lg={10} xs={10}>
                    <Card className={classes.container}>
                        <div style={{width: '100%', height: '100%'}} ref={ref}>
                            <img className={classes.img} src={pukinnLogo} />
                            <h4>{customer.date}</h4>
                            <table style={{ width: '100%', border: '1px solid grey'}}>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td className={classes.td}>KENNITALA</td>
                                    <td className={classes.td}>{customer.kennitala}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='bikebooking.bike' 
                                        defaultMessage='BIKE'
                                    /></td>
                                    <td className={classes.td}>{customer.bike.description}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='product.size' 
                                        defaultMessage='SIZE'
                                    /></td>
                                    <td className={classes.td}>{customer.bike.size}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='bikebooking.helmet' 
                                        defaultMessage='HELMET'
                                    /></td>
                                    <td className={classes.td}>{customer.helmet}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='bikebooking.armor' 
                                        defaultMessage='BODY ARMOR'
                                    /></td>
                                    <td className={classes.td}>{customer.bodyArmor}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='bikebooking.protectors' 
                                        defaultMessage='PROTECTORS'
                                    /></td>
                                    <td className={classes.td}>{customer.protectors}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='receipt.method' 
                                        defaultMessage='PAYMENT METHOD'
                                    /></td>
                                    <td className={classes.td}>{customer.payThrough}</td>
                                </tr>
                                <tr>
                                    <td className={classes.td}><FormattedMessage 
                                        id='cart.total' 
                                        defaultMessage='TOTAL'
                                    /></td>
                                    <td className={classes.td}>{customer.price} {customer.currency === '352' ? 'ISK' : 'EUR'}</td>
                                </tr>
                            </table>
                        </div>

                        <Pdf targetRef={ref} filename={`Receipt-${customer.kennitala}`} onComplete={handleClose} scale={0.7}>
                            {({ toPdf }) => <Button onClick={toPdf} variant='contained' color='secondary'><FormattedMessage 
                                id='receipt.downloadpdf' 
                                defaultMessage='DOWNLOAD IN PDF'
                            /></Button>}
                        </Pdf>
                    </Card>
                </Grid>
            </Grid> :
            <div></div>
        }
    </div>
}

export default Receipt;