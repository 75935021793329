import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { productsApiCall } from '../actions';

const slice = createSlice({
    name: 'products',
    initialState: {
        list: [],
        loading: false,
        empty: false,
        lastFetch: null
    },
    reducers: {
        productsRequested: (state, action) => {
            state.loading = true
        },

        productsRequestFailed: (state, action) => {
            state.loading = false
        },

        productsReceived: (state, action) => {
            if(action.payload.length > 0){
                if(action.newQuery){
                    state.list = action.payload;
                } else {
                    state.list = state.list.concat(action.payload);
                }
                state.empty = false;
                state.loading = false;
            } else {
                if(action.newQuery){
                    state.list = []
                }
                state.empty = true;
                state.loading = false;
            }
        },
    }
})
export const { client, db, query, skip, sort, productsRequested, productsRequestFailed, productsReceived } = slice.actions
export default slice.reducer;

export const getFilteredProducts = createSelector(
    state => state.products,
    (products) => {
        return {
            products: products.list,
            emptyList: products.empty
        }
    }
)

export const loadProducts = (client, db, query, category, skip, sort, newQuery) => (dispatch, getState) => {
    dispatch(
        productsApiCall({
            client: client,
            db: db,
            query: query,
            category: category,
            skip: skip,
            sort: sort,
            newQuery: newQuery,
            onStart: productsRequested.type,
            onSuccess: productsReceived.type,
            onError: productsRequestFailed.type
        })
    )
}