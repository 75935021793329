import React, { useState, useEffect } from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Firebase
import * as firebase from 'firebase';

// Material UI
import { makeStyles, Grid, Card, CardHeader, CardContent,
    Typography, Switch, CardActions, Select, MenuItem,
    InputLabel, Divider, Checkbox, Modal, Fade,
    Backdrop } from '@material-ui/core/';

// FormatJS (Internationalization)
import { useIntl, FormattedMessage } from 'react-intl';

// React Responsive Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Moment JS
import moment from 'moment';

// Axios
import axios from 'axios';

// Utils
import { isk_to_eur } from './Payment/cardUtils.js';

// Demon Knight
import demonKnight from '../../../../../assets/icons/knight_demon.png';


// Components
import TermsAndConditions from '../../TermsAndConditions/termsAndConditions';
import Payment from './Payment/payment';
import Success from '../../../../Modals/Success/success';
import ErrorModal from '../../Product/ErrorModal/errorModal';

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '1%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    packageCard: {
        // maxWidth: '500',
        margin: '1%',
        height: '100%'
    },
    prodImg: {
        backgroundColor: 'white',
        maxWidth: '50%'
    },
    blackText: {
        color: 'black',
        fontSize: '17px'
    },
    greenText: {
        color: 'green'
    },
    termsAndCond: {
        color: 'blue',
        cursor: 'pointer'
    },
    name: {
        borderBottom: '1px solid grey'
    },
    bold: {
        fontWeight: 'bold'
    }
}));


const BookingCheckout = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const intl = useIntl();
    
    const [basePrice, setBasePrice] = useState('12000')
    const [euroRate, setEuro] = useState(0)
    const [totalInEuro, setTotalInEuro] = useState('0')
    const [showInCurrency, setShowInCurrency] = useState('352')
    const [showEuroOption, setShowEuroOption] = useState(false)

    const [protectorPackage, setProtectorPackage] = useState(false)
    const [helmet, setHelmet] = useState('')
    const [kneeProtectors, setKneeProtectors] = useState('')
    const [bodyArmor, setBodyArmor] = useState('')
    const [termsAndCondCheck, setTermsAndCondCheck] = useState(false)
    const [showTermsAndCond, setShowTermsAndCond] = useState(false)

    const [showFinishBooking, setShowFinishedBooking] = useState(false)
    const [finishMsg, setFinishMsg] = useState('')
    const [errorBooking, setErrorBooking] = useState(false)

    const [dateForm, setDateForm] = useState(new Date())
    const [date, setDate] = useState('')
    const [nextDate, setNextDate] = useState('')

    const [newClientKey, setNewKey] = useState('')

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        var dateForm = props._pickedDate
        var date = moment(props._pickedDate).format('DD MMMM YYYY')
        var nextDate = moment(props._pickedDate.setDate(props._pickedDate.getDate()+1)).format('DD MMMM YYYY')
        setDateForm(dateForm)
        setDate(date)
        setNextDate(nextDate)
    },[props._pickedDate])

    useEffect(()=>{
        axios.get('https://apis.is/currency/m5')
            .then((res)=>{
                console.log(res)
                res.data.results.forEach(val=>{
                    if(val.shortName === 'EUR'){
                        setEuro(val.value)
                        var result = isk_to_eur(parseFloat(basePrice), val.value)
                        setTotalInEuro(result)
                        setShowEuroOption(true)
                    }
                })
            }).catch((err)=>{
                setShowEuroOption(false)
                console.log(err)
            })
    },[])

    function handleChangeCurrency(val){
        setShowInCurrency(val)
    }

    function handleProtectorPackage(e){
        var checked = e.target.checked
        setProtectorPackage(checked)
        if(checked){
            var result = isk_to_eur(parseFloat('15000'), euroRate)
            setTotalInEuro(result)
            setBasePrice('15000')
        } else {
            var result = isk_to_eur(parseFloat('12000'), euroRate)
            setTotalInEuro(result)
            setBasePrice('12000')
        }
    }

    function handleChangeTermsAndCond(e){
        var checkedTermsAndCond = e.target.checked
        setTermsAndCondCheck(checkedTermsAndCond)
    }

    function handleShowTermsAndCond(){
        setShowTermsAndCond(true)
    }

    function bookNow(kennitala){
        const db = firebase.database();
        var chosenBike = {}
        chosenBike['_id'] = props._pickedBike.groupid
        chosenBike['itemcode'] = props._pickedBike.itemcode
        chosenBike['description'] = props._pickedBike.description
        chosenBike['size'] = props._pickedBike.size

        var customer = {
            actionCode: '0000',
            authCode: '0000',
            kennitala: kennitala,
            payThrough: 'Sækja',
            orderId: '0000',
            price: parseFloat(basePrice),
            bike: chosenBike,
            date: dateForm.toString(),
            paymentMethod: { 'PaymentType': 'Sækja' },
            transactionDate: dateForm.toString(),
            transactionId: '0000',
            currency: showInCurrency
        };
        if(protectorPackage) {
            customer.helmet = helmet
            customer.bodyArmor = bodyArmor
            customer.protectors = kneeProtectors
        } else {
            customer.helmet = 'No helmet'
            customer.bodyArmor = 'No body armor'
            customer.protectors = 'No knee protectors'
        }
        var newKey = db.ref('bikebooking').push().key;
        customer.id = newKey
        setNewKey(newKey)
        db.ref('bikebooking/'+newKey).update(customer)
            .then(()=>{
                window.localStorage.setItem('pukinn_customer', JSON.stringify(customer));
                setShowFinishedBooking(true)
                setFinishMsg('YOUR BIKE HAS BEEN SUCCESSFULLY BOOKED AND IS WAITING FOR THE PICK UP')
            }) 
            .catch((error)=>{
                setShowFinishedBooking(false)
                setErrorBooking(true)
                setFinishMsg('SOMETHING WENT WRONG. PLEASE TRY AGAIN!')
            })
    }

    function handleCloseBooking(){
        setShowFinishedBooking(false)
        history.push(`/receipt`)
    }

    return <Grid container direction='row' justify='center'>
        <Modal
            className={classes.modal}
            open={showFinishBooking}
            onClose={handleCloseBooking}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade>
                <Success _message={finishMsg} _closeSuccessModal={handleCloseBooking}></Success>
            </Fade>
        </Modal>
        <Modal
            className={classes.modal}
            open={errorBooking}
            onClose={()=>setErrorBooking(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade>
                <ErrorModal _message={finishMsg}></ErrorModal>
            </Fade>
        </Modal>
        <Modal
            className={classes.modal}
            open={showTermsAndCond}
            onClose={()=>setShowTermsAndCond(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showTermsAndCond}>
                <Card style={{margin: '5%', maxHeight: '50%', overflow: 'auto'}}><TermsAndConditions></TermsAndConditions></Card>
            </Fade>
        </Modal>
        <Grid item lg={6} xs={12}>
            <Card className={classes.packageCard}>
                <CardHeader
                    title={<Grid container direction='column' jusitfy='center' alignItems='center'>
                        <Grid item className={classes.name}>{props._pickedBike.description.toUpperCase()}</Grid>
                        <Grid item><FormattedMessage 
                            id='product.size' 
                            defaultMessage='SIZE'
                        />: {props._pickedBike.size.toUpperCase()}</Grid>
                    </Grid>}
                    subheader={
                        `${intl.formatMessage({ id: 'bikebooking.for', defaultMessage: 'FOR' })}: ${date} |
                        ${showInCurrency === '352' ? basePrice + ' isk' : totalInEuro + ' eur'} `
                    }
                />
                <Carousel
                    renderItem={item => <div style={{ background: 'white', height: '100%' }}>{item}</div>}
                    showThumbs={false}
                    showIndicators={false}
                >
                    {props._pickedBike.images.length > 0 ?
                        props._pickedBike.images.map((image,i)=>{
                            return <img className={classes.prodImg} key={i} src={image}/>
                        }) :
                        <img className={classes.prodImg} src={demonKnight}/>
                    }
                </Carousel>
                {/* <CardContent>
                    <Typography variant='body2' color='textSecondary' component='p'>
                        {props._pickedBike.itemdescription}
                    </Typography>
                </CardContent> */}
                <CardActions style={{margin: 'auto'}}>
                    <Typography>
                        <FormattedMessage 
                            id='bikebooking.package' 
                            defaultMessage='ADD PROTECTOR PACKAGE | EXTRA 3000 isk'
                        />
                    </Typography>
                    <Switch
                        checked={protectorPackage}
                        onChange={handleProtectorPackage}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </CardActions>
                {protectorPackage ? 
                    <CardActions>
                        <Grid container direction='row' justify='space-evenly' alignItems='center'>
                            <Grid item>
                                <a className={classes.blackText} target="_blank" rel="noopener noreferrer" href='https://www.foxracing.com/mtb-size-chart.html#helmets'>
                                    <FormattedMessage 
                                        id='bikebooking.sizeguide' 
                                        defaultMessage='CHECK SIZE GUIDE'
                                    />
                                </a>
                                <InputLabel><FormattedMessage 
                                    id='bikebooking.helmet' 
                                    defaultMessage='HELMET'
                                /></InputLabel>
                                <Select
                                    value={helmet}
                                    onChange={(e)=>setHelmet(e.target.value)}
                                >
                                    <MenuItem value={'s'}>S</MenuItem>
                                    <MenuItem value={'m'}>M</MenuItem>
                                    <MenuItem value={'l'}>L</MenuItem>
                                    <MenuItem value={'x'}>X</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <a className={classes.blackText} target="_blank" rel="noopener noreferrer" href='https://www.foxracing.com/mtb-size-chart.html#jerseys'>
                                    <FormattedMessage 
                                        id='bikebooking.sizeguide' 
                                        defaultMessage='CHECK SIZE GUIDE'
                                    />
                                </a>
                                <InputLabel><FormattedMessage 
                                    id='bikebooking.armor' 
                                    defaultMessage='BODY ARMOR'
                                /></InputLabel>
                                <Select
                                    value={bodyArmor}
                                    onChange={(e)=>setBodyArmor(e.target.value)}
                                >
                                    <MenuItem value={'s/m'}>S/M</MenuItem>
                                    <MenuItem value={'l/xl'}>L/XL</MenuItem>
                                    <MenuItem value={'x/xl'}>X/XL</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <a className={classes.blackText} target="_blank" rel="noopener noreferrer" href='https://www.foxracing.com/mtb-size-chart.html#guards'>
                                    <FormattedMessage 
                                        id='bikebooking.sizeguide' 
                                        defaultMessage='CHECK SIZE GUIDE'
                                    />
                                </a>
                                <InputLabel><FormattedMessage 
                                    id='bikebooking.protectors' 
                                    defaultMessage='KNEE PROTECTORS'
                                /></InputLabel>
                                <Select
                                    value={kneeProtectors}
                                    onChange={(e)=>setKneeProtectors(e.target.value)}
                                >
                                    <MenuItem value={'s'}>S</MenuItem>
                                    <MenuItem value={'m'}>M</MenuItem>
                                    <MenuItem value={'l'}>L</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </CardActions> : 
                    <div></div>
                }
            </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Card>
                <CardHeader
                    title={`${intl.formatMessage({ id: 'bikebooking.yourorder', defaultMessage: 'YOUR ORDER' })}`}
                />
                <CardContent>
                    <Typography align='left'><span className={classes.bold}>
                        <FormattedMessage 
                            id='bikebooking.bike' 
                            defaultMessage='BIKE'
                        />:</span> {props._pickedBike.description.toUpperCase()} 
                        <span className={classes.bold}> <FormattedMessage 
                            id='product.size' 
                            defaultMessage=' SIZE'
                        />:</span> {props._pickedBike.size.toUpperCase()} | <span className={classes.greenText}>{showInCurrency === '352' ? basePrice + ' ISK' : totalInEuro + ' EUR'}</span></Typography>
                    {protectorPackage ?
                        <div>
                            <Typography align='left'><FormattedMessage 
                                id='bikebooking.protectorpackage' 
                                defaultMessage='PROTECTOR PACKAGE'
                            />: <span className={classes.greenText}>3000 ISK</span></Typography>
                            <Typography align='left'><FormattedMessage 
                                id='bikebooking.helmet' 
                                defaultMessage='HELMET'
                            /> <FormattedMessage 
                                id='product.size' 
                                defaultMessage='SIZE'
                            />: {helmet.toUpperCase()}</Typography>
                            <Typography align='left'><FormattedMessage 
                                id='bikebooking.armor' 
                                defaultMessage='BODY ARMOR'
                            /> <FormattedMessage 
                                id='product.size' 
                                defaultMessage='SIZE'
                            />: {bodyArmor.toUpperCase()}</Typography>
                            <Typography align='left'><FormattedMessage 
                                id='bikebooking.protectors' 
                                defaultMessage='KNEE PROTECTORS'
                            /> <FormattedMessage 
                                id='product.size' 
                                defaultMessage='SIZE'
                            />: {kneeProtectors.toUpperCase()}</Typography>
                        </div> :
                        <div></div>
                    }
                    <Divider className={classes.divider}/>
                    <Typography align='left'><FormattedMessage 
                        id='bikebooking.bookedfor' 
                        defaultMessage='BOOKED FOR'
                    />: {date}</Typography>
                    <Typography align='left'><FormattedMessage 
                        id='bikebooking.return' 
                        defaultMessage='RETURN DATE'
                    />: {nextDate}</Typography>
                    <Divider className={classes.divider}/>
                    <Typography style={{fontWeight: 'bold'}} align='left'><FormattedMessage 
                        id='cart.total' 
                        defaultMessage='TOTAL'
                    />: <span className={classes.greenText}>{showInCurrency === '352' ? basePrice + ' ISK' : totalInEuro + ' EUR'}</span></Typography>
                    
                    <Typography align='left'>
                        <Checkbox checked={termsAndCondCheck} onChange={handleChangeTermsAndCond} name="checkedA" />
                        <FormattedMessage 
                            id='cart.termsmsgshort' 
                            defaultMessage='By checking the box you agree to our '
                        />
                        <span className={classes.termsAndCond} onClick={handleShowTermsAndCond}> <FormattedMessage 
                            id='footer.column4.terms' 
                            defaultMessage='Terms and Conditions'
                        /></span>
                    </Typography>
                </CardContent>
                {termsAndCondCheck ?
                    <Payment 
                        _protectorPackage={protectorPackage}
                        _helmet={helmet} 
                        _bodyArmor={bodyArmor}
                        _kneeProtectors={kneeProtectors}
                        _bookNow={bookNow}
                        // _groupID={props._pickedBike.groupid}
                        _handleChangeCurrency={handleChangeCurrency}
                        _selectedCurrency={showInCurrency}
                        _iskPrice={basePrice}
                        _eurPrice={totalInEuro}
                        _pickedBike={props._pickedBike}
                        _showEuroOption={showEuroOption}>
                    </Payment> : 
                    <div></div>
                }
            </Card>
        </Grid>
    </Grid>
}

export default BookingCheckout;