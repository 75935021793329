import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Grid, Typography, Card, CardMedia,
        CardContent, CardActions, CardHeader, Collapse,
        AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Store
import { loadBrands, getFilteredBrands } from '../../../../store/brands/brands';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Placeholder Image
import knightdemon from '../../../../assets/icons/knight_demon.png';

// React Card Flip
import ReactCardFlip from 'react-card-flip';

// React Router
import { Link, useHistory } from "react-router-dom";

// Components
import Brand from './Brand/brand';

const useStyles = makeStyles((theme) => ({
    text: {
        color: 'black',
    },
    media: {
        width: 100,
        margin: 'auto',
    },
    description: {
        cursor: 'pointer',
        color: '#ED1C24'
    },
    card: {
        height: '100%',
        cursor: 'pointer'
    },
    loadingContainer: {
        height: '80vh'
    }
}));

const Brands = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    var brands = useSelector(getFilteredBrands);

    const [isFlipped, setFlip] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            dispatch(loadBrands(client, db));
            setLoading(false)
        }
    },[props._mongoClient])

    return <div style={{ marginTop: props._marginTop }}>
        {loading !== true ?
            <Grid container justify='center'>
                <Grid item xs={10}>
                    <Grid container spacing={2}>
                        {brands.map((brand, i)=> {
                            return <Brand key={i} _brand={brand}></Brand>
                        })}
                    </Grid>
                </Grid>
            </Grid> :
            <Grid container className={classes.loadingContainer} justify='center' direction='row' alignItems='center'>
                <CircularProgress/>
            </Grid>
        }
        
    </div>
}

export default Brands;