import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, Button, Divider, Typography, ListItem,
        ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { Carousel } from 'react-responsive-carousel';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Store
import { deleteOneFromCart } from '../../../../store/cart/cart';

// Knight Demon
import knightDemon from '../../../../assets/icons/knight_demon.png';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'black'
    },
    image: {
        width: '10px'
    },
    divider: {
        marginTop: '3%'
    },
    price: {
        color: 'green'
    }
}));

const ProductInCart = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return <div>
        <ListItemText 
            primary={'(' + props._product.qtyToBuy + 'x) ' + props._product.description.toUpperCase()} 
            secondary={format((Math.ceil(props._product.detailsToBuy.price) - (parseInt(props._product.detailsToBuy.price) * props._product.detailsToBuy.discount / 100)), {
                currency: 'isk',
                showDecimals: 'NEVER',
                thousandSeparator: ' '
            })}
        />
        <Typography variant='subtitle2' gutterBottom>
            <FormattedMessage 
                id='cart.size' 
                defaultMessage='SIZE'
            />: {props._product.detailsToBuy.size.toUpperCase()}
        </Typography>
        <Typography variant='subtitle2' gutterBottom>
            <FormattedMessage 
                id='cart.total' 
                defaultMessage='TOTAL'
            />: <span className={classes.price}>
                    {format((Math.ceil(props._product.detailsToBuy.price) - (parseInt(props._product.detailsToBuy.price) * props._product.detailsToBuy.discount / 100)) * props._product.qtyToBuy, {
                    currency: 'isk',
                    showDecimals: 'NEVER',
                    thousandSeparator: ' '
                })}
            </span>
        </Typography>
        <ListItem>
            <Carousel showThumbs={false}>
                {props._product.images.length > 0 ?
                    props._product.images.map((image, j)=>{
                        if(image !== undefined){
                            return <img key={j} className={classes.image} src={image} />
                        } else {
                            return <img className={classes.image} src={knightDemon} />
                        }
                    }) :
                    <img className={classes.image} src={knightDemon} />
                }
            </Carousel>
        </ListItem>
        {/* <Button
            onClick={()=>dispatch(deleteOneFromCart(props._index))}
            variant='contained'
            size='small'
            className={classes.button}
            startIcon={<DeleteForeverIcon />}
        >
            SIZE: {props._product.detailsToBuy.size}
        </Button> */}
        <Button
            onClick={()=>dispatch(deleteOneFromCart(props._index))}
            variant='contained'
            size='small'
            className={classes.button}
            startIcon={<DeleteForeverIcon />}
        >
            <FormattedMessage 
                id='cart.delete' 
                defaultMessage='DELETE'
            />
        </Button>
        <Divider className={classes.divider} />
    </div>
}

export default ProductInCart;