import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Material UI
import { makeStyles, Grid, FormControl, InputLabel, Select,
        MenuItem, Stepper, Step, StepLabel, Modal, 
        Fade, Backdrop } from '@material-ui/core/';

// Store
import { loadMakes, getFilteredMakes } from '../../../../store/makes/makes';
import { loadModels, getFilteredModels } from '../../../../store/models/models';
import { loadYears, getFilteredYears } from '../../../../store/years/years';
import { loadFits, getFilteredFits } from '../../../../store/fits/fits';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Components
import Fits from './Fits/fits';
import ErrorModal from '../Product/ErrorModal/errorModal';

const useStyles = makeStyles((theme) => ({
    
}));

const FitMyBike = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const makes = useSelector(getFilteredMakes);
    const models = useSelector(getFilteredModels);
    const years = useSelector(getFilteredYears);
    const fits = useSelector(getFilteredFits);

    const [client, setClient] = useState(null)
    const [db, setDB] = useState(null)
    const [db2, setDB2] = useState(null)

    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [year, setYear] = useState('');

    const [showModels, setShowModels] = useState(null);
    const [showYears, setShowYears] = useState(null); 
    const [showFits, setShowFits] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [containerHeight, setContainerHeight] = useState('')

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        // console.log(makes)
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            const db2 = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            setClient(client)
            setDB(db)
            setDB2(db2)
            dispatch(loadMakes(client, db2));
        }
        if(props._isMobile) {
            setContainerHeight('')
        }
    },[props._mongoClient])

    useEffect(()=>{
        if(models.length > 0) {
            setActiveStep(1)
            setShowModels(true)
        } else {
            // setErrorMsgBool(true)
            // setErrorMsg(`NO MODELS FOUND FOR ${make.toUpperCase()}`)
            setShowModels(null)
        }
    },[models])

    useEffect(()=>{
        if(years.length > 0) {
            setActiveStep(2)
            setShowYears(true)
        } else {
            // setErrorMsgBool(true)
            // setErrorMsg(`NO YEARS FOUND FOR ${model.toUpperCase()}`)
            setShowYears(null)
        }
    },[years])

    useEffect(()=>{
        if(fits.length > 0){
            setShowFits(true)
            setActiveStep(3)
        }
    },[fits])

    function handleChangeMake(e){
        setYear('')
        setModel('')
        setMake(e.target.value)
        setActiveStep(0)
        setShowModels(null)
        setShowYears(null)
        setShowFits(null)
        dispatch(loadModels(client, db, e.target.value));
    }

    function handleChangeModel(e){
        setYear('')
        setModel(e.target.value)
        setActiveStep(1)
        setShowYears(null)
        setShowFits(null)
        dispatch(loadYears(client, db, make, e.target.value));
    }

    function handleChangeYear(e){
        setYear(e.target.value)
        setActiveStep(2)
        setShowFits(null)
        dispatch(loadFits(client, db, make, model, e.target.value));
    }

    return(
        <div style={{ marginTop: props._marginTop, height: containerHeight }}>
            <Modal
                className={classes.modal}
                open={errorMsgBool}
                onClose={()=>setErrorMsgBool(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={errorMsgBool}>
                    <ErrorModal _message={errorMsg}></ErrorModal>
                </Fade>
            </Modal>
            <Grid container justify='center'>
                <Grid item xs={10}>  
                    <Stepper activeStep={activeStep} completed={showModels}>
                        <Step active={true}>
                            <StepLabel><FormattedMessage id='fitmybike.pickmake' 
                                defaultMessage='Pick the brand of your bike'/></StepLabel>
                        </Step>
                        <Step active={showModels} completed={showYears}>
                            <StepLabel><FormattedMessage id='fitmybike.pick' 
                                defaultMessage='Pick the '/> {make.toUpperCase()} <FormattedMessage id='fitmybike.pickmodel' 
                                defaultMessage=' make'/></StepLabel>
                        </Step>
                        <Step active={showYears} completed={showFits}>
                            <StepLabel><FormattedMessage id='fitmybike.pick' 
                                defaultMessage='Pick the '/> {model.toUpperCase()} <FormattedMessage id='fitmybike.pickyear' 
                                defaultMessage=' make'/></StepLabel>
                        </Step>
                    </Stepper>
                    <Grid container>
                        <Grid item xs={4}>   
                            <InputLabel id='makes'><FormattedMessage id='fitmybike.makeoption' 
                            defaultMessage='MAKE'/></InputLabel>
                            <FormControl className={classes.formControl}>
                                <Select
                                    styles={{dropdownIndicator: 'none'}}
                                    labelId='makes'
                                    id='makes'
                                    value={make}
                                    onChange={handleChangeMake}
                                >
                                    {makes.map((val, i)=>{
                                        return <MenuItem key={i} value={val.make}>{val.make.toUpperCase()}</MenuItem>    
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel id="models"><FormattedMessage id='fitmybike.modeloption' 
                            defaultMessage='MODEL'/></InputLabel>
                            <FormControl className={classes.formControl} disabled={!showModels}>
                                <Select
                                    labelId='models'
                                    id='models'
                                    value={model}
                                    onChange={handleChangeModel}
                                >
                                    {models.map((val, i)=>{
                                        return <MenuItem key={i} value={val._id}>{val._id.toUpperCase()}</MenuItem>    
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel id="years"><FormattedMessage id='fitmybike.yearoption' 
                            defaultMessage='YEAR'/></InputLabel>
                            <FormControl className={classes.formControl} disabled={!showYears}>
                                <Select
                                    labelId='years'
                                    id='years'
                                    value={year}
                                    onChange={handleChangeYear}
                                >
                                    {years.map((val, i)=>{
                                        return <MenuItem key={i} value={val._id}>{val._id.toUpperCase()}</MenuItem>    
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={10}>
                    <Fits _fits={fits}></Fits>
                </Grid>
            </Grid>
        </div>
    )
}

export default FitMyBike;