import React from 'react';

// Material UI
import { Snackbar, Button } from '@material-ui/core/';

// FormatJS (Internationalization)
import { useIntl, FormattedMessage } from 'react-intl';

const BaseCookie = (props) => {
    const intl = useIntl();
    return <Snackbar
        open={props._open}
        message={intl.formatMessage({ id: 'basecookie', defaultMessage: 'Like most websites Pukinn.com uses cookies. To deliver personalized and best experience for our users and improve our website we store the information on how you use it. Cookies are completely safe and secure and will never contain any sensitive information.' })}
        action={
            <React.Fragment>
                <Button color='secondary' size='small' onClick={props._acceptCookie}>
                    <FormattedMessage 
                        id='basecookie.accept' 
                        defaultMessage='ACCEPT AND CLOSE'
                    />
                </Button>
            </React.Fragment>
        }
    />
}

export default BaseCookie;