import React, { useState } from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Material UI
import { makeStyles, Grid, TextField, Button, Tooltip,
    Radio, FormControlLabel, Modal, Fade, Backdrop } from '@material-ui/core/';

// Cards
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { cc_expires_format, cc_format, bike_card_pay } from './cardUtils';

// Components
import ErrorModal from '../../../Product/ErrorModal/errorModal';
import ACSForm from './acsForm';
import LoadingSnackbar from '../../../../../Loading/loadingSnackbar';
import Success from '../../../../../Modals/Success/success';

// Axios
import axios from 'axios';

// Firebase
import firebase from 'firebase';

// FormatJS (Internationalization)
import { useIntl, FormattedMessage } from 'react-intl';

import borgunImage from '../../../../../../assets/borgun-logo-min.jpg';

const useStyles = makeStyles((theme) => ({
    packageCard: {
        // maxWidth: '500',
        margin: '1%',
        padding: '1%',
    },
    textField: {
        width: '100%'
    },
    payBtn: {
        margin: '1%',
        width: '100%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Payment = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const fDB = firebase.database();
    const intl = useIntl();

    const [selectedValue, setSelectedValue] = useState('a');
    const [cvc, setCvc] = useState('')
    const [expiry, setExpiry] = useState('')
    const [focus, setFocus] = useState('')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [kennitala, setKennitala] = useState('')
    const [showACSFORM, setShowACSFORM] = useState(false)
    const [acsForm, setACSForm] = useState('')
    const [token, setToken] = useState('')

    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')

    const [loading, setLoading] = useState(false)

    function handleInputFocus(e){
        setFocus(e.target.name );
    }
      
    function handleInputChange(e){
        const { name, value } = e.target;
        if(name === 'number'){
            var formatedNumber = cc_format(value)
            setNumber(formatedNumber)
        } else if(name === 'name') {
            setName(value)
        } else if(name === 'expiry') {
            var formatedExpiry = cc_expires_format(value)
            setExpiry(formatedExpiry)
        } else if(name === 'cvc') {
            setCvc(value)
        }
    }

    function handleChange(event){
        setSelectedValue(event.target.value);
    };

    function payNow(){
        if(cvc.length > 0 && expiry.length > 0 && number.length > 0){
            setLoading(true)
            var cardDetails = {   
                "PAN": number.replace(/\s/g,''),
                "ExpMonth": expiry.split('/')[0],
                "ExpYear": expiry.split('/')[1],
                "TokenLifetime": 5000,
            }
            axios({
                method: 'post',
                url: 'https://us-central1-pukinn2.cloudfunctions.net/tokenSingleV2',
                data: cardDetails,
            }).then((response) => {
                if(response.status === 200) {
                    setToken(response.data.Token)
                    var purch_price = 0
                    if(props._selectedCurrency === '352'){
                        purch_price = parseFloat(props._iskPrice)
                    } else {
                        purch_price = parseFloat(props._eurPrice)
                    }
                    var mpiEnrollmentData = {
                        "data": response.data,
                        "protector_package": props._protectorPackage,
                        "currency": props._selectedCurrency,
                        "purch_price": purch_price * 100
                    }
                    axios({
                        method: 'post',
                        url: 'https://us-central1-pukinn2.cloudfunctions.net/mpiEnrollmentBikeBookingV2',
                        data: mpiEnrollmentData
                    }).then((response) => {
                        console.log('response.data: ', response.data)
                        setACSForm(response.data.RedirectToACSForm)
                        setLoading(false)
                        setShowACSFORM(true)
                        console.log(response)
                    }).catch((err) => {
                        setLoading(false)
                        setErrorMsg('SOMETHING WENT WRONG: ', err.message)
                        setShowError(true)
                    })
                } else {
                    setLoading(false)
                    setErrorMsg('COULD NOT PROCESS THE CARD. PLEASE TRY AGAIN.')
                    setShowError(true)
                }
            }).catch((error) => {
                setLoading(false)
                setErrorMsg('SOMETHING WENT WRONG: ', error.message)
                setShowError(true)
            });
        } else {
            setErrorMsg('MAKE SURE ALL FIELDS ARE FILLED')
            setShowError(true)
        }
    }  

    function borgunPayment(data) {
        setShowACSFORM(false)
        setLoading(true)
        fDB.ref('lastBookingCardOrderId').once('value').then((snap)=>{
            var orderId = ''
            var year = snap.val().year
            var idStr = snap.val().id
            var nextId = parseFloat(idStr)+1
            if(nextId.toString().length === 1){
                nextId = '0000000'+nextId.toString()
            } else if(nextId.toString().length === 2) {
                nextId = '000000'+nextId.toString()
            } else if(nextId.toString().length === 3) {
                nextId = '00000'+nextId.toString()
            } else if(nextId.toString().length === 4) {
                nextId = '0000'+nextId.toString()
            }
            var today = new Date();
            var currentYear = today.getFullYear()
            if(year === currentYear){
                orderId = year + nextId
            } else {
                nextId = '00000001'
                orderId = currentYear + nextId
            }

            var totalAmount = 0;
            var currency = props._selectedCurrency;
            var currentDate = new Date()
            var transactionDate = currentDate.toISOString();
            if(props._selectedCurrency === '352') {
                totalAmount = parseFloat(props._iskPrice)
            } else {
                totalAmount = parseFloat(props._eurPrice)
            }
            var options = {
                "TransactionType": "Sale",
                "Amount": totalAmount * 100,
                "Currency": currency,
                "TransactionDate": transactionDate,
                "PaymentMethod": {
                    "PaymentType": "TokenSingle",
                    "Token": token
                },
                "OrderId": orderId,
                "ThreeDSecure": {
                    "DataType": "Manual",
                    "SecurityLevelInd": "2",
                    "CAVV": data.CAVV,
                    "Xid": data.XId,
                },
            }
            axios({
                method: 'post',
                url: 'https://us-central1-pukinn2.cloudfunctions.net/paymentBikeBookingV2',
                data: options
            }).then((response) => {
                var d = response.data
                if(d.TransactionStatus.toLowerCase() === 'accepted'){
                    bike_card_pay(fDB, d, props._pickedBike, props._helmet, props._kneeProtectors, props._bodyArmor, props._selectedCurrency, kennitala, currentYear, nextId,  (res, resData) => {
                        if(res === 'success'){
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL! YOUR BIKE HAS BEEN SUCCESSFULLY BOOKED AND IS WAITING FOR THE PICK UP')
                            setShowSuccess(true)
                            window.localStorage.setItem('pukinn_customer', JSON.stringify(resData));
                        } else if('firebase_error') {
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL! PROBLEM WITH UPDATING ORDER ID')
                            setShowSuccess(true)
                        } else {
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL, BUT NOT ABLE TO SAVE TO DATABASE. PLEASE CONTACT US ABOUT YOUR ORDER ON +354 566 6820 OR SEND US AN EMAIL AT pukinn@pukinn.com')
                            setShowSuccess(true)
                        }
                    })
                } else {
                    setLoading(false)
                    setErrorMsg('PAYMENT NOT ACCEPTED! PLEASE TRY AGAIN')
                    setShowError(true)
                }
            }).catch((err) => {
                setLoading(false)
                setErrorMsg('SOMETHING WENT WRONG: ', err.message)
                setShowError(true)
            })
        }).catch((e)=>{
            setLoading(false)
            setErrorMsg('SOMETHING WENT WRONG: ', e.message)
            setShowError(true)
        })
    }

    function receiptPage() {
        setShowSuccess(false)
        history.push('/receipt')
    }

    const PaymentButton = () =>{
        const payBtn = <Button onClick={payNow} className={classes.payBtn} variant='contained' color='secondary'><FormattedMessage 
            id='bikebooking.bookandpay' 
            defaultMessage='BOOK AND PAY'
        /></Button>;
        if(props._protectorPackage && props._helmet !== ''
        && props._bodyArmor !== '' && props._kneeProtectors !== '' && kennitala.length === 10){
            return payBtn
        } else if(!props._protectorPackage && kennitala.length === 10) {
            return payBtn
        } else {
            return <Tooltip title='YOU HAVE NOT PICKED PROTECTOR PACKAGE SIZE OR FILL IN KENNITALA' placement='top'>
                <span><Button className={classes.payBtn} variant='contained' color='secondary' disabled><FormattedMessage 
                id='bikebooking.bookandpay' 
                defaultMessage='BOOK AND PAY'
            /></Button></span>
            </Tooltip>
        }
    }

    const BookButton = () =>{
        const bookBtn = <Button variant='contained' color='secondary' className={classes.payBtn} onClick={()=>props._bookNow(kennitala)}><FormattedMessage 
            id='bikebooking.book' 
            defaultMessage='BOOK'
        /></Button>
        if(props._protectorPackage && props._helmet !== ''
        && props._bodyArmor !== '' && props._kneeProtectors !== '' && kennitala.length === 10) {
            return bookBtn
        } else if (!props._protectorPackage && kennitala.length === 10) {
            return bookBtn
        } else {
            return <Tooltip title='YOU HAVE NOT PICKED PROTECTOR PACKAGE SIZE OR FILL IN KENNITALA' placement='top'>
                <span><Button className={classes.payBtn} variant='contained' color='secondary' disabled><FormattedMessage 
                    id='bikebooking.book' 
                    defaultMessage='BOOK'
                /></Button></span>
            </Tooltip>
        }
    }

    return <Grid className={classes.packageCard} container direction='column' alignItems='center'>
        <LoadingSnackbar _open={loading}></LoadingSnackbar>
        <Modal
            className={classes.modal}
            open={showError}
            onClose={()=>setShowError(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        <Modal
            className={classes.modal}
            open={showSuccess}
            onClose={receiptPage}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade>
                <Success _message={successMsg} _closeSuccessModal={receiptPage}></Success>
            </Fade>
        </Modal>
        <Modal
            // className={classes.modal}
            open={showACSFORM}
            disableEscapeKeyDown={true}
            onClose={()=>setShowACSFORM(false)}
        >
            <Fade>
                <ACSForm _acsForm={acsForm} _close={()=>setShowACSFORM(false)} _borgunPayment={borgunPayment}></ACSForm>
            </Fade>
        </Modal>
        <Grid item>
            <Grid container justify='space-between' direction='row' alignItems='flex-start'>
                <Grid item>
                    <Grid container justify='center' direction='column' alignItems='center'>
                        <Grid item>
                            <FormControlLabel 
                                value='end'
                                label={intl.formatMessage({ id: 'payment.cardpay', defaultMessage: 'PAY WITH CARD' })}
                                control={ <Radio
                                    checked={selectedValue === 'a'}
                                    onChange={(e)=>handleChange(e)}
                                    value='a'
                                    inputProps={{ 'aria-label': 'CARD' }}
                                />}
                            />   
                        </Grid>
                        <Grid item>
                            <img style={{width: '100px'}} src={borgunImage}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControlLabel 
                        value='end'
                        label={intl.formatMessage({ id: 'payment.pickup', defaultMessage: 'PAY ON PICK UP' })}
                        control={ <Radio
                            checked={selectedValue === 'b'}
                            onChange={(e)=>handleChange(e)}
                            value='b'
                            inputProps={{ 'aria-label': 'PICK UP' }}
                        />}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Grid className={classes.packageCard} container direction='column' alignItems='center'>
                {selectedValue === 'a' ?
                    <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
                        <Grid item className={classes.textField} >
                            <TextField 
                                label='KENNITALA'
                                className={classes.textField} 
                                value={kennitala} 
                                onChange={(e)=>setKennitala(e.target.value)}
                            ></TextField>
                        </Grid>
                        <Grid item>
                            <FormControlLabel 
                                value='end'
                                label={`ISK - ${props._iskPrice} isk`}
                                control={ <Radio
                                    checked={props._selectedCurrency === '352'}
                                    onChange={(e)=>props._handleChangeCurrency(e.target.value)}
                                    value='352'
                                    inputProps={{ 'aria-label': 'ISK' }}
                                />}
                            /> 
                            {props._showEuroOption ?
                                <FormControlLabel 
                                    value='end'
                                    label={`EUR - €${props._eurPrice}`}
                                    control={ <Radio
                                        checked={props._selectedCurrency === '978'}
                                        onChange={(e)=>props._handleChangeCurrency(e.target.value)}
                                        value='978'
                                        inputProps={{ 'aria-label': 'EUR' }}
                                    />}
                                /> : <div></div>
                            }  
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-evenly'>
                                <Grid item lg={6} xs={12}>
                                    <Grid container direction='column' justify='center'>
                                        <Grid item>
                                            <TextField
                                                className={classes.textField}
                                                type='tel'
                                                name='number'
                                                placeholder={intl.formatMessage({ id: 'payment.cardnumber', defaultMessage: 'CARD NUMBER' })}
                                                value={number}
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </Grid>
                                        {/* <Grid item>
                                            <TextField
                                                className={classes.textField}
                                                type='tel'
                                                name='name'
                                                placeholder='NAME'
                                                value={name.toUpperCase()}
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </Grid> */}
                                        <Grid item>
                                            <Grid container direction='row' spacing={1}>
                                                <Grid item xs={6} lg={6}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        type='text'
                                                        name='expiry'
                                                        placeholder={intl.formatMessage({ id: 'payment.exp', defaultMessage: 'MM/YY' })}
                                                        value={expiry}
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    <TextField
                                                        type='tel'
                                                        name='cvc'
                                                        placeholder='CVC'
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item><PaymentButton></PaymentButton></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Cards
                                        cvc={cvc}
                                        expiry={expiry}
                                        focused={focus}
                                        name={name}
                                        number={number}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> :
                    <Grid container direction='columns' justify='center' alignItems='center' spacing={1}>
                        <Grid item className={classes.textField}>
                            <TextField 
                                label='KENNITALA' 
                                type='number'                     
                                className={classes.textField} 
                                value={kennitala} 
                                onChange={(e)=>setKennitala(e.target.value)}
                            ></TextField>
                        </Grid>
                        <Grid item className={classes.textField}>
                            <BookButton></BookButton>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    </Grid>
}

export default Payment