import moment from 'moment';
import axios from 'axios';
import { AnonymousCredential, BSON } from 'mongodb-stitch-browser-sdk';

export function cc_format(value) {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, '')

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter(group => !!group).join(' ')
    )
}

export function cc_expires_format(string) {
    return string.replace(
        /[^0-9]/g, '' // To allow only numbers
    ).replace(
        /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
        /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
}

export async function get_tokenSingleV2(cardDetails) {
    var result = await axios({
        method: 'post',
        url: 'https://us-central1-pukinn2.cloudfunctions.net/tokenSingleV2',
        data: cardDetails,
    }).catch((e)=>{ return {status: 0, res: `Network Error: ${e}` } })
    if(result.status === 200) {
        return { status: 1, res: result.data.Token }
    }
    return { status: 0, res: `Could not fetch the data: ${result.status} Error!` };
}

function extractBarcodes(pack) {
    var result = []
    pack.forEach((prod)=>{ 
        result.push(prod.barcode);
    })
    return result;
}

function calculateTotalPrice(prices) {
    var result = prices.reduce((acc, val)=>{ 
        return acc += parseInt(val.barcode) 
    },0)
    return result;
}

function calculate_purch_after_giftcard(price, credit) {
    return price -= credit
}

export function gifcardAppliction(giftcard, totalPurchPrice, selectedCurrency, euroValue) {
    var credit = giftcard.res.credit
    var creditInEur = isk_to_eur(credit, euroValue)

    if(selectedCurrency === '352') {
        var result = calculate_purch_after_giftcard(totalPurchPrice, credit)
        if(result > 0) {
            return { type: 1, res: 'Can Proceed to Payment', credit: credit }
        } else {
            return { type: 0, res: 'GIFT CARD CREDIT IS HIGHER THAN PURCHASING PRICE. GIFT CARD CANNOT BE APPLIED!' }
        }
    } else {
        var result = calculate_purch_after_giftcard(totalPurchPrice, creditInEur)
        if(result > 0) {
            return { type: 1, res: 'Can Proceed to Payment', credit: creditInEur }
        } else {
            return { type: 0, res: 'GIFT CARD CREDIT IS HIGHER THAN PURCHASING PRICE. GIFT CARD CANNOT BE APPLIED!' }
        }
    }
}

export async function invalidate_giftcard(db, giftcard) {
    var objID = new BSON.ObjectId(giftcard._id);
    var result = await db.collection('giftcards').deleteOne({ _id: objID })
                        .catch((e)=> {
                            return { type: 0, res: `COULD NOT CONNECT TO DATABASE: ${e}` }
                        });
                        console.log(result)
    if(result.type === 0){
        return result
    } else {
        return { type: 1, res: 'Deleted' }
    }
}

async function get_packageTotalPrice(pack, client, db) {
    // client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
    var barcodes = extractBarcodes(pack);
    var result = await db.collection('products').aggregate([
        { 
            $match: {
                itemcode: {
                    $in: barcodes
                }
            }
        },
        { $project: { barcode: 1 } }
    ]).toArray().catch((e)=>{
        console.log(e)
    });
    var totalPackagePrice = calculateTotalPrice(result)
    return totalPackagePrice
}

export async function get_purchPrice(products, client , db) {
    var totalPrice = 0
    var totalPackagePrice = 0
    for (const product of products) {
        if(product.pack !== undefined) {
            var package_totalPrice = await get_packageTotalPrice(product.pack, client, db);
            totalPackagePrice += package_totalPrice
        } else {
            var priceWithDiscount = Math.ceil(parseInt(product.detailsToBuy.price) - (parseInt(product.detailsToBuy.price) * product.detailsToBuy.discount / 100))
            totalPrice += (priceWithDiscount * product.qtyToBuy)
        }
    }
    return { total: totalPrice, totalPackage: totalPackagePrice };
}

export async function get_Discount(db, price) {
    var result = await db.collection('packagepricing').aggregate([
        {$match: {price: {$lte: price}}},
        // Project a diff field that's the absolute difference along with the original doc.
        {$project: {diff: {$abs: {$subtract: [price, '$discount']}}, doc: '$$ROOT'}},
        // Order the docs by diff
        {$sort: {diff: 1}},
        // Take the first one
        {$limit: 1}
    ]).toArray();

    var discount_percents = 0;
    if(result.length > 0) {
        discount_percents = result[0].doc.discount
    }
    return Math.ceil(price - (price * discount_percents / 100))
}

export function prod_card_pay(db, apiData, products, deliveryInfo, year, id, currency, giftCard, iskGiftForDB, eurGiftForDB, callback) {
    var prodArr = []
    products.map((prod)=>{
        var cat = ''
        var gID = ''
        var subcat = ''
        var totalQTY = ''
        if(prod.pack !== undefined && prod.pack !== null){
            cat = `Package - ${prod.pack.length} items`
            gID = 'Package'
            subcat = 'Package'
            totalQTY = 1
        } else {
            cat = Object.keys(prod.category).toString();
            gID = prod._id;
            subcat = prod.subcategory;
            totalQTY = prod.qtyToBuy
        }

        var up1 = ''
        if(prod.unitprice1 !== undefined && prod.unitprice1 !== null){
            up1 = prod.unitprice1
        }
        
        var prodImage = ''
        if(prod.images.length > 0 && prod.detailsToBuy.barcode !== 'gjafakort') {
            prodImage = prod.images[0]
        }
        var obj = {
            barcode: Math.ceil(parseInt(prod.detailsToBuy.price) - (parseInt(prod.detailsToBuy.price) * prod.detailsToBuy.discount / 100)),
            brand: prod.brand,
            category: cat,
            description: prod.description,
            groupid: gID,
            img: prodImage,
            itemcode: prod.detailsToBuy.barcode,
            qty: totalQTY,
            size: prod.detailsToBuy.size,
            subcategory: subcat,
            unitprice1: up1,
        }
        prodArr.push(obj)
    })

    var newKey = db.ref('customers').push().key
    var customer = {
        address: `${deliveryInfo.streetName} ${deliveryInfo.streetNumber}, ${deliveryInfo.city} ${deliveryInfo.zip}, ${deliveryInfo.district}`,
        actionCode: apiData.ActionCode,
        authCode: apiData.AuthCode,
        dateOrder: moment().format('MMMM Do, YYYY'),
        email: deliveryInfo.email,
        id: newKey,
        orderId: apiData.OrderId,
        name: `${deliveryInfo.firstName} ${deliveryInfo.lastName}`,
        shipping: 1500,
        payThrough: 'Kort',
        phone: deliveryInfo.phone,
        products: prodArr,
        totalPrice: apiData.Amount / 100,
        processedAt: new Date(),
        paymentMethod: apiData.PaymentMethod,
        transactionDate: apiData.TransactionDate,
        transactionId: apiData.TransactionId,
        currency: currency
    }
    if(giftCard !== null && giftCard !== undefined){
        customer.giftcardBookId = giftCard.bookId
        if(currency === '352'){
            customer.giftcardcredit = iskGiftForDB
        } else {
            customer.giftcardcredit = eurGiftForDB
        }
    }
    console.log(customer)
    db.ref('customers/'+newKey).update(customer)
        .then(()=>{
            db.ref('lastCardOrderId').set({
                id: id,
                year: year
            }).then(()=>{
                callback('success', customer)
            }).catch((error)=>{
                callback('error', error.message)
            })
        }) 
        .catch((error)=>{
            callback('error', `Something Went Wrong: ${error}`)
        })
}

export function send_mpi_enrollmentV2(data, currency, price, callback) {
    var mpiEnrollmentData = {
        "data": data,
        "currency": currency,
        "purch_price": price * 100,
        // "issuer": issuer
        // "purch_price": price
    }
    axios({
        method: 'post',
        url: 'https://us-central1-pukinn2.cloudfunctions.net/mpiEnrollmentV2',
        data: mpiEnrollmentData
    }).then((response) => {
        console.log('RESPONSE: ', response)
        callback('success', response.data.RedirectToACSForm)
    }).catch((err) => {
        callback('error', err)
    })
}

export async function get_giftcard(db, giftcard_id) {
    var objID = new BSON.ObjectId(giftcard_id);
    var result = await db.collection('giftcards').findOne({ _id: objID })
                        .catch((e)=> {
                            return { type: 0, res: `COULD NOT CONNECT TO DATABASE: ${e}` }
                        });
    if(result.type === 0){
        return result
    } else {
        return { type: 1, res: result }
    }
}

export function prod_pickUp_pay(db, products, deliveryInfo, kennitala, totalPrice, callback) {
    var prodArr = []
    products.map((prod)=>{
        var cat = ''
        var gID = ''
        var subcat = ''
        var totalQTY = ''
        var img = '';
        if(prod.pack !== undefined){
            cat = `Package - ${prod.pack.length} items`
            gID = 'Package'
            subcat = 'Package'
            totalQTY = 1
        } else {
            cat = Object.keys(prod.category).toString();
            gID = prod._id;
            subcat = prod.subcategory;
            totalQTY = prod.qtyToBuy
        }

        var up1 = ''
        if(prod.unitprice1 !== undefined){
            up1 = prod.unitprice1
        }

        if(prod.images.length > 0 && prod.detailsToBuy.barcode !== 'gjafakort') {
            img = prod.images[0]
        } else {
            img = ''
        }
        var obj = {
            barcode: Math.ceil(parseInt(prod.detailsToBuy.price) - (parseInt(prod.detailsToBuy.price) * prod.detailsToBuy.discount / 100)),
            brand: prod.brand,
            category: cat,
            description: prod.description,
            groupid: gID,
            img: img,
            itemcode: prod.detailsToBuy.barcode,
            qty: totalQTY,
            size: prod.detailsToBuy.size,
            subcategory: subcat,
            unitprice1: up1,
        }
        prodArr.push(obj)
    })

    db.ref('lastPickUpOrderId').once('value').then((snap)=>{
        var newKey = db.ref('customers').push().key
        var orderId = ''
        var year = snap.val().year
        var idStr = snap.val().id
        var nextId = parseFloat(idStr)+1
        if(nextId.toString().length === 1){
            nextId = '0000000'+nextId.toString()
        } else if(nextId.toString().length === 2) {
            nextId = '000000'+nextId.toString()
        } else if(nextId.toString().length === 3) {
            nextId = '00000'+nextId.toString()
        } else if(nextId.toString().length === 4) {
            nextId = '0000'+nextId.toString()
        }
        var today = new Date();
        var currentYear = today.getFullYear()
        if(year === currentYear){
            orderId = 'P' + year + nextId
        } else {
            nextId = '00000001'
            orderId = 'P' + currentYear + nextId
        }
        var customer = {
            address: `${deliveryInfo.streetName} ${deliveryInfo.streetNumber}, ${deliveryInfo.city} ${deliveryInfo.zip}, ${deliveryInfo.district}`,
            dateOrder: moment().format('MMMM Do, YYYY'),
            email: deliveryInfo.email,
            id: newKey,
            orderId: orderId,
            kennitala: kennitala,
            name: `${deliveryInfo.firstName} ${deliveryInfo.lastName}`,
            payThrough: 'Þégar sæki',
            phone: deliveryInfo.phone,
            products: prodArr,
            totalPrice: totalPrice,
            processedAt: new Date(),
            shipping: 0,
        }
        db.ref('customers/'+newKey).update(customer)
            .then(()=>{
                db.ref('lastPickUpOrderId').set({
                    id: nextId,
                    year: currentYear
                }).then(()=>{
                    callback('success', customer)
                }).catch((error)=>{
                    callback('error', error.message)
                })
            }) 
            .catch((error)=>{
                callback('error','Something Went Wrong: ', error)
            })
    }).catch((e)=>{
        console.log(e)
        callback('error','COULD NOT GET NEW ORDERID')
    })
}

export function bike_card_pay(db, apiData, bikeData, helmet, kneeProtectors, bodyArmor, currency, kennitala, year, id, callback) {
    var newKey = db.ref('bikebooking').push().key
    var customer = {
        actionCode: apiData.ActionCode,
        authCode: apiData.AuthCode,
        bike: bikeData,
        helmet: helmet,
        protectors: kneeProtectors,
        bodyArmor: bodyArmor,
        kennitala: kennitala,
        payThrough: 'Kort',
        currency: currency,
        orderId: apiData.OrderId,
        price: apiData.Amount / 100,
        date: apiData.TransactionDate,
        paymentMethod: apiData.PaymentMethod,
        transactionDate: apiData.TransactionDate,
        transactionId: apiData.TransactionId,
    }
    customer['id'] = newKey
    db.ref('bikebooking/'+newKey).update(customer)
    .then(()=>{
        db.ref('lastBookingCardOrderId').set({
            id: id,
            year: year
        }).then(()=>{
            callback('success', customer)
        }).catch((error)=>{
            callback('firebase_error', error.message)
        })
    }) 
    .catch((error)=>{
        callback('error', error.message)
    })
}

export function isk_to_eur(total, rate) {
    return Math.ceil(total / rate)
}