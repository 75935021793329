import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography, Divider } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: '30%',
        alignItems: 'center'
    },
    text: {
        color: 'black',
    },
}));

const TermsAndConditions = (props) =>{
    const classes = useStyles();

    useEffect(()=>{window.scrollTo(0, 0)})

    return <div className={classes.root} style={{ marginTop: props._marginTop }}>
        <Grid container justify='center'>
            <Grid item sm={10} xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            AFHENDING/AFGREIÐSLUTÍMI
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            1-3 VIRKIR DAGAR
                        </Typography>
                        <Divider />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            VÖRUVERÐ
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            ÖLL VÖRUVERÐ ER MEÐ VSK.
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            VÖRUSKIL OG ENDURGREIÐSLUR
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            VÖRUM ER EINUNGIS HÆGT AÐ SKILA GEGN VÖRUREIKNING INNAN 14 DAGA FRÁ KAUPDEGI Í FORMI INNEIGNAR.
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            ÁBYRGÐARSKILMÁLAR
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            FULL FRAMLEIÐSLUÁBYRGÐ ER VEGNA GALLA OG VINNU Á VÖRUM. EF VARA TELST GÖLLUÐ MUNUM VIÐ GERA VIÐ HANA, SKIPTA HENNI EÐA ENDURGREIÐA EF SAMBÆRILEG VARA ER EKKI TIL, ÁN KOSTNAÐAR FYRIR VIÐSKIPTAVINI. ATHUGIÐ AÐ ÁBYRGÐ NÆR EKKI TIL TJÓNS SEM HLÝST AF SLYSI, ÞVOTTI, VANRÆKSLU, VENJULEGU SLITI EÐA LITATAPI SEM VERÐUR VIÐ NOTKUN OG EÐA SÖKUM ALDURS VÖRUNNAR.
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            GREIÐSLUMÁTI
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            1. KREDIT KORT<br/>
                            2. ÞEGAR ÉG SÆKI
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            FLUTNINGSAÐILI
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            PÓSTURINN
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            SENDINGARKOSTNAÐUR
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            1500 isk.
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            LÖG UM VARNARÞING
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            ÁKVÆÐI OG SKILMÁLA ÞESSA BER AÐ TÚLKA Í SAMRÆMI VIÐ ÍSLENSK LÖG. KOMI UPP ÁGREININGUR EÐA TELJI EINHVER AÐ HANN EIGI KRÖFU Á HENDUR NETVERSLUN PÚKINN EHF Á GRUNDVELLI ÞESSARA ÁKVÆÐA OG SKILMÁLA, VERÐUR SLÍKUM ÁGREININGI EÐA KRÖFU VÍSAÐ TIL MEÐFERÐAR HJÁ ÍSLENSKUM DÓMSTÓLUM.
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h6" gutterBottom>
                            TRÚNAÐUR
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            SELJANDI HEITIR KAUPANDA FULLUM TRÚNAÐI UM ALLAR ÞÆR UPPLÝSINGAR SEM KAUPANDI GEFUR UPP Í TENGSLUM VIÐ VIÐSKIPTIN. UPPLÝSINGAR FRÁ KAUPANDA VERÐA EKKI AFHENTAR ÞRIÐJA AÐILA UNDIR NEINUM KRINGUMSTÆÐUM.NOTKUN Á PERSÓNUUPPLÝSINGUM.
                        </Typography>
                        <Typography className={classes.text} variant="subtitle1" gutterBottom>
                            SENDINGAR ÚR KERFI VERSLUNAR KUNNA AÐ NOTA PERSÓNUUPPLÝSINGAR, S.S. BÚSETU, ALDUR EÐA VIÐSKIPTASÖGU, TIL AÐ ÚTBÚA VIÐEIGANDI SKILABOÐ TIL MEÐLIMA SÍÐUNNAR. ÞESSAR UPPLÝSINGAR ERU ALDREI AFHENTAR ÞRIÐJA AÐILA. MEÐLIMIR VEFVERSLUNNARINNAR GETA ÆTÍÐ AFSKRÁÐ SIG OG ÞANNIG NEITAÐ FYRIRTÆKINU NOTKUN Á SLÍKUM UPPLÝSINGUM.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
}

export default TermsAndConditions;