import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { bikeBookingApiCall } from '../actions';

const slice = createSlice({
    name: 'bikeBooking',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        bikeBookingRequested: (state, action) => {
            state.loading = true
        },

        bikeBookingRequestFailed: (state, action) => {
            state.loading = false
        },

        bikeBookingReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, bikeBookingRequested, bikeBookingRequestFailed, bikeBookingReceived } = slice.actions
export default slice.reducer;

export const getFilteredBikeBooking = createSelector(
    state => state.bikeBooking,
    (bikeBooking) => bikeBooking.list,
)

export const loadBikeBooking = (client, db) => (dispatch, getState) => {
    dispatch(
        bikeBookingApiCall({
            client: client,
            db: db,
            onStart: bikeBookingRequested.type,
            onSuccess: bikeBookingReceived.type,
            onError: bikeBookingRequestFailed.type
        })
    )
}