import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl'; 

// Material UI
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { InputBase, Menu, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Store
import { loadSearch, getFilteredSearch } from '../../../store/search/search';

// Components
import SearchResult from './SearchResult/searchResult';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    menu: {
        marginTop: '2%',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const SearchBar = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [client, setClient] = useState(null)
    const [db, setDB] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [skip, setSkip] = useState(0)
    // const [prodList, setProdList] = useState([])
    const [anchorEl, setAnchor] = useState(null)
    const [menuOpen, setMenu] = useState(false)
    const [searched, setSearched] = useState(false)

    var searchResult = useSelector(getFilteredSearch);

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            setClient(client)
            setDB(db)
        }
    },[props._mongoClient])

    // useEffect(()=>{
    //     // if(searchResult.length > 0){
    //         if(prodList.length > 0){
    //             setProdList(searchResult)
    //             // setProdList([].concat.apply(prodList, searchResult))
    //             // setMenu(true)
    //         } else {
    //             setProdList(searchResult)
    //             setMenu(true)
    //         }
    //     // }
    // },[searchResult])

    const debounced = useDebouncedCallback(
        (value, anchor) => {
            if(value.length > 0){
                setSkip(0)
                dispatch(loadSearch(client, db, value.toLowerCase(), skip))
                setSearched(true)
            }
        },
        800
    );

    function onTextChange(val){
        setSearchQuery(val.target.value)
        // setProdList([])
        setAnchor(val.currentTarget)
        debounced(val.target.value)
    }

    function handleLoadNext(){
        var newSkip = skip + 5
        setSkip(newSkip)
        dispatch(loadSearch(client, db, searchQuery, newSkip))
    }

    function handleLoadPrev(){
        var newSkip = skip - 5
        if(newSkip > 0){
            setSkip(newSkip)
        } else {
            newSkip = 0
            setSkip(newSkip)
        }
        dispatch(loadSearch(client, db, searchQuery, newSkip))
    }

    function handleMenuOpen(){
        setMenu(true)
    }

    function handleMenuClose(){
        setMenu(false)
    }

    function showProdList(val){
        if (val.key === 'Enter') {
            setMenu(true)
        }
    }

    return <div className={classes.search}>
        <div className={classes.searchIcon}>
            <SearchIcon />
        </div>
        <InputBase
            placeholder={intl.formatMessage({ id: 'search.search' })}
                classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchQuery}
            onChange={e => onTextChange(e)}
            onKeyUp={e => showProdList(e)}
        />
        <SearchResult 
            _handleLoadNext={handleLoadNext} 
            _handleMenuClose={handleMenuClose}
            _handleMenuOpen={handleMenuOpen}
            _loadNext={handleLoadNext}
            _loadPrev={handleLoadPrev}
            _menuOpen={menuOpen}
            _searched={searched}
            _prodList={searchResult} 
            _anchorEl={anchorEl}
            _skip={skip}>
        </SearchResult> 
    </div>
}

export default SearchBar;