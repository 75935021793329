import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Grid, Typography,
  Box } from '@material-ui/core';

// FormatJS (Internationalization)
import { Context } from '../../../languages/Wrapper';

// Components
import SubCategories from './SubCategories/subCategories';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-prevent-tab-${index}`,
      'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
      root: {
          flexGrow: 1,
          width: '100%',
          backgroundColor: '#212121',
      },
      category: {
          cursor: 'pointer'
      },
      white: {
          color: 'white',
      }
  }));

const CategoriesNav = (props) => {
    const classes = useStyles();
    const context = useContext(Context);
    const [value, setValue] = useState(null);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [subcategories, setSubCategories] = useState([])
    const [openSubcategories, setOpenSubcategories] = useState(false)
    const [customVariant, setCustomVariant] = useState('standard')
    const [indexTab, setIndex] = useState(false)

    useEffect(()=>{
        setCategories(props._categories)
        if(props._isMobile){
            setCustomVariant('scrollable')
        }
    },[props])

    function showSubcategories(category, subcategories, open, index) {
      if(indexTab === index) {
        setCategory(category)
        setSubCategories(subcategories)
        setOpenSubcategories(false)
        setIndex(false)
      } else {
        setCategory(category)
        setSubCategories(subcategories)
        setOpenSubcategories(true)
        setIndex(index)
      }
    }

    function closeSubcategories() {
      setOpenSubcategories(false)
    }

    return (
        <div className={classes.root} onMouseLeave={()=>showSubcategories('', [], false, false)}>
            <Tabs
                value={indexTab}
                indicatorColor='secondary'
                textColor='secondary'
                variant={customVariant}
                scrollButtons="auto"
                centered={!props._isMobile}
            >
              {categories.length > 0 ?
                categories.map((category,i)=>
                  props._isMobile ?
                  <Tab 
                    key={i} 
                    className={classes.white} 
                    label={ context.locale1.includes('en') ? category.name.toUpperCase() : category.nameis.toUpperCase() } 
                    onClick={()=>showSubcategories(category.name, category.subcategories, true, i)}
                  /> :
                  <Tab 
                    key={i} 
                    className={classes.white} 
                    label={ context.locale1.includes('en') ? category.name.toUpperCase() : category.nameis.toUpperCase() } 
                    onMouseEnter={()=>showSubcategories(category.name, category.subcategories, true, i)}
                  />
                        // <Grid item 
                        //     className={classes.category}
                        //     onMouseEnter={()=>showSubcategories(category.subcategories, true)}
                        // >{category.name.toUpperCase()}</Grid>
                ) :
                <Typography>LOADING...</Typography>
              }
            </Tabs>
            {/* <Grid className={classes.container} container justify='space-around'>
                {categories.map((category,i)=>{
                    return <div key={i}>
                        <Grid item 
                            className={classes.category}
                            onMouseEnter={()=>showSubcategories(category.subcategories, true)}
                        >{category.name.toUpperCase()}</Grid>
                    </div>
                })}
            </Grid> */}
                {openSubcategories 
                ? <SubCategories 
                  _category={category} 
                  _subCategories={subcategories}
                  _closeSubcategories={closeSubcategories}></SubCategories> 
                : <div></div>}
        </div>
    );
}

export default CategoriesNav;