import React, { useEffect, useState } from 'react';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Material UI 
import { makeStyles, Grid, Typography } from '@material-ui/core/';

import desktopPhoto from '../../../../../assets/desktop_about_cover.jpeg';
import mobilePhoto from '../../../../../assets/mobile_about_cover.jpeg';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh'
    },
    text: {
        color: 'black',
    },
    intro: {
        height: '100vh',
        backgroundSize: 'cover'
    },
    greyBox: {
        backgroundColor: '#212121'
    }
}));

const Intro = (props) => {
    const classes = useStyles();
    const [introphoto, setIntrophoto] = useState('');

    useEffect(()=>{
        if(props._isMobile) {
            setIntrophoto(mobilePhoto)
        } else {
            setIntrophoto(desktopPhoto)
        }
    }, [props])

    return <div className={classes.intro} style={{ backgroundImage: `url(${introphoto})` }}>
            <Grid container
                    spacing={0}
                    direction='row'
                    alignItems='center'
                    justify='center'
                    className={classes.root}>
                <Grid item xs={10}>  
                    <Grid container alignContent='center' direction='row' spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom variant='h6' className={classes.greyBox}>
                                <FormattedMessage 
                                    id='aboutusintro.line1' 
                                    defaultMessage='PUKINN AND MOHAWKS COOPERATION BRINGS A MIX OF QUALITY PRODUCTS FROM BOTH COMPANIES.'
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom variant='h6' className={classes.greyBox}>
                                <FormattedMessage 
                                    id='aboutusintro.line2' 
                                    defaultMessage='WE ARE PROUD OF OUR SELECTION OF GOODS AND WE AIM FOR A QUALITY CUSTOMER SERVICE.'
                                />
                                <FormattedMessage 
                                    id='aboutusintro.line3' 
                                    defaultMessage='OUR ONLINE STORE IS ALWAYS OPEN.'
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </div>
}

export default Intro;