import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Mongo Stitch
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Axios
import axios from 'axios';

// React Router
import { useHistory } from 'react-router-dom';

// Material UI
import { makeStyles, Grid, Card, Typography, Divider,
    Button, TextField, Modal, Fade, Backdrop,
    CardContent, CardHeader, FormControlLabel, Radio } from '@material-ui/core';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Store
import { getFilteredDelivery } from '../../../../../store/delivery/delivery';
import { deactivateGiftCard } from '../../../../../store/giftCard/giftCard';

// Cards
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { cc_expires_format, cc_format, get_tokenSingleV2, prod_pickUp_pay, prod_card_pay, isk_to_eur, 
    get_purchPrice, get_Discount, get_giftcard, send_mpi_enrollmentV2, gifcardAppliction,
    invalidate_giftcard } from '../../BikeBooking/BookingCheckout/Payment/cardUtils';

// Firebase 
import firebase from 'firebase';

// Components
import PriceWithDiscount from '../../Product/Prices/priceWithDiscount';
import PriceWithoutDiscount from '../../Product/Prices/priceWithoutDiscount';
import ErrorModal from '../../Product/ErrorModal/errorModal';
import LoadingSnackbar from '../../../../Loading/loadingSnackbar';
import ACSForm from '../../BikeBooking/BookingCheckout/Payment/acsForm';
import Success from '../../../../Modals/Success/success';

import borgunImage from '../../../../../assets/borgun-logo-min.jpg';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    helpingCard: {
        padding: '2%'
    },
    greenText: {
        color: 'green'
    },
    redText: {
        color: 'red'
    },
    fullWidth: {
        width: '100%'
    },
    cotnainerPadding: {
        padding: '3%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Payment = (props) => {
    const classes = useStyles();
    const fDB = firebase.database();
    const history = useHistory();
    var deliveryInfo = useSelector(getFilteredDelivery)
    const dispatch = useDispatch();
    const intl = useIntl();

    const [db, setDB] = useState(null)
    const [db2, setDB2] = useState(null)
    const [client, setClient] = useState(null)

    const [selectedValue, setSelectedValue] = useState('a');
    const [cvc, setCvc] = useState('')
    const [expiry, setExpiry] = useState('')
    const [focus, setFocus] = useState('')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [kennitala, setKennitala] = useState('')
    const [giftCard, setGiftCard] = useState({})
    const [showGiftCard, setShowGiftCard] = useState(false)
    const [iskCredit, setISKCredit] = useState(0)
    const [finalGiftCard, setFinalGifcard] = useState(null)

    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBool, setErrorMsgBool] = useState(false)

    const [euroValue, setEuroValue] = useState(0)
    const [totalInEuro, setTotalInEuro] = useState(0)
    const [totalInIsk, setTotalInIsk] = useState(0)
    const [showEuroOption, setShowEuroOption] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState('352')
    const [shipping, setShipping] = useState(1500)

    const [iskGiftForDB, setIskCreditForDB] = useState(0)
    const [eurGiftForDB, setEuroCreditForDB] = useState(0) 

    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [showACSFORM, setShowACSFORM] = useState(false)
    const [acsForm, setACSForm] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')

    const [issuer, setIssuer] = useState('unknown')

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            const db2 = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            setDB(db)
            setDB2(db2)
            setClient(client)
        }
    }, [props._mongoClient])

    useEffect(()=>{}, [deliveryInfo])

    useEffect(()=> {
        props._productsInCart.forEach((prod)=>{
            if(Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'fullsuspension' ||
            Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'hardtail' ||
            Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'e-bikes' ||
            Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'citydirt' ||
            Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'bmx' ||
            Object.keys(prod.category)[0].toLowerCase() === 'bicycle' && prod.subcategory.toLowerCase() === 'balancebikes') {
                setShipping(10000)
            }
        })
    },[props._productsInCart])
    
    useEffect(()=>{
        var giftcard = window.localStorage.getItem('pukinn_giftcard');
        if(giftcard !== null){
            var parsedGC = JSON.parse(giftcard)
            if(parsedGC.credit > props._totalPrice){
                setErrorMsg('GIFT CARD CREDIT IS HIGHER THAN TOTAL PRICE. GIFT CARD CANNOT BE APPLIED IN THIS INSTANCE')
                setErrorMsgBool(true)
            } else {
                setShowGiftCard(true)
                setISKCredit(parsedGC.credit)
                setGiftCard(parsedGC);
            }
        } else {
            setShowGiftCard(false)
        }
    },[])

    useEffect(()=>{
        setTotalInIsk(props._totalPrice);
        axios.get('https://apis.is/currency/m5')
            .then((res)=>{
                res.data.results.forEach(val=>{
                    if(val.shortName === 'EUR'){
                        setEuroValue(val.value)
                        var result = isk_to_eur(parseFloat(props._totalPrice + shipping), val.value)
                        setTotalInEuro(result)
                        setShowEuroOption(true)
                    }
                })
            }).catch((err)=>{
                setShowEuroOption(false)
                console.log(err)
            })
    },[])
    
    function handleInputFocus(e){
        setFocus(e.target.name );
    }
      
    function handleInputChange(e){
        const { name, value } = e.target;
        if(name === 'number'){
            var formatedNumber = cc_format(value)
            setNumber(formatedNumber)
        } else if(name === 'name') {
            setName(value)
        } else if(name === 'expiry') {
            var formatedExpiry = cc_expires_format(value)
            setExpiry(formatedExpiry)
        } else if(name === 'cvc') {
            setCvc(value)
        }
    }

    function payOnPickup(){
        prod_pickUp_pay(fDB, props._productsInCart, deliveryInfo, kennitala, props._totalPrice, (message, content)=>{
            if(message === 'error') {
                setErrorMsg(content)
                setErrorMsgBool(true)
            } else {
                window.localStorage.setItem('pukinn_customer_prod', JSON.stringify(content))
                history.push('/receiptprod')
            }
        })
    }

    function handleChangeCurrency(currency) {
        setSelectedCurrency(currency)
    }

    function handleSetSelectedValue(value) {
        setSelectedValue(value)
        console.log(value)
        if(value === 'a'){
            setShipping(1500)
        } else {
            setShipping(0)
        }
    }

    async function payNow(){
        if(cvc.length > 0 && expiry.length > 0 && number.length > 0){
            setLoading(true)
            var cardDetails = {   
                "PAN": number.replace(/\s/g,''),
                "ExpMonth": expiry.split('/')[0],
                "ExpYear": expiry.split('/')[1],
                "TokenLifetime": 5000,
            }
            var tokenSingleV2_response = await get_tokenSingleV2(cardDetails);
            if(tokenSingleV2_response.status === 1) {
                setToken(tokenSingleV2_response.res)
                var purchPrice_response = await get_purchPrice(props._productsInCart, client, db2);
                var discount_response = 0
                if(purchPrice_response.totalPackage < 1) {
                    discount_response = await get_Discount(db, purchPrice_response.totalPackage)
                }
                var totalPurchPrice = purchPrice_response.total + discount_response;

                if(selectedCurrency === '352'){
                    totalPurchPrice = totalPurchPrice + shipping
                } else {
                    totalPurchPrice = isk_to_eur((totalPurchPrice + shipping), euroValue)
                }

                if(showGiftCard) {
                    var giftCardCredit = await get_giftcard(db, giftCard._id);
                    if(giftCardCredit.type === 0) {
                        setLoading(false)
                        setErrorMsg(giftCardCredit.res)
                        setErrorMsgBool(true)
                    } else {
                        var application = gifcardAppliction(giftCardCredit, totalPurchPrice, selectedCurrency, euroValue)
                        if(application.type === 1) {
                            setFinalGifcard(giftCardCredit.res);
                            totalPurchPrice -= application.credit
                            if(selectedCurrency === '352') {
                                setIskCreditForDB(application.credit)
                            } else {
                                setEuroCreditForDB(application.credit)
                            }
                            send_mpi_enrollmentV2(tokenSingleV2_response, selectedCurrency, totalPurchPrice, (res, val)=>{
                                if(res === 'error') {
                                    setLoading(false)
                                    setErrorMsg('(3) SOMETHING WENT WRONG: ', val)
                                    setErrorMsgBool(true)
                                } else {
                                    setACSForm(val)
                                    setLoading(false)
                                    setShowACSFORM(true)
                                }
                            })
                        } else {
                            setLoading(false)
                            setErrorMsg(application.res)
                            setErrorMsgBool(true)
                        }
                    }
                } else {
                    send_mpi_enrollmentV2(tokenSingleV2_response, selectedCurrency, totalPurchPrice, (res, val)=>{
                        if(res === 'error') {
                            setLoading(false)
                            setErrorMsg('(4) SOMETHING WENT WRONG: ', val)
                            setErrorMsgBool(true)
                        } else {
                            setACSForm(val)
                            setLoading(false)
                            setShowACSFORM(true)
                        }
                    })
                }
            } else {
                setLoading(false)
                setErrorMsg(tokenSingleV2_response.res)
                setErrorMsgBool(true)
            }

        } else {
            setErrorMsg('MAKE SURE ALL FIELDS ARE FILLED')
            setErrorMsgBool(true)
        }
    }

    function borgunPayment(data) {
        setShowACSFORM(false)
        setLoading(true)
        fDB.ref('lastCardOrderId').once('value').then((snap)=>{
            var orderId = ''
            var year = snap.val().year
            var idStr = snap.val().id
            var nextId = parseFloat(idStr)+1
            if(nextId.toString().length === 1){
                nextId = '0000000'+nextId.toString()
            } else if(nextId.toString().length === 2) {
                nextId = '000000'+nextId.toString()
            } else if(nextId.toString().length === 3) {
                nextId = '00000'+nextId.toString()
            } else if(nextId.toString().length === 4) {
                nextId = '0000'+nextId.toString()
            }
            var today = new Date();
            var currentYear = today.getFullYear()
            if(year === currentYear){
                orderId = year + nextId
            } else {
                nextId = '00000001'
                orderId = currentYear + nextId
            }

            var totalAmount = 0;
            var currency = selectedCurrency;
            var currentDate = new Date()
            var transactionDate = currentDate.toISOString();
            if(selectedCurrency === '352') {
                totalAmount = totalInIsk + shipping
            } else {
                totalAmount = totalInEuro
            }
            if(showGiftCard) {
                if(selectedCurrency === '352') {
                    totalAmount -= iskGiftForDB
                } else {
                    totalAmount -= eurGiftForDB
                }
            }
            var options = {
                "TransactionType": "Sale",
                // "Amount": totalAmount,
                "Amount": totalAmount * 100,
                "Currency": currency,
                "TransactionDate": transactionDate,
                "PaymentMethod": {
                    "PaymentType": "TokenSingle",
                    "Token": token
                },
                "OrderId": orderId,
                "ThreeDSecure": {
                    "DataType": "Manual",
                    "SecurityLevelInd": "2",
                    "CAVV": data.CAVV,
                    "Xid": data.XId,
                },
            }
            axios({
                method: 'post',
                url: 'https://us-central1-pukinn2.cloudfunctions.net/paymentBikeBookingV2',
                data: options
            }).then((response) => {
                console.log('RESPONSE DATA: ', response)
                var d = response.data
                if(d.TransactionStatus.toLowerCase() === 'accepted'){
                    prod_card_pay(fDB, d, props._productsInCart, deliveryInfo, currentYear, nextId, selectedCurrency, finalGiftCard, iskGiftForDB, eurGiftForDB, (res, resData) => {
                        if(res === 'success'){
                            if(finalGiftCard !== null) {
                                var result = invalidate_giftcard(db, finalGiftCard);
                                dispatch(deactivateGiftCard())
                            }
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL! THE PRODUCTS ARE ON THEIR WAY')
                            setShowSuccess(true)
                            window.localStorage.setItem('pukinn_customer_prod', JSON.stringify(resData));
                            history.push('/receiptprod')
                        } else if('firebase_error') {
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL! PROBLEM WITH UPDATING ORDER ID')
                            setShowSuccess(true)
                        } else {
                            setLoading(false)
                            setSuccessMsg('TRANSACTION SUCCESSFUL, BUT NOT ABLE TO SAVE TO DATABASE. PLEASE CONTACT US ABOUT YOUR ORDER ON +354 566 6820 OR SEND US AN EMAIL AT pukinn@pukinn.com')
                            setShowSuccess(true)
                        }
                    })
                } else {
                    setLoading(false)
                    setErrorMsg('PAYMENT NOT ACCEPTED! PLEASE TRY AGAIN')
                    setErrorMsgBool(true)
                }
            }).catch((err) => {
                setLoading(false)
                setErrorMsg('(1) SOMETHING WENT WRONG: ', err)
                console.log(err)
                setErrorMsgBool(true)
            })
        }).catch((e)=>{
            console.log(e)
            setLoading(false)
            setErrorMsg('(2) SOMETHING WENT WRONG: ', e)
            setErrorMsgBool(true)
        })
    }

    function receiptPage() {
        setShowSuccess(false)
        history.push('/receiptprod')
    }

    return <Grid container direction='row' justify='space-around' alignItems='flex-start'>
        <Modal
            className={classes.modal}
            open={errorMsgBool}
            onClose={()=>setErrorMsgBool(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        <Modal

            open={showACSFORM}
            disableEscapeKeyDown={true}
            onClose={()=>setShowACSFORM(false)}
        >
            <Fade>
                <ACSForm _acsForm={acsForm} _close={()=>setShowACSFORM(false)} _borgunPayment={borgunPayment}></ACSForm>
            </Fade>
        </Modal>
        <Modal
            className={classes.modal}
            open={showSuccess}
            onClose={receiptPage}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade>
                <Success _message={successMsg} _closeSuccessModal={receiptPage}></Success>
            </Fade>
        </Modal>
        <Grid item lg={6} xs={12}>
            <Grid container direction='column' justify='center'>
                <Grid item lg={12}>
                    <Card>
                        <CardHeader title={`${intl.formatMessage({ id: 'payment.summary', defaultMessage: 'ORDER SUMMARY' })} ${props._productsInCart.length}X`}></CardHeader>
                        <CardContent >
                            {props._productsInCart.map((val,i)=>{
                                return <div key={i}>
                                    <Typography align='left' key={i}>{`${intl.formatMessage({ id: 'delivery.firstname', defaultMessage: 'NAME' })}: ${val.description.toUpperCase()}`}</Typography>
                                    <Typography align='left' key={i}>{`${intl.formatMessage({ id: 'product.size', defaultMessage: 'SIZE' })}: ${val.detailsToBuy.size}`}</Typography>
                                    <Typography align='left' key={i}>{`${intl.formatMessage({ id: 'fitmybike.quantity', defaultMessage: 'QUANTITY' })}: ${val.qtyToBuy}x`}</Typography>
                                    {parseInt(val.detailsToBuy.discount) > 0 ?
                                        <Grid container direction='row'>
                                            <Grid item><Typography><FormattedMessage 
                                                id='payment.price' 
                                                defaultMessage='PRICE: '
                                            /></Typography></Grid>
                                            <Grid item><PriceWithDiscount _prodDetails={val.detailsToBuy}></PriceWithDiscount></Grid>
                                        </Grid> :
                                        <Grid container direction='row'>
                                            <Grid item><Typography><FormattedMessage 
                                                id='payment.price' 
                                                defaultMessage='SHIPMENT: '
                                            /></Typography></Grid>
                                            <Grid item><PriceWithoutDiscount _prodDetails={val.detailsToBuy}></PriceWithoutDiscount></Grid>
                                        </Grid>
                                    }
                                    <Divider/>
                                </div>
                            })}
                            { selectedValue === 'a' ?
                                <Typography align='left'><FormattedMessage 
                                    id='payment.shipment' 
                                    defaultMessage='SHIPMENT'
                                />: <span className={classes.greenText}>{shipping} isk</span></Typography> :
                                <div></div>
                            }
                            <Grid container direction='row'>
                                <Grid item><Typography><FormattedMessage 
                                    id='cart.total' 
                                    defaultMessage='TOTAL '
                                />:</Typography></Grid>
                                <Grid item>
                                    <PriceWithoutDiscount _prodDetails={{price: totalInIsk + shipping}}></PriceWithoutDiscount>
                                    {showGiftCard > 0 && selectedValue === 'a' ?
                                        <Typography className={classes.redText} > - {giftCard.credit} isk {intl.formatMessage({ id: 'payment.giftcard.giftcard', defaultMessage: '(Gift Card)' })}</Typography> :
                                        <div></div>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                { selectedValue === 'a' ?
                    <Grid item lg={12}>
                        <Card>
                            <CardHeader title={`${intl.formatMessage({ id: 'payment.shipment', defaultMessage: 'SHIPMENT' })} - ${shipping} isk`}></CardHeader>
                            <CardContent>
                                <Typography align='left'><FormattedMessage 
                                    id='payment.fullname' 
                                    defaultMessage='NAME'
                                />: {deliveryInfo.firstName} {deliveryInfo.lastName}</Typography>
                                <Typography align='left'><FormattedMessage 
                                    id='payment.shippingto' 
                                    defaultMessage='SHIPPING TO'
                                />: {deliveryInfo.streetName} {deliveryInfo.streetNumber}, {deliveryInfo.city} {deliveryInfo.zip}, {deliveryInfo.country}</Typography>
                                <Typography align='left'><FormattedMessage 
                                    id='delivery.email' 
                                    defaultMessage='EMAIL'
                                />: {deliveryInfo.email}</Typography>
                                <Typography align='left'><FormattedMessage 
                                    id='delivery.phone' 
                                    defaultMessage='PHONE'
                                />: {deliveryInfo.phone}</Typography>
                            </CardContent>
                        </Card>
                    </Grid> : <div></div>
                }
            </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Card style={{padding: '1%'}}>
                <Grid container justify='space-evenly' direction='row' alignItems='flex-start'>
                    <Grid item>
                        <Grid container justify='center' direction='column' alignItems='center'>
                            <Grid item>
                                <FormControlLabel 
                                    value='end'
                                    label={intl.formatMessage({ id: 'payment.cardpay', defaultMessage: 'PAY WITH CARD' })}
                                    control={ <Radio
                                        checked={selectedValue === 'a'}
                                        onChange={(e)=>handleSetSelectedValue(e.target.value)}
                                        value='a'
                                        inputProps={{ 'aria-label': 'CARD' }}
                                    />}
                                />   
                            </Grid>
                            <Grid item>
                                <img style={{width: '100px'}} src={borgunImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel 
                            value='end'
                            label={intl.formatMessage({ id: 'payment.pickup', defaultMessage: 'PAY ON PICK UP' })}
                            control={ <Radio
                                checked={selectedValue === 'b'}
                                onChange={(e)=>handleSetSelectedValue(e.target.value)}
                                value='b'
                                inputProps={{ 'aria-label': 'PICK UP' }}
                            />}
                        />
                    </Grid>
                </Grid>
                <Grid justify='space-evenly' direction='row' alignItems='center'>
                    {selectedValue === 'a' ?
                        <Grid item lg={12}>
                            <Grid container direction='row' justify='space-evenly' alignItems='center'>
                                <Grid item lg={6} xs={12}>
                                    <Grid container direction='column' justify='center'>
                                        <Grid item>
                                            <Grid container direction='row' justify='space-evenly' alignItems='center'>
                                                <Grid item>
                                                    <FormControlLabel 
                                                        value='end'
                                                        label={`ISK - ${(totalInIsk + shipping) - iskCredit} isk`}
                                                        control={ <Radio
                                                            checked={selectedCurrency === '352'}
                                                            onChange={(e)=>handleChangeCurrency(e.target.value)}
                                                            value='352'
                                                            inputProps={{ 'aria-label': 'ISK' }}
                                                        />}
                                                    /> 
                                                    {showEuroOption ?
                                                        <FormControlLabel 
                                                            value='end'
                                                            label={`EUR - €${totalInEuro - isk_to_eur(iskCredit, euroValue)}`}
                                                            control={ <Radio
                                                                checked={selectedCurrency === '978'}
                                                                onChange={(e)=>handleChangeCurrency(e.target.value)}
                                                                value='978'
                                                                inputProps={{ 'aria-label': 'EUR' }}
                                                            />}
                                                        /> : <div></div>
                                                    }  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                type='tel'
                                                name='number'
                                                placeholder={intl.formatMessage({ id: 'payment.cardnumber', defaultMessage: 'CARD NUMBER' })}
                                                value={number}
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </Grid>
                                        {/* <Grid item>
                                            <TextField
                                                fullWidth
                                                type='tel'
                                                name='name'
                                                placeholder='NAME'
                                                value={name.toUpperCase()}
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </Grid> */}
                                        <Grid item>
                                            <Grid container direction='row'>
                                                <Grid item lg={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        type='text'
                                                        name='expiry'
                                                        placeholder={intl.formatMessage({ id: 'payment.exp', defaultMessage: 'MM/YY' })}
                                                        value={expiry}
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        type='tel'
                                                        name='cvc'
                                                        placeholder='CVC'
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item><Button variant='contained' color='secondary' className={classes.fullWidth} onClick={()=>payNow()}>
                                        <FormattedMessage 
                                            id='payment.pay' 
                                            defaultMessage='PAY'
                                        /></Button></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Cards
                                        cvc={cvc}
                                        expiry={expiry}
                                        focused={focus}
                                        name={name}
                                        number={number}
                                        // callback={(type)=>setIssuer(type.issuer)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> :
                        <Grid item>
                            <Grid container direction='column' justify='center'>
                                {showGiftCard > 0 ?
                                    <Grid item lg={12}><Typography variant='caption' gutterBottom style={{color: 'grey'}}>YOU CAN USE THE GIFT CARD CREDIT ON PICK UP!</Typography></Grid> :
                                    <div></div>
                                }
                                <Grid item lg={12}>
                                    <TextField fullWidth value={kennitala} onChange={(e)=>setKennitala(e.target.value)} label='KENNITALA'></TextField>
                                </Grid>
                                <Grid item lg={12}>
                                {kennitala.length === 10 ?
                                    <Button className={classes.fullWidth} variant='contained' color='secondary' onClick={payOnPickup}>
                                        <FormattedMessage 
                                            id='payment.submit' 
                                            defaultMessage='SUBMIT'
                                        />
                                    </Button> :
                                    <Button className={classes.fullWidth} variant='contained' color='secondary' disabled>
                                        <FormattedMessage 
                                            id='payment.submit' 
                                            defaultMessage='SUBMIT'
                                        />
                                    </Button>
                                }
                            </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Card>
        </Grid>
        <LoadingSnackbar _open={loading}></LoadingSnackbar>
    </Grid>
}

export default Payment;