import React from 'react';

// Material UI
import { Snackbar, CircularProgress } from '@material-ui/core';

const LoadingSnackbar = (props) => {
    return <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={props._open}
    // onClose={handleClose}
    // onExited={handleExited}
    message={'PLEASE WAIT'}
    action={
        <CircularProgress color='secondary'/>
    }
  />
}

export default LoadingSnackbar;