import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Material UI
import { makeStyles, Grid, Button, Typography } from '@material-ui/core';

// Store
import { deactivate } from '../../../../../store/giftCard/giftCard';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
  }));

const DeactivateGiftCard = (props) => {
    const classes = useStyles();
    var dispatch = useDispatch();

    function deactivateCard() {
        dispatch(deactivate())
        props._handleCloseGiftCardModal()
    }

    return <div className={classes.paper}>
        <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
            <Grid item lg={12} xs={12}>
                <Typography>{props._message}</Typography>
            </Grid>
            <Grid item lg={12} xs={12}>
                <Button variant='contained' color='secondary' onClick={deactivateCard}>DEACTIVATE</Button>
            </Grid>
        </Grid>
    </div>
}

export default DeactivateGiftCard;