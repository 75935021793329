import React from 'react';

// Material UI
import { makeStyles, Card, Grid, Button, Typography } from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import pukinnLogo from '../../../assets/pukinnlogo.png';
import successGif from '../../../assets/icons/check-circle.gif';

const useStyles = makeStyles({
    root: {
        maxWidth: '50%',
        padding: '3%'
    },
    logo: {
        width: '100%',
    },
    title: {
        color: 'black'
    },
    icon: {
        marginRight: '1%'
    },
});

const Success = (props) => {
    const classes = useStyles();

    return <Card className={classes.root}>
        <Grid container direction='column' justify='space-between' alignItems='center'>
            <Grid item>
                <img src={pukinnLogo} className={classes.logo}/>
            </Grid> 
            <Grid item>
                <img src={successGif} />
            </Grid>
            <Grid item>
                <Typography variant='h5' className={classes.title} gutterBottom>
                    {props._message}
                </Typography>
            </Grid>
            <Grid item>
                <Button size='large' variant='contained' color='secondary' onClick={props._closeSuccessModal}>CLOSE</Button>
            </Grid>
        </Grid>
    </Card>
}

export default Success;