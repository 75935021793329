import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import swDev from './swDev';
import reportWebVitals from './reportWebVitals';

// Internationalization
import Wrapper from './languages/Wrapper';

// Firebase Initialization
import * as firebase from 'firebase';
import { firebaseConfig } from './firebaseconfig.js';
firebase.initializeApp(firebaseConfig)

ReactDOM.render(<Wrapper><App /></Wrapper>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
// swDev();
