import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { workersApiCall } from '../actions';

const slice = createSlice({
    name: 'workers',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        workersRequested: (state, action) => {
            state.loading = true
        },

        workersRequestFailed: (state, action) => {
            state.loading = false
        },

        workersReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, workersRequested, workersRequestFailed, workersReceived } = slice.actions
export default slice.reducer;

export const getFilteredWorkers = createSelector(
    state => state.workers,
    (workers) => workers.list,
)

export const loadWorkers = (client, db) => (dispatch, getState) => {
    dispatch(
        workersApiCall({
            client: client,
            db: db,
            onStart: workersRequested.type,
            onSuccess: workersReceived.type,
            onError: workersRequestFailed.type
        })
    )
}