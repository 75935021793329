import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl'; 

// Material UI
import { makeStyles, Grid, Typography, Card, CardContent,
        CardMedia, Box } from '@material-ui/core/';

// Store
import { loadMembers, getFilteredMembers } from '../../../../store/members/members';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '3%'
    },
    text: {
        color: 'black',
    },
    textRed: {
        color: 'red'
    },
    card: {
        height: '100%',
    },
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
}));

const TeamPukinn = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const members = useSelector(getFilteredMembers)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            dispatch(loadMembers(client, db));
        }
    },[props])

    return(
        <div style={{ marginTop: props._marginTop }}>
            <Grid container justify='center'>
                <Grid item xs={10}>  
                    <Box textAlign='left'>
                        <Typography gutterBottom variant='h5' component='h2' className={classes.text}>
                            <FormattedMessage 
                                id='teampukinn.teampukinn1' 
                                defaultMessage='TEAM '
                            /> <span className={classes.textRed}><FormattedMessage 
                                id='teampukinn.teampukinn2' 
                                defaultMessage=' PUKINN'
                            /></span>
                        </Typography>
                    </Box>
                    <Box textAlign='left'>
                        <Typography variant='body2' color='textSecondary' component='p' className={classes.text}>
                            <FormattedMessage 
                                id='teampukinn.line1' 
                                defaultMessage='AMAZING, BEAUTIFUL AND GOOD PEOPLE WE WORK WITH IN MX AND MTB SCENE IN ICELAND.'
                            /><br/>
                            <FormattedMessage 
                                id='teampukinn.line2' 
                                defaultMessage='THESE ARE PEOPLE FULL OF PASSION FOR THE SPORT WITH RICH EXPERIENCE IN THE FIELD.'
                            /><br/>
                            <FormattedMessage 
                                id='teampukinn.line3' 
                                defaultMessage='THEY ARE AN AMAZING ROLE MODELS THAT ENCOURAGE OTHERS TO FIND THE PASSION FOR BIKES!'
                            />
                        </Typography>
                    </Box>
                    <Box textAlign='left' className={classes.box}>
                        <Typography gutterBottom variant='h5' component='h2' className={classes.text}>
                            <span className={classes.textRed}>MX 2021</span>
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {members.map((member, i)=>{
                            if(member.type === 'mx'){
                                return <Grid item xs={12} sm={4} key={i}>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.media}
                                            image={member.image}
                                            title={member.name}
                                        />
                                        <CardContent>
                                            <Box textAlign='left'>
                                                <Typography gutterBottom variant='h5' component='h2' className={classes.text}>
                                                    {member.name.toUpperCase()}
                                                </Typography>
                                            </Box>
                                            <Box textAlign='left'>
                                                {member.description.split('°').map((value, index) => {
                                                    return (
                                                        <Typography key={index} variant='body2' color='textSecondary' component='p' className={classes.text}>
                                                            {value}
                                                            <br />
                                                        </Typography>
                                                    );
                                                })}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid> 
                            } else {
                                return <div key={i}></div>
                            }
                        })}
                    </Grid> 
                    <Box textAlign='left' className={classes.box}>
                        <Typography gutterBottom variant='h5' component='h2' className={classes.text}>
                            <span className={classes.textRed}>MTB 2021</span>
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {members.map((member, i)=>{
                            if(member.type === 'mtb'){
                                return <Grid item xs={12} sm={4} key={i}>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.media}
                                            image={member.image}
                                            title={member.name}
                                        />
                                        <CardContent>
                                            <Box textAlign='left'>
                                                <Typography gutterBottom variant='h5' component='h2' className={classes.text}>
                                                    {member.name.toUpperCase()}
                                                </Typography>
                                            </Box>
                                            <Box textAlign='left'>
                                                {member.description.split('°').map((value, index) => {
                                                    return (
                                                        <Typography key={index} variant='body2' color='textSecondary' component='p' className={classes.text}>
                                                            {value}
                                                            <br />
                                                        </Typography>
                                                    );
                                                })}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid> 
                            } else {
                                return <div key={i}></div>
                            }
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default TeamPukinn;