import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential, BSON } from 'mongodb-stitch-browser-sdk';

const giftCardApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.giftCardApiCall.type) return next(action)
    const { client, db, _id, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('giftcards').findOne({ _id: BSON.ObjectId.createFromHexString(_id) })
            .then((result)=>{
                if(result === null){
                    result = {}
                }
                if(onSuccess) dispatch({ type: onSuccess, payload: result})
                else dispatch(actions.giftCardApiCallSucess(result))
            })
            .catch((e)=>{
                if(e) dispatch({ type: onError, payload: e.message })
                else dispatch(actions.giftCardApiCallFailed(e.message))
            })
    }); 
    return next(action)
}

export default giftCardApi;