import React, { useState, useEffect } from 'react';

// Material UI
import { makeStyles, Grid, Modal, Backdrop, Fade } from '@material-ui/core/';

// Components
import MainNavBar from '../MainNavBar/mainNavBar';
import Footer from '../Footer/footer';
import Game from '../Game/src/Game';
import BaseCookie from '../Modals/BaseCookie/baseCookie'

// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Location from './Pages/Location/location';
import MainPage from './Pages/MainPage/mainPage';
import TermsAndConditions from './Pages/TermsAndConditions/termsAndConditions';
import Faq from './Pages/Faq/faq';
import About from './Pages/About/about';
import FitMyBike from './Pages/FitMyBike/fitMyBike';
import TeamPukinn from './Pages/TeamPukinn/teamPukinn';
import BikeBooking from './Pages/BikeBooking/bikeBooking';
import Brands from './Pages/Brands/brands';
import Shop from './Pages/Shop/shop';
import Product from './Pages/Product/product';
import Checkout from './Pages/Checkout/checkout';
import GiftCard from './Pages/GiftCard/giftCard';
import Receipt from './Pages/Receipt/receipt';
import ReceiptProd from './Pages/Receipt/receiptprod';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const LandingPage = (props) => {
    const classes = useStyles();
    const [marginTop, setMarginTop] = useState('3%')

    const [reloadRouting, setReload] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [cookieModal, setCookieModal] = useState(false)

    useEffect(()=>{
        if(props._isMobile)
            setMarginTop('10%')
    }, [props])

    useEffect(()=>{
        var base_cookie = window.localStorage.getItem('pukinn_base_cookie')
        if(base_cookie === null || base_cookie === undefined) {
            setCookieModal(true)
        }
    },[])

    const handleDrawerOpen = () => {
      setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
      setOpenDrawer(false);
    };

    function acceptCookie() {
        window.localStorage.setItem('pukinn_base_cookie', true)
        setCookieModal(false)
    }

    return(
        <Router>
            <div className={classes.container}>
                <MainNavBar 
                    _mongoClient={props._mongoClient}
                    _isMobile={props._isMobile}
                    _categories={props._categories}
                    _openDrawer={openDrawer}
                    _handleDrawerOpen={handleDrawerOpen}
                    _handleDrawerClose={handleDrawerClose}
                ></MainNavBar>
                {/* {navigator.onLine ? */}
                    <Switch>
                        <Route exact path='/'>
                            <MainPage _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></MainPage>
                        </Route>
                        <Route path='/location'>
                            <Location _marginTop={marginTop}></Location>
                        </Route>
                        <Route path='/termsAndConditions'>
                            <TermsAndConditions _marginTop={marginTop}></TermsAndConditions>
                        </Route>
                        <Route path='/faq'>
                            <Faq _marginTop={marginTop} _mongoClient={props._mongoClient}></Faq>
                        </Route>
                        <Route path='/about'>
                            <About _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></About>
                        </Route>
                        <Route exact path='/fitmybike'>
                            <FitMyBike _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></FitMyBike>
                        </Route>
                        <Route path='/teamPukinn'>
                            <TeamPukinn _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></TeamPukinn>
                        </Route>
                        <Route exact path='/bikebooking'>
                            <BikeBooking _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></BikeBooking>
                        </Route>
                        <Route path='/brands'>
                            <Brands _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></Brands>
                        </Route>
                        <Route path='/shop'>
                            <Shop _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></Shop>
                        </Route>
                        <Route exact path='/product'>
                            <Product _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></Product>
                        </Route>
                        <Route exact path='/checkout'>
                            <Checkout _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></Checkout>
                        </Route>
                        <Route exact path='/articles/giftcard'>
                            <GiftCard _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></GiftCard>
                        </Route>
                        <Route exact path='/receipt'>
                            <Receipt _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></Receipt>
                        </Route>
                        <Route exact path='/receiptprod'>
                            <ReceiptProd _isMobile={props._isMobile} _marginTop={marginTop} _mongoClient={props._mongoClient}></ReceiptProd>
                        </Route>
                        {/* <Route exact path='/offline'>
                            <Grid container direction='column' justify='center' alignItems='center' style={{height: '75vh'}}>
                                <Grid item>
                                    <Game></Game>
                                </Grid>
                            </Grid>
                        </Route> */}
                    </Switch> 
                    {/* : */}
                    {/* <Grid container direction='column' justify='center' alignItems='center' style={{height: '75vh'}}>
                        <Grid item>
                            <Game></Game>
                        </Grid>
                    </Grid> */}
                {/* } */}
                {/* <Modal
                    className={classes.modal}
                    open={cookieModal}
                >
                    <Fade in={cookieModal}>
                        <BaseCookie _acceptCookie={acceptCookie}></BaseCookie>
                    </Fade>
                </Modal> */}
                <BaseCookie _open={cookieModal} _acceptCookie={acceptCookie}></BaseCookie>
                <Footer
                    _mongoClient={props._mongoClient}
                    _isMobile={props._isMobile}
                    _openDrawer={openDrawer}
                    _handleDrawerOpen={handleDrawerOpen}
                ></Footer>
            </div>
        </Router>
    )
}

export default LandingPage;