import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const productsApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.productsApiCall.type) return next(action)
    const { client, db, query, category, skip, sort, newQuery, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        var catQuery = {}
        if(category !== null) {
            catQuery = { category: { $elemMatch: { v: category } } }
        }
        db.collection('products').aggregate([
            { $match: query },
            { $project: { 
                _id: 1,
                groupid: 1,
                images: 1,
                description: 1,
                barcode: 1,
                category: { $objectToArray: "$category" },
                subcategory: 1, 
                discount: 1,
                priority: 1,
                itemdescription: 1,
                qty: 1,
                brand: 1,
                package: 1
            } 
            },
            { $match: catQuery },
            { $group: {
                _id: "$groupid",
                id: { $last: "$_id" },
                images: { $last: "$images" },
                description: { $last: "$description" },
                barcode: { $min: { $toInt: "$barcode" } },
                category: { $last: "$category" },
                subcategory: { $last: "$subcategory" }, 
                discount: { $last: "$discount" },
                priority: { $last: "$priority" },
                itemdescription: { $last: "$itemdescription" },
                totalQty: { $sum: { $toInt: "$qty" } },
                brand: { $last: "$brand" },
                package: { $last: "$package" }
            } },
            { $sort: {'barcode': sort, 'description': 1} },
            { $skip: skip },
            { $limit: 12 }
        ]).toArray().then((result)=>{
            if(onSuccess) dispatch({ type: onSuccess, payload: result, newQuery: newQuery})
            else dispatch(actions.productsApiCallSucess(result))
        }).catch((e)=>{
            console.log(e)
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.productsApiCallFailed(e.message))
        })
    }); 
    return next(action)
}

export default productsApi;