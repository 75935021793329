import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Store
import { loadCategories, getFilteredCategories } from '../../store/categories/categories';

// Components
import LandingPage from '../LandingPage/landingPage';

// Material UI
import { Card, Button, Modal, Typography } from '@material-ui/core';

//Mongo Stitch
import { RemoteMongoClient, AnonymousCredential } from 'mongodb-stitch-browser-sdk';

//Firebase
import * as firebase from 'firebase'
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

// MongoDB Atlas
import { Stitch } from 'mongodb-stitch-browser-sdk';

// Axios
import axios from 'axios'

// Is Mobile
import { isMobile } from 'react-device-detect';

const Auth = () => {
    const [db, setDB] = useState(null)
    const [mongoClient, setClient] = useState(null)
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    //Sign Up
    const [logout, setLogout] = useState(false)
    const [loginMsg, setLoginMsg] = useState('')
    const [role, setRole] = useState(null);

    const dispatch = useDispatch();
    const categories = useSelector(getFilteredCategories)
    useEffect(() => {
        const client = Stitch.initializeDefaultAppClient('pukinn_lager-ybrhs');
        const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
        setClient(client);
        authListener()
        dispatch(loadCategories(client, db));
    }, []);

    function authListener() {
        // firebase.auth().onAuthStateChanged((user) => {
        //     if(user) {
        //         user.getIdToken().then((token)=>{
        //             axios.post('https://us-central1-pukinn2.cloudfunctions.net/adminLogin', 
        //                 { idToken: token})
        //                     .then((response) => {
        //                         console.log(response)
        //                     if(response.data !== false) {
        //                         setUser(user)
        //                         setRole(response.data)
        //                         setLoading(false)
        //                     } else {
        //                         setUser(null)
        //                         setLoading(false)
        //                         setLogout(true)
        //                         setLoginMsg('Waiting for Admin acceptance for: ' + user.displayName)
        //                     }
        //                 })
        //                     .catch((error) => {
        //                     setLoading(false)
        //                     setError(true)
        //                     setErrorMessage(error.message)
        //                 });
        //         })
        //     } else {
        //         setUser(null)
        //         setLoading(false)
        //     }
        // }) 
    }  


    return <LandingPage 
        _isMobile={isMobile}
        _categories={categories}
        _mongoClient={mongoClient}
    >
    </LandingPage>
}

export default Auth;