import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducer';

// APIS
import categoriesApi from './middleware/categoriesApi';
import faqApi from './middleware/faqApi';
import workersApi from './middleware/workersApi';
import membersApi from './middleware/membersApi';
import brandsApi from './middleware/brandsApi';
import brandsByProductsApi from './middleware/brandsByProductsApi';
import productsApi from './middleware/productsApi';
import productApi from './middleware/productApi';

import cart from './middleware/cart';
import delivery from './middleware/delivery';
import makesApi from './middleware/makesApi';
import modelsApi from './middleware/modelsApi';
import yearsApi from './middleware/yearsApi';
import fitsApi from './middleware/fitsApi';
import searchApi from './middleware/searchApi';
import packagePricingApi from './middleware/packagePricingApi';
import bikeBookingApi from './middleware/bikeBookingApi';
import giftCardApi from './middleware/giftCardApi';
import featuredProductsApi from './middleware/featuredProductsApi';

export default function(){
    return configureStore({
        reducer,
        middleware: [
            ...getDefaultMiddleware({
                serializableCheck: false
            }), 
            categoriesApi,
            faqApi,
            workersApi,
            membersApi,
            brandsApi,
            brandsByProductsApi,
            productsApi,
            productApi,
            cart,
            delivery,
            makesApi,
            modelsApi,
            yearsApi,
            fitsApi,
            searchApi,
            packagePricingApi,
            bikeBookingApi,
            giftCardApi,
            featuredProductsApi
        ]
    });
}