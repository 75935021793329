import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Material UI
import { Menu, MenuItem, Divider, Typography, Avatar,
        Grid, List } from '@material-ui/core';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl'; 

// Demon Knight
import demonKnight from '../../../../assets/icons/knight_demon.png';

// React Router
import { Link } from "react-router-dom";

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    menu: {
        maxHeight: 115 * 5,
        marginTop: '2%',
        overflowY:'scroll'
    },
    avatar: {
        marginRight: '1%',
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    text: {
        color: 'black'
    },
    greenPrice: {
        color: 'green',
    },
    redPrice: {
        color: 'red',
        marginRight: '1%',
        textDecoration: 'line-through'
    }
}));

const SearchResult = (props) => {
    const classes = useStyles();

    useEffect(()=>{
        props._handleMenuOpen()
        if(props._searched) {
            props._handleMenuOpen()
        } else {
            props._handleMenuClose()
        }
    },[props._prodList])

    const handleScroll = (e) => {
        console.log(e)
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            console.log("bottom")
        }
    }

    return <Menu
            onScroll={handleScroll}
            className={classes.menu}
            anchorEl={props._anchorEl}
            keepMounted
            open={props._menuOpen}
            onClose={props._handleMenuClose}
        >   
            {props._prodList.length > 0 ?
                props._prodList.map((value, i) => {
                    return <Link key={i} to={`/product?id=${value._id}`} onClick={props._handleMenuClose}>
                        <MenuItem button>
                            {value.images.length > 0 ?
                                <Avatar variant='square' className={classes.avatar} src={value.images[0]}/> :
                                <Avatar variant='square' className={classes.avatar} src={demonKnight}/>
                            }
                            <div>
                                <Typography className={classes.text} variant='body2' gutterBottom>{value.description.toUpperCase()}</Typography>
                                {value.discount > 0 ?
                                    <div>
                                        <span className={classes.redPrice}>{format(value.barcode, {
                                            currency: 'isk',
                                            showDecimals: 'NEVER',
                                            thousandSeparator: ' '
                                        })}</span>
                                        <span className={classes.greenPrice}>
                                            {format(Math.ceil(parseInt(value.barcode) - (parseInt(value.barcode) * value.discount / 100)), {
                                                currency: 'isk',
                                                showDecimals: 'NEVER',
                                                thousandSeparator: ' '
                                            })}
                                        </span>
                                    </div> :
                                    <span className={classes.greenPrice}>{format(value.barcode, {
                                        currency: 'isk',
                                        showDecimals: 'NEVER',
                                        thousandSeparator: ' '
                                    })}</span>
                                }
                            </div>
                    </MenuItem>
                </Link>
            }) :
            <MenuItem><FormattedMessage 
                id='search.noitems' 
                defaultMessage='ENGAR VÖRUR'
            /></MenuItem>
            }
            <Divider/>
            <Grid container direction='row' justify='space-evenly'>
                {props._skip > 0 ?
                    <Grid item>
                        <MenuItem onClick={props._loadPrev}><FormattedMessage 
                            id='search.back' 
                            defaultMessage='BACK'
                        /></MenuItem>
                    </Grid> :
                    <div></div>
                }
                { props._prodList.length === 5 ?
                    <Grid item>
                        <MenuItem onClick={props._loadNext}>
                        <FormattedMessage 
                            id='search.load' 
                            defaultMessage='LOAD'
                        /></MenuItem>
                    </Grid> :
                    <div></div>
                }
            </Grid>
        </Menu>
}

export default SearchResult;