import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Grid, CircularProgress, Button } from '@material-ui/core/';

//Mongo Stitch
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// React Router
import { useLocation } from "react-router-dom";

// Store
import { loadProducts, getFilteredProducts } from '../../../../store/products/products';

// Components
import MiniProduct from './MiniProduct/miniProduct';
import MiniProductSoldOut from './MiniProduct/miniProductSoldOut';
import Filters from './Filters/filters';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    prodListDefault: {
        // height: '100%'
    },
    prodListEmpty: {
        height: '75vh'
    },
    card: {
        height: '100%',
    },
    button: {
        backgroundColor: '#212121',
        '&:hover': {
            backgroundColor: '#3a3a3a',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        color: 'white',
        margin: '3%'
    },
    loadingContainer: {
        height: '80vh'
    }
}));

const Shop = (props) => {
    const classes = useStyles();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const query = new URLSearchParams(search);
    var products = useSelector(getFilteredProducts);

    const [db, setDB] = useState(null);
    const [client, setClient] = useState(null);
    const [change, setChange] = useState(true);
    const [skip, setSkip] = useState(0);
    const [prodQuery, setProdQuery] = useState({})
    const [cat, setCategory] = useState(null)
    const [loading, setLoading] = useState(true);
    const [emptyListCheck, setListCheck] = useState(false)

    //Filters
    const [gender, setGender] = useState('all')
    const [priceRange, setPriceRange] = useState(1)
    const [chosenBrands, setChosenBrand] = useState([]);

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            var brand = query.get('brand')
            var category = query.get('category')
            var subCat = query.get('subCat')
            var q = {}
            if(brand !== null){
                q['brand'] = brand;
            }
            if(category !== null){
                setCategory(category)
            }
            if(subCat !== null){
                q['subcategory'] = subCat;
                subCat = ''
            }
            q['webview'] = true
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            setDB(db)
            setClient(client)
            setProdQuery(q)
            setSkip(0)
            dispatch(loadProducts(client, db, q, category, 0, priceRange, true));
            setLoading(false);

        }
    },[search, props._mongoClient])

    useEffect(()=>{
        if(products.products.length > 0){
            setListCheck(false)
        } else {
            setListCheck(true)
        }
    },[products])

    function handleCardHeightChange(ch) {
        setChange(!ch)
    }

    function loadMore() {
        var newSkip = skip + 12
        dispatch(loadProducts(client, db, prodQuery, cat, newSkip, priceRange, false));
        setSkip(newSkip);
    }

    function handleChangeGender(e) {
        setGender(e.target.value)
        setSkip(0);
        var newProdQuery = prodQuery
        if(e.target.value !== 'all'){
            newProdQuery['gender'] = e.target.value
        } else {
            if(newProdQuery['gender'] !== undefined || newProdQuery['gender'] !== null){
                delete newProdQuery['gender']
            }
        }
        setProdQuery(newProdQuery)
        dispatch(loadProducts(client, db, newProdQuery, cat, 0, priceRange, true));
    }

    function handleChangePriceRange(e) {
        setPriceRange(e.target.value)
        setSkip(0);
        dispatch(loadProducts(client, db, prodQuery, cat, 0, e.target.value, true));
    }

    function handleChangeBrand(e) {
        setChosenBrand(e);
        var arrOfBrands = []
        var newProdQuery = prodQuery
        if(e.length > 0){
            e.forEach((brand)=>{
                var obj = {
                    brand: ''
                }
                obj.brand = brand
                arrOfBrands.push(obj)
            })
            newProdQuery['$and'] = []
            var orKey = {'$or': []}
            newProdQuery.$and.push(orKey)
            newProdQuery.$and[0].$or = arrOfBrands
        } else {
            delete newProdQuery.$and
        }
        setProdQuery(newProdQuery)
        dispatch(loadProducts(client, db, newProdQuery, cat, 0, priceRange, true));
    }

    return(
        <div className={clsx({
            [classes.prodListDefault]: !emptyListCheck,
            [classes.prodListEmpty]: emptyListCheck,
        })} style={{marginTop: props._marginTop}}>
            <Grid container justify='center'>
                {loading !== true ?
                    <Grid item xs={10}>
                        <Filters 
                            _mongoClient={props._mongoClient}
                            _category={query.get('category')}
                            _subCat={query.get('subCat')}
                            _products={products.products} 
                            _gender={gender} 
                            _priceRange={priceRange}
                            _chosenBrands={chosenBrands}
                            _handleChangeGender={handleChangeGender}
                            _handleChangePriceRange={handleChangePriceRange}
                            _handleChangeBrand={handleChangeBrand}
                        ></Filters>
                        <Grid container spacing={2}>
                            {products.products.map((product, i)=>{
                                    if(product.totalQty > 0){
                                        return <MiniProduct 
                                            key={i} 
                                            _details={product}
                                            _handleCardHeightChange={handleCardHeightChange}
                                            _cardHeight={classes.card}
                                            _changed={change}
                                        ></MiniProduct>
                                    } else {
                                        return <MiniProductSoldOut 
                                            key={i} 
                                            _details={product}
                                            _handleCardHeightChange={handleCardHeightChange}
                                            _cardHeight={classes.card}
                                            _changed={change}
                                        ></MiniProductSoldOut>
                                    }
                                })
                            }
                        </Grid>
                        <Grid container justify='center' alignItems='center'>
                            {products.emptyList ? 
                                <Button disabled className={classes.button} size='large' variant='contained'>NO MORE PRODUCTS IN THIS CATEGORY</Button> 
                                : <Button className={classes.button} onClick={loadMore} size='large' variant='contained'>LOAD MORE</Button>
                            }
                        </Grid>
                    </Grid> :
                    <Grid container className={classes.loadingContainer} justify='center' direction='row' alignItems='center'>
                        <Grid item xs={10}>
                            <CircularProgress color='secondary' />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default Shop;