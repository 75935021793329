import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ErrorModal = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.paper}>
            <h2>{props._message}</h2>
        </div>
    );
}

export default ErrorModal;