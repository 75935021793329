import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Material UI
import { makeStyles, Grid, Button } from '@material-ui/core/';

// Store
import { loadPackagePricing, getFilteredPackagePricing } from '../../../../../store/packagePricing/packagePricing';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    buyPackage: {
        background: 'orange'
    },
    oldPrice: {
        textDecoration: 'line-through',
    },
    packPrice: {
        color: 'green'
    },
    discount: {
        fontWeight: 'bold' 
    }
}));

const BuyPackage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    var packagePricing = useSelector(getFilteredPackagePricing);

    const [packPricing, setPackPricing] = useState([])
    const [packDiscount, setPackDiscount] = useState(0)
    const [packTotalPrice, setTotalPrice] = useState(0)
    const [disabledButton, setDisabledButton] = useState(true)

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            dispatch(loadPackagePricing(client, db));
        }
    },[props._mongoClient])

    useEffect(()=>{
        if(packagePricing.length > 0){
            setPackPricing(packagePricing)
        }
    },[packagePricing])

    useEffect(()=>{
        var totalPrice = parseInt(props._mainItemPrice.price) + props._totalPackPrice
        setTotalPrice(totalPrice)
        if(props._totalPackPrice > 0){
            var discount = 0
            packPricing.forEach((val)=>{
                if(totalPrice > val.price && val.price > discount){
                    setPackDiscount(val.discount)
                }
            })
            if(props._secodnaryPackPrice.every(val=>val > 0) && props._packageLength === props._secodnaryPackPrice.length){
                setDisabledButton(false)
            } else {
                setDisabledButton(true)
            }
        } else {
            setDisabledButton(true)
        }
    },[props._totalPackPrice, props._mainItemPrice])

    return <Grid container className={classes.buyPackage} direction='row' justify='center' alignItems='center' spacing={2}>
        <Grid item>
            <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item>
                    {props._packageLength + 1}X SET PRICE
                </Grid>
                <Grid item>
                    <span className={classes.discount}>{packDiscount}%</span> DISCOUNT
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item>
                    <span className={classes.packPrice}>
                        {format(Math.ceil(parseInt(packTotalPrice) - (parseInt(packTotalPrice) * packDiscount / 100)), {
                            currency: 'isk',
                            showDecimals: 'NEVER',
                            thousandSeparator: ' '
                        })}
                    </span>
                </Grid>
                <Grid item>
                    <span className={classes.oldPrice}>
                        {format(packTotalPrice, {
                            currency: 'isk',
                            showDecimals: 'NEVER',
                            thousandSeparator: ' '
                        })}
                    </span>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            
        </Grid>
        <Grid item>
            <Button variant='contained' disabled={disabledButton} onClick={()=>{props._handleAddToCart(packTotalPrice, packDiscount)}}>ADD SET TO CART</Button>
        </Grid>
    </Grid>
}

export default BuyPackage;