import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Material UI
import { makeStyles, Button, Grid, TextField, Typography,
    Modal, Fade, Backdrop } from '@material-ui/core/';

// Store
import { addToCart } from '../../../../../store/cart/cart';

// Gift Card Image
import gjafaKort from '../../../../../assets/aug_gjafabref_litil-min.jpg';

// Components
import ErrorModal from '../../Product/ErrorModal/errorModal';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: '#F2F2F2',
    },
    button: {
        margin: '1%'
    },
    blackText: {
        color: 'black',
        padding: '3%'
    },
    textField: {
        width: '50%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    giftcard: {
        backgroundImage: `url(${gjafaKort})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '20em',
    }
}));

const GiftCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [receiver, setReceiver] = useState('')
    const [amount, setAmount] = useState(0)
    const [errorMsgBool, setErrorMsgBool] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')

    function handleAddToCard(){
        if(receiver.length > 0){
            if(!isNaN(amount)){
                if(amount >= 500){
                    var giftCard = {
                        brand: 'pukinn',
                        description: 'Gjafakort',
                        detailsToBuy: { 
                            size: 'no size',
                            price: amount,
                            discount: 0,
                            qty: '1',
                            barcode: 'gjafakort'
                        },
                        itemdescription: '',
                        images: [gjafaKort],
                        qtyToBuy: 1,
                        unitprice1: amount,
                        category: { gjafakort: 'gjafakort'},
                        subcategory: receiver,
                        _id: 'gjafakort'
                        // itemcode: 'gjafakort'
                    }
                    dispatch(addToCart(giftCard))
                } else {
                    setErrorMsg('AMOUNT HAS TO BE 500 OR MORE')
                    setErrorMsgBool(true)
                }
            } else {
                setErrorMsg('AMOUNT CANNOT BE EMPTY')
                setErrorMsgBool(true)
            }
        } else {
            setErrorMsg('RECEIVER CANNOT BE EMPTY')
            setErrorMsgBool(true)
        }
    }

    return(
        <div>
            <Modal
                className={classes.modal}
                open={errorMsgBool}
                onClose={()=>setErrorMsgBool(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={errorMsgBool}>
                    <ErrorModal _message={errorMsg}></ErrorModal>
                </Fade>
            </Modal>
            <Grid container direction='row' justify='center' alignItems='center' className={classes.background}>
                <Grid item lg={6} xs={12} className={classes.giftcard}></Grid>
                <Grid item lg={6} xs={12}>
                    <Grid container direction='column'>
                        <Grid item>
                            <Typography className={classes.blackText}>
                                <FormattedMessage id='giftcard.text' 
                                    defaultMessage='GIFT CARD IS A VERY POPULAR ITEM. 
                                    WE OFFER CUSTOM MADE PÚKINN AND MOHAWKS GIFTCARD STARTING FROM 10.000 KR. 
                                    A REGULAR CARD IS ALSO AVAILABLE FOR 500 KR. GREAT SOLUTION TO GET A GIFT YOU DESIRE :)
                                    WE SEND ANYWHERE IN ICELAND!'/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField 
                                className={classes.textField} 
                                value={receiver} 
                                onChange={(e)=>setReceiver(e.target.value)}
                                label={intl.formatMessage({id: 'giftcard.owner', defaultMessage: 'WHO GETS A GIFT CARD?'})}
                            />
                        </Grid>
                        <Grid item>
                            <TextField 
                                className={classes.textField} 
                                value={amount} 
                                onChange={(e)=>setAmount(e.target.value)}
                                label={intl.formatMessage({id: 'giftcard.worth', defaultMessage: 'HOW MUCH IS A CARD WORTH?'})}
                                type='number'/>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button} variant='contained' color='secondary' onClick={handleAddToCard}>
                                <FormattedMessage id='fitmybike.addtocart' 
                                defaultMessage='ADD TO CART'/></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default GiftCard;