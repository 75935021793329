import React, { useState } from 'react';

// Material UI
import { makeStyles, Grid, CardContent, CardMedia, Typography, 
    CardActions, FormControl, InputLabel, Select } from '@material-ui/core/';

// React Router
import { Link } from "react-router-dom";

// Knight Demon
import knightDemon from '../../../../../../assets/icons/knight_demon.png';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles({
    sizes: {
        minWidth: '100%'
    },
    media: {
        height: '20rem',
        objectFit: 'contain'
    },
    option: {
        cursor: 'pointer'
    },
    text: {
        color: 'black'
    },
    price: {
        color: 'green'
    },
    redPrice: {
        color: 'red',
        textDecoration: 'line-through'
    },
});

const PackageProd = (props) => {
    const classes = useStyles();

    const handleChange = (event) => {
        var specificProd = JSON.parse(event.target.value)
        if(specificProd.barcode === props._defaultProd.barcode){
            props._handleSetProd({ size: 'CHOOSE THE SIZE', discount: 0, price: '0', barcode: 'default' }, props._prod)
        } else {
            props._handleSetProd(specificProd, props._prod)
        }
    };

    const PriceWithDiscount = () => <Grid container direction='row' justify='center' spacing={2}>
        <Grid item>
            <span className={classes.redPrice}>
                {format(props._defaultProd.price, {
                    currency: 'isk',
                    showDecimals: 'NEVER',
                    thousandSeparator: ' '
                })}
            </span>
        </Grid>
        <Grid item>
            <span className={classes.price}>
                {format(Math.ceil(parseInt(props._defaultProd.price) - (parseInt(props._defaultProd.price) * props._defaultProd.discount / 100)), {
                    currency: 'isk',
                    showDecimals: 'NEVER',
                    thousandSeparator: ' '
                })}
            </span>
        </Grid>
    </Grid>

    const PriceWithoutDiscount = () => <span className={classes.price}>
        {format(props._defaultProd.price, {
            currency: 'isk',
            showDecimals: 'NEVER',
            thousandSeparator: ' '
        })}
    </span>

    return <div>
        <CardMedia
            className={classes.media}
            component='img'
            image={props._prod.images.length > 0 ? props._prod.images[0] : knightDemon}
        />
        <CardContent>
            <Link to={`/product?id=${props._prod._id}`}>
                <Typography className={classes.text} variant='h5' component='h2' onClick={()=>console.log(props._prod)}>
                    {props._prod.description}
                </Typography>
            </Link>
            {props._defaultProd.discount > 0 ?
                <PriceWithDiscount></PriceWithDiscount> :
                <PriceWithoutDiscount></PriceWithoutDiscount>
            }
        </CardContent>
        <CardActions>
        {props._prod.sizepricesdiscountqty.length >= 1 &&
            props._prod.sizepricesdiscountqty[0].size !== '' ?
            <FormControl className={classes.sizes}>
            <InputLabel>SIZE</InputLabel>
                <Select
                    value={JSON.stringify(props._defaultProd)}
                    renderValue={(val)=>JSON.parse(val).size}
                    onChange={handleChange}
                >
                    <option disabled value={JSON.stringify(props._defaultProd)}>CHOOSE SIZE</option>
                    {props._prod.sizepricesdiscountqty.map((item, i)=>{
                        if(parseInt(item.qty) > 0){
                            return <option className={classes.option} key={i}
                                value={JSON.stringify(item)}
                            >
                                {item.size.toUpperCase()}
                            </option>
                        } 
                    })}
                </Select>
            </FormControl> :
            <Grid container justify='flex-start'>
            {props._prod.sizepricesdiscountqty[0].size === '' ?
                <Typography>SIZE: NO SIZE</Typography> :
                <Typography>SIZE: {props._prod.sizepricesdiscountqty[0].size.toUpperCase()}</Typography>
            }
            </Grid>
        }
        </CardActions>
    </div>
}

export default PackageProd;