import React, { useState } from 'react';

// FB Messenger
import MessengerCustomerChat from 'react-messenger-customer-chat';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl'; 

// Material UI
import { makeStyles, Button, Menu, MenuItem, Modal, 
    Backdrop, IconButton, Snackbar } from '@material-ui/core/';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import FBMessenger from '../../assets/icons/SVG/fbmessenger';

// Components
import IdeaForm from '../Footer/Forms/ideaForm';

const useStyles = makeStyles((theme) => ({
    extendedIcon: {
        margin: 0,
        top: 'auto',
        right: 25,
        bottom: 80,
        left: 'auto',
        position: 'fixed',
        zIndex: 1
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    white: {
        color: 'white'
    },
    black: {
        color: 'black'
    },
    pukinnred: {
        backgroundColor: '#212121',
        color: 'white'
    }
}));

const ContactUs = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const [anchorEl, setAnchorEl] = useState(null);
    const [emailForm, setEmailForm] = useState(false)
    const [showMessenger, setShowMessenger] = useState(false)
    const [loading, setLoading] = useState(false)
    const [FBActive, setFBActive] = useState(false)
    const [errMsg, setErrMsg] = useState(false)

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    function handleOpenMail() {
        setAnchorEl(null);
        setEmailForm(true)
    }

    function handleLoadMessenger() {
        setFBActive(false);
        if(showMessenger === false){
            setLoading(true)
            setTimeout(()=>{
                if(FBActive === false){
                    setErrMsg(true)
                }
                setLoading(false)
            },10000)
        } 
        setShowMessenger(!showMessenger)
    }

    function handleSetLoadingFalse() {
        setLoading(false)
        setAnchorEl(null)
        setFBActive(true)
    }

    return <div style={{zIndex: 10}}>
        <Modal
            className={classes.modal}
            open={emailForm}
            onClose={()=>setEmailForm(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <IdeaForm _closeForm={()=>setEmailForm(false)} _title={intl.formatMessage({ id: 'contactus.message' })}></IdeaForm>
        </Modal>
        {!props._isMobile ?
            <div className={classes.extendedIcon}>
                {showMessenger ?
                    <MessengerCustomerChat
                        pageId='371626723019839'
                        appId=''
                        shouldShowDialog={true}
                        onCustomerChatDialogShow={handleSetLoadingFalse}
                    /> : <div></div>
                }
                <Button fab variant='contained' className={classes.pukinnred} aria-label='add' size='large' onClick={(e)=>handleOpenMenu(e)}>
                    <ContactSupportIcon />
                    <FormattedMessage 
                        id='contactus.contactus' 
                        defaultMessage='CONTACT US'
                    />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={anchorEl}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleLoadMessenger} disabled={loading}>
                        <FBMessenger/>
                        {loading ? 'LOADING...' : 'MESSENGER'}
                    </MenuItem>
                    {/* <MenuItem onClick={handleCloseMenu}>
                        <CallIcon/>
                        CALL
                    </MenuItem> */}
                    <MenuItem onClick={handleOpenMail}>
                        <EmailIcon/>
                        <FormattedMessage 
                            id='contactus.sendemail' 
                            defaultMessage='SEND EMAIL'
                        />
                    </MenuItem>
                </Menu>
            </div> :
            <div>
                {showMessenger ?
                    <MessengerCustomerChat
                        pageId='371626723019839'
                        appId=''
                        shouldShowDialog={true}
                        onCustomerChatDialogShow={handleSetLoadingFalse}
                    /> : <div></div>
                }
                <IconButton onClick={(e)=>handleOpenMenu(e)}>
                    <ContactSupportIcon className={classes.white}/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={anchorEl}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleLoadMessenger} disabled={loading}>
                        <FBMessenger/>
                        {loading ? 'LOADING...' : 'MESSENGER'}
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <a href='tel:003545666820' className={classes.black}><CallIcon/> CALL</a>
                    </MenuItem>
                    <MenuItem onClick={handleOpenMail}>
                        <EmailIcon/>
                        SEND EMAIL
                    </MenuItem>
                </Menu>
            </div>
        }
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={errMsg}
            autoHideDuration={10000}
            onClose={()=>setErrMsg(false)}
            message="Messenger can't load. Turn off the AD Blocker if you want to use it."
        />
    </div> 
}

export default ContactUs;