import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { yearsApiCall } from '../actions';

const slice = createSlice({
    name: 'years',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        yearsRequested: (state, action) => {
            state.loading = true
        },

        yearsRequestFailed: (state, action) => {
            state.loading = false
        },

        yearsReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, make, model, yearsRequested, yearsRequestFailed, yearsReceived } = slice.actions
export default slice.reducer;

export const getFilteredYears = createSelector(
    state => state.years,
    (years) => years.list,
)

export const loadYears = (client, db, make, model) => (dispatch, getState) => {
    dispatch(
        yearsApiCall({
            client: client,
            db: db,
            make: make,
            model: model,
            onStart: yearsRequested.type,
            onSuccess: yearsReceived.type,
            onError: yearsRequestFailed.type
        })
    )
}