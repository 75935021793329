import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { brandsByProductsApiCall } from '../actions';

const slice = createSlice({
    name: 'brandsByProducts',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        brandsByProductsRequested: (state, action) => {
            state.loading = true
        },

        brandsByProductsRequestFailed: (state, action) => {
            state.loading = false
        },

        brandsByProductsReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, query, brandsByProductsRequested, brandsByProductsRequestFailed, brandsByProductsReceived } = slice.actions
export default slice.reducer;

export const getBrandsByProducts = createSelector(
    state => state.brandsByProducts,
    (brandsByProducts) => brandsByProducts.list,
)

export const loadBrandsByProducts = (client, db, query) => (dispatch, getState) => {
    dispatch(
        brandsByProductsApiCall({
            client: client,
            db: db,
            query: query,
            onStart: brandsByProductsRequested.type,
            onSuccess: brandsByProductsReceived.type,
            onError: brandsByProductsRequestFailed.type
        })
    )
}