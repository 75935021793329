import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';


// Material UI
import { Typography, Divider, Grid, Button, TextField,
    Modal, Fade, Backdrop } from '@material-ui/core';

// Icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DetailsIcon from '@material-ui/icons/Details';

// React Router
import { Link } from "react-router-dom";

// Carousel
import { Carousel } from 'react-responsive-carousel';
import '../../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

// Store
import { addToCart } from '../../../../../../store/cart/cart';

// Components
import ErrorModal from '../../../Product/ErrorModal/errorModal';

// Demon Knight
import demonKnight from '../../../../../../assets/icons/knight_demon.png';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    prodContainer: {
      padding: '1%'
    },
    discount: {
        backgroundColor: '#ED1C24',
        borderRadius: '0% 0% 0% 70%',
        color: 'white',
        textAlign: 'center',
        display: 'table-cell',
        height: '10%',
        width: '5%',
        left: '14.6%',
        position: 'absolute',
        zIndex: '1'
    },
    prodImg: {
      backgroundColor: 'white',
    },
    oldPrice: {
        color: 'red',
        textDecoration: 'line-through'
    },
    price: {
      color: 'green'
    },
    sizes: {
        minWidth: '10%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Fit = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1)
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    function handleAddToCart(prod){
        var productToCart = JSON.parse(JSON.stringify(prod));
        productToCart['qtyToBuy'] = parseInt(quantity)
        productToCart['detailsToBuy'] = {
            size: prod.size,
            price: prod.barcode,
            discount: prod.discount,
            qty: prod.qty,
            barcode: prod.itemcode,
        }
        if(parseInt(prod.qty) < 1){
            setErrorMsgBool(true)
            setErrorMsg('THIS SIZE IS OUT OF STOCK.')
        } else if(parseInt(quantity) < 1) {
            setErrorMsgBool(true)
            setErrorMsg('QUANTITY IS LESS THAN 1')
        } else {
            dispatch(addToCart(productToCart))
        }
    }

    return <div className={classes.prodContainer}>
        <Modal
                className={classes.modal}
                open={errorMsgBool}
                onClose={()=>setErrorMsgBool(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>

        <Grid container direction='row' justify='space-between'>
            <Grid item>
                <Grid container direction='row' justify='space-evenly' alignItems='center' spacing={1}>
                    <Grid item>
                        {/* {props.prod.discount > 0 ?
                            <div className={classes.discount}><p>{props.prod.discount}%</p></div> :
                            <div></div>
                        } */}
                        <Carousel
                            showThumbs={false}
                            showIndicators={false}
                            showStatus={false}
                            width={150}
                        >
                            {props.prod.images.length > 0 ?
                                props.prod.images.map((image, i)=>{
                                    return <img className={classes.prodImg} key={i} src={image}/>
                                }) :
                                <img src={demonKnight}/>
                            }
                        </Carousel>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1' gutterBottom>{props.prod.description}</Typography>
                    {/* </Grid>
                    <Grid item> */}
                        {props.prod.discount > 0 ?
                            <div>
                                <Typography variant='subtitle1' gutterBottom>
                                    <span className={classes.oldPrice}><sup>{format(props.prod.barcode, {
                                        currency: '',
                                        showDecimals: 'NEVER',
                                        thousandSeparator: ' '
                                    })}</sup></span>
                                    <span className={classes.price}>
                                        {format(Math.ceil(parseInt(props.prod.barcode) - (parseInt(props.prod.barcode) * props.prod.discount / 100)), {
                                            currency: 'isk',
                                            showDecimals: 'NEVER',
                                            thousandSeparator: ' '
                                        })}
                                    </span>
                                </Typography>
                            </div> :
                            <Typography className={classes.price} variant='subtitle1' gutterBottom>
                                {format(props.prod.barcode, {
                                    currency: 'isk',
                                    showDecimals: 'NEVER',
                                    thousandSeparator: ' '
                                })}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                {parseInt(props.prod.qty) > 0 ?
                    <Grid container direction='row' justify='space-evenly' alignItems='center' spacing={1}>
                        <Grid item>
                            <TextField
                                className={classes.sizes}
                                label={<FormattedMessage id='fitmybike.quantity' 
                                defaultMessage='Quantity'/>}
                                type='number'
                                defaultValue={quantity}
                                onChange={(e)=>setQuantity(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                startIcon={<AddShoppingCartIcon />}
                                onClick={()=>handleAddToCart(props.prod)}
                            >
                                <FormattedMessage id='fitmybike.addtocart' 
                                defaultMessage='ADD TO CART'/>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Link to={`/product?id=${props.prod.groupid}`}>
                                <Button
                                    variant='contained'
                                    startIcon={<DetailsIcon />}
                                >
                                    <FormattedMessage id='fitmybike.showmore' 
                                    defaultMessage='SHOW MORE'/>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid> :
                    <Grid container direction='row' justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant='contained'
                            >
                                <FormattedMessage id='fitmybike.soldout' 
                                defaultMessage='SOLDOUT'/>
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
        <Divider variant="middle" />
    </div>
}

export default Fit;