import React, { useContext } from 'react';

// Material UI
import { IconButton } from '@material-ui/core';

// FormatJS (Internationalization)
import { Context } from './Wrapper';

// Icons
import IcelandFlag from '../assets/icons/SVG/icelandflag';
import BritainFlag from '../assets/icons/SVG/britainflag';

const Languages = () => {
    const context = useContext(Context)

    return <div>
        {context.locale1.includes('en') ?
            <IconButton onClick={()=>context.selectLang('is-IS')}><IcelandFlag/></IconButton> :
            <IconButton onClick={()=>context.selectLang('en-GB')}><BritainFlag/></IconButton>
        }
    </div>
}

export default Languages;