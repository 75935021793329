import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI
import { makeStyles, fade, AppBar, Toolbar, Grid, 
    Typography, IconButton, Modal, Backdrop, Badge, 
    InputBase } from '@material-ui/core/';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import LanguageIcon from '@material-ui/icons/Language';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Countdown
import Countdown from 'react-countdown';

// Icons
import facebookIcon from '../../assets/icons/fb.png';
import instagramIcon from '../../assets/icons/insta.png';

// Components
import Information from '../Modals/Information/information';
import IdeaForm from './Forms/ideaForm';
import ContactUs from '../ContactUs/contactUs';
import Languages from '../../languages/languages';

// React Router
import { Link } from "react-router-dom";

// Store
import { getFilteredCart } from '../../store/cart/cart';
import Newsletter from './Newsletter/newsletter';

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#212121',
        textAlign: 'start',
    },
    icon: {
        color: 'white',
        // marginRight: '1%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clickable: {
        cursor: 'pointer'
    }
}));

const Footer = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const [informationModal, setOpenInformationModal] = useState(false);
    const [email, setEmail] = useState('')

    const [formType, setFormType] = useState(0)
    const [form, setForm] = useState(false)

    var productsInCart = useSelector(getFilteredCart)
    useEffect(()=>{},[productsInCart])

    const Completionist = () => <span style={{color: 'red'}}>Closed</span>;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <span style={{color: 'red'}}>{hours}:{minutes}:{seconds}</span>;
        }
      };

    function closeInformationModal(){
        setOpenInformationModal(false);
    }

    function handleOpenEmailForm(form){
        setForm(true)
    }

    const monThuCountdown = () => {
        var currentDate = new Date()
        var opening = new Date()
        opening.setHours(9)
        opening.setMinutes(30)
        var closing1830 = new Date()
        closing1830.setHours(18)
        closing1830.setMinutes(30)
        var monToThuClosing = closing1830 - Date.now()
        if(currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4) {
            if(Date.now() >= opening) {
                return <Countdown renderer={renderer} daysInHours={true} date={Date.now() + monToThuClosing}><Completionist /></Countdown>
            } else {
                return <Countdown daysInHours={true} date={Date.now()}><Completionist /></Countdown>
            }
        } else {
            return <div></div>
        }
    }

    const friCountdown = () => {
        var currentDate = new Date()
        var opening = new Date()
        opening.setHours(9)
        opening.setMinutes(30)
        var closing1900 = new Date()
        closing1900.setHours(19)
        closing1900.setMinutes(0)
        var friClosing = closing1900 - Date.now()
        if(currentDate.getDay() === 5) {
            if(Date.now() >= opening) {
                return <Countdown renderer={renderer} daysInHours={true} date={Date.now() + friClosing}><Completionist /></Countdown>
            } else {
                return <Countdown daysInHours={true} date={Date.now()}><Completionist /></Countdown>
            }
        } else {
            return <div></div>
        }
    }

    const satCoundown = () => {
        var currentDate = new Date()
        var opening = new Date()
        opening.setHours(11)
        opening.setMinutes(0)
        var closing1600 = new Date()
        closing1600.setHours(16)
        closing1600.setMinutes(0)
        var satClosing = closing1600 - Date.now()
            if(currentDate.getDay() === 6) {
                if(Date.now() >= opening) {
                    return <Countdown renderer={renderer} daysInHours={true} date={Date.now() + satClosing}><Completionist /></Countdown>
                } else {
                    return <Countdown daysInHours={true} date={Date.now()}><Completionist /></Countdown>
                }
            } else {
                return <div></div>
            }
    }

    const akMonFriCountdown = () => {
        var currentDate = new Date()
        var opening = new Date()
        opening.setHours(10)
        opening.setMinutes(0)
        var closing1800 = new Date()
        closing1800.setHours(18)
        closing1800.setMinutes(0)
        var monToThuClosing = closing1800 - Date.now()
        if(currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4 || currentDate.getDay() === 5) {
            if(Date.now() >= opening) {
                return <Countdown renderer={renderer} daysInHours={true} date={Date.now() + monToThuClosing}><Completionist /></Countdown>
            } else {
                return <Countdown daysInHours={true} date={Date.now()}><Completionist /></Countdown>
            }
        } else {
            return <div></div>
        }
    }

    const aksatCountdown = () => {
        var currentDate = new Date()
        var opening = new Date()
        opening.setHours(12)
        opening.setMinutes(0)
        var closing1600 = new Date()
        closing1600.setHours(16)
        closing1600.setMinutes(0)
        var satClosing = closing1600 - Date.now()
        if(currentDate.getDay() === 6) {
            if(Date.now() >= opening) {
                return <Countdown renderer={renderer} daysInHours={true} date={Date.now() + satClosing}><Completionist /></Countdown>
            } else {
                return <Countdown daysInHours={true} date={Date.now()}><Completionist /></Countdown>
            }
        } else {
            return <div></div>
        }
    }

    if(props._isMobile){
        return(
            <AppBar position='sticky' className={classes.appBar}>
                <Modal
                    className={classes.modal}
                    open={informationModal}
                    onClose={closeInformationModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Information _fadeOpen={informationModal} _closeInformationModal={closeInformationModal} _mongoClient={props._mongoClient}></Information>
                </Modal>
                <Grid container justify='space-evenly' alignItems='center'>
                    <Grid item lg={3}>
                        <IconButton onClick={()=>setOpenInformationModal(true)}>
                            <InfoIcon className={classes.icon} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={3}>
                        <Languages></Languages>
                    </Grid>
                    <Grid item lg={3}>
                        <IconButton>
                            <ContactUs _isMobile={props._isMobile}/>
                        </IconButton>
                    </Grid>
                    <Grid item lg={3}>
                        <IconButton onClick={props._handleDrawerOpen}>
                            <Badge badgeContent={productsInCart.length} color='secondary'>
                                <ShoppingCartIcon className={classes.icon} />
                            </Badge>
                        </IconButton>
                    </Grid>
                </Grid>
            </AppBar>
        )
    }
    return(
        <AppBar position='relative' className={classes.appBar}>
            <Modal
                className={classes.modal}
                open={form}
                onClose={()=>setForm(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <IdeaForm _closeForm={()=>setForm(false)} _title={intl.formatMessage({ id: 'contactus.idea' })}></IdeaForm>
            </Modal>
            <Toolbar>
                <Grid container>
                    <Grid item sm={3} xs={6}>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column1.about' 
                                defaultMessage='Pukinn.com specializes on sale and services of clothing and protective equipment for people who love motorbikes, bicycles, snowboards and skateboards.'
                            />
                        </Typography>
                        <a href='https://www.facebook.com/PukinnN/' target='_blank'><img src={facebookIcon} className={classes.icon}/></a>
                        <a href='https://www.instagram.com/pukinn.mork/?hl=en' target='_blank'><img src={instagramIcon} className={classes.icon} /></a>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column1.trademark' 
                                defaultMessage='Púkinn EHF. All rights reserved.'
                            />
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            Kennitala: 690300-2060 <br/>
                            VSK:66699 <br/>
                            <a href= 'mailto: pukinn@pukinn.com'>pukinn@pukinn.com</a>
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Typography variant='body2' gutterBottom>
                            Mörkin 4 <br/>
                            <FormattedMessage 
                                id='footer.columns.phone' 
                                defaultMessage='Phone: +354 566 6820'
                            />
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.columns.openingHours' 
                                defaultMessage='Opening Hours: '
                            />
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column2.monThu' 
                                defaultMessage='Mon-Thur: 9:30-18:30'
                                values={{times: '9:30-18:30'}}
                            /> 
                            {/* {monThuCountdown()} */}
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column2.fri' 
                                defaultMessage='Friday: 9:30-19:00'
                                values={{times: '9:30-19:00'}}
                            /> 
                            {/* {friCountdown()} */}
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.columns.sat' 
                                defaultMessage='Saturday: 11:00-16:00'
                                values={{times: '11:00-16:00'}}
                            /> 
                            {/* {satCoundown()} */}
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.columns.sun' 
                                defaultMessage='Sunday: Closed'
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Typography variant='body2' gutterBottom>
                            Hafnarstræti 99-101 <br/>
                            Akureyri. <br/>
                            <FormattedMessage 
                                id='footer.columns.phoneAkureyri' 
                                defaultMessage='Phone: +354 571 3188'
                            />
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.columns.openingHours' 
                                defaultMessage='Opening Hours: '
                            />
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column3.monFri' 
                                defaultMessage='Mon-Fri: 11:00-18:00'
                                values={{times: '11:00-18:00'}}
                            /> 
                            {/* {akMonFriCountdown()} */}
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.column3.sat' 
                                defaultMessage='Saturday: 12:00-16:00'
                                values={{times: '12:00-16:00'}}
                            /> 
                            {/* {aksatCountdown()} */}
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            <FormattedMessage 
                                id='footer.columns.sun' 
                                defaultMessage='Sunday: Closed'
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Link to='/about'>
                            <Typography variant='body2' gutterBottom>
                                <FormattedMessage 
                                    id='footer.column4.aboutUs' 
                                    defaultMessage='About Us'
                                />
                            </Typography>
                        </Link>
                        <Typography className={classes.clickable} variant='body2' gutterBottom onClick={()=>handleOpenEmailForm(0)}>
                            <FormattedMessage 
                                id='footer.column4.contactUs' 
                                defaultMessage='Contact Us'
                            />          
                        </Typography>
                        <Typography className={classes.clickable} variant='body2' gutterBottom onClick={()=>handleOpenEmailForm(1)}>
                            <FormattedMessage 
                                id='footer.column4.idea' 
                                defaultMessage='Send us an idea'
                            />  
                        </Typography>
                        <Link to='/faq'>
                            <Typography variant='body2' gutterBottom>
                                <FormattedMessage 
                                    id='footer.column4.faq' 
                                    defaultMessage='FAQ'
                                /> 
                            </Typography>
                        </Link>
                        <Link to='/termsAndConditions'>
                            <Typography variant='body2' gutterBottom>
                                <FormattedMessage 
                                    id='footer.column4.terms' 
                                    defaultMessage='Terms and Conditions'
                                />  
                            </Typography>
                        </Link>
                        <Newsletter _mongoClient={props._mongoClient}></Newsletter>
                    </Grid>
                </Grid>
            </Toolbar>
            <ContactUs _isMobile={props._isMobile}></ContactUs>
        </AppBar>
    )
}

export default Footer;