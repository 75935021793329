import React, { useState } from 'react';

// Material UI
import { makeStyles, Grid, Typography, Card, CardContent,
    CardMedia, Box } from '@material-ui/core/';

// React Card Flip
import ReactCardFlip from 'react-card-flip';

// Mummi
import mummi from '../../../../../assets/mummi.jpg';
import flameHeart from '../../../../../assets/flameHeart.jpg';

const useStyles = makeStyles((theme) => ({
    text: {
        color: 'black',
    },
    secretText: {
        color: 'white'
    },
    textRed: {
        color: 'red'
    },
    card: {
        height: '100%',
    },
    secretCard: {
        height: '100%',
        backgroundColor: 'black'
    },
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
    secretMedia: {
        height: 0,
        paddingTop: '150%'
    }
}));

const MummiSpecialCard = () => {
    const classes = useStyles();
    const [isFlipped, setFlip] = useState(false)

    return <Grid item xs={12} sm={4}>
        <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
            
        <Card className={classes.card}>
            <CardMedia
                className={classes.media}
                image={mummi}
                title="Mummi"
            />
            <CardContent>
                <Box textAlign="left">
                    <Typography gutterBottom variant="h5" component="h2" className={classes.text}>
                        MUMMI
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                        STARFSMAÐUR Í REYKJAVÍK
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>   
                        PISCES
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                        AT THE MOMENT HE OWNS A SPORTSTER...
                        MUMMI HAS FUN WITH MX BIKES..
                        FAVORITE FOOD: BLOOD BEEF STEAK.
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                        FAVORITE FOOD: BLOOD BEEF STEAK.
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                        <span className={classes.textRed}>SLOGAN:</span> 
                        "OWNING A MOTORCYCLE IS NOT A MATTER OF LIFE AND DEATH. IT IS MUCH MORE IMPORTANT THAN THAT"
                    </Typography>
                </Box>
                <Box textAlign="right" onClick={()=>setFlip(true)}>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.secretText}>
                        secret
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    
        <Card className={classes.secretCard} onClick={()=>setFlip(false)}>
            <CardMedia
                className={classes.secretMedia}
                image={flameHeart}
                title='Flaming Heart'
            />
            <CardContent>
                <Box textAlign='center'>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.secretText}>
                        FOREVER IN OUR HEARTS
                    </Typography>
                </Box>
            </CardContent>
        </Card>
      </ReactCardFlip>
    </Grid> 
}

export default MummiSpecialCard;