import React, { useState } from 'react';

// FormatJS (Internationalization)
import { useIntl, FormattedMessage } from 'react-intl';

// Material UI
import { makeStyles, CardHeader, Button, Card, Grid } from '@material-ui/core/';

// Demon Knight
import demonKnight from '../../../../../../assets/icons/knight_demon.png';

const useStyles = makeStyles((theme) => ({
    media: {
        height: '20rem',
        objectFit: 'contain'
    },
    name: {
        borderBottom: '1px solid grey'
    }
}));

const Bike = (props) => {
    const classes = useStyles();
    const intl = useIntl()

    return <Card>
        <CardHeader
            title={<Grid container direction='column' jusitfy='center' alignItems='center'>
                <Grid item className={classes.name}>{props._bike.description.toUpperCase()}</Grid>
                <Grid item>
                    <FormattedMessage 
                        id='product.size' 
                        defaultMessage='SIZE'
                    />: {props._bike.size.toUpperCase()}</Grid>
            </Grid>}
            subheader={`24${intl.formatMessage({ id: 'hour', defaultMessage: 'h' })} | 12 000 isk`}
        />
        <div className={classes.imgContainer}>
            {props._bike.images.length > 0 ?
                <img className={classes.media} src={props._bike.images[0]}/> :
                <img className={classes.media} src={demonKnight}/>
            }
            {/* <img className={classes.rifan} src={rifan}/> */}
        </div>
        <Button
            variant='contained'
            color='secondary'
            onClick={()=>props._handleBookNow(props._bike)}
        >
            <FormattedMessage 
                id='bikebooking.booknow' 
                defaultMessage='BOOK NOW'
            />
        </Button>
    </Card>
}

export default Bike;