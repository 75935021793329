import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const productApi = ({ dispatch }) => next => action => {
    if(action.type !== actions.productApiCall.type) return next(action)
    const { client, db, db2, groupid, onStart, onSuccess, onError } = action.payload
    client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
        db.collection('products').aggregate([
            { $match: { groupid: groupid, webview: true } },
            { $group: {
                _id: "$groupid",
                category: { $last: "$category" },
                images: { $last: "$images" },
                description: { $last: "$description" },
                sizepricesdiscountqty: { 
                    $push: { size: "$size", price: "$barcode", discount: "$discount", qty: "$qty", barcode: "$itemcode" } 
                },
                itemdescription: { $last: "$itemdescription" },
                brand: { $last: "$brand" },
                package: { $last: "$package" },
                unitprice1: { $last: "$unitprice1" },
                subcategory: { $last: "$subcategory" }
            } },
            // { $lookup: {
            //     from: "products",
            //     localField: "package.groupid",
            //     foreignField: "groupid",
            //     as: "packageProds",
            // } },
        ]).toArray().then((result)=>{
            db2.collection('brands').aggregate([
                { $match: { name: result[0].brand } },
                { $project: { description: 1, image: 1, _id: 0 } }
            ]).toArray().then((res)=>{
                if(res.length > 0){
                    result[0]['brandDetails'] = res[0]
                }
                var groupidsArr = []
                if(result[0].package !== null){
                    result[0].package.map((val)=>{
                        groupidsArr.push(val.groupid)
                    })
                    db.collection('products').aggregate([
                        { $match: {  groupid: { $in: groupidsArr }, webview: true } },
                        { $group: {
                            _id: "$groupid",
                            category: { $last: "$category" },
                            images: { $last: "$images" },
                            description: { $last: "$description" },
                            subcategory: { $last: "$subcategory" },
                            sizepricesdiscountqty: { 
                                $push: { size: "$size", price: "$barcode", discount: "$discount", qty: "$qty", barcode: "$itemcode", groupid: "$groupid" } 
                            },
                            itemdescription: { $last: "$itemdescription" },
                            brand: { $last: "$brand" },
                            totalQty: { $sum: { $toInt: "$qty" } },
                            unitprice1: { $last: "$unitprice1" }
                        } },
                        { $match: {  totalQty: { $gt: 0 } } },
                    ]).toArray().then((results)=>{
                        result[0]['packageProds'] = results
                        if(onSuccess) dispatch({ type: onSuccess, payload: result})
                        else dispatch(actions.productsApiCallSucess(result))
                    }).catch(e=>console.log(e))
                } else {
                    if(onSuccess) dispatch({ type: onSuccess, payload: result})
                    else dispatch(actions.productsApiCallSucess(result))
                }
            }).catch((e)=>{
                console.log(e)
                if(e) dispatch({ type: onError, payload: e.message })
                else dispatch(actions.productsApiCallFailed(e.message))
            })
        }).catch((e)=>{
            console.log(e)
            if(e) dispatch({ type: onError, payload: e.message })
            else dispatch(actions.productApiCallFailed(e.message))
        })
    })
    return next(action)
}

export default productApi;