import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles, Grid, Typography, List, ListItem } from '@material-ui/core/';

//Mongo Stitch
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Store
import { loadFeaturedProducts, getFilteredFeaturedProducts } from '../../../../../store/featuredProducts/featuredProducts';

// Components
import FeaturedProduct from './FeaturedProduct/featuredProduct';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: '1%'
    },
    text: {
        color: 'black',
        fontSize: '25px'
    },
    flex: {
        display: 'flex',
        overflowX: 'auto',
        minHeight: '120px'
    }
}));

const FeaturedProducts = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    var featuredProducts = useSelector(getFilteredFeaturedProducts);

    const [db, setDB] = useState(null)
    const [client, setClient] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            setDB(db)
            setClient(client)
    
            dispatch(loadFeaturedProducts(client, db));
            setLoading(false);
        }
    },[props._mongoClient])

    useEffect(()=>{},[featuredProducts])

    return <div>
        {featuredProducts.length > 0 ?
            <div className={classes.padding}>
                <Typography className={classes.text}>HOT ITEMS</Typography>
                <Grid container justify='center' alignItems='center' spacing={1}>
                    {featuredProducts.map((val,i)=>
                        <Grid item key={i} lg={3} xs={6}>
                            <FeaturedProduct _details={val}></FeaturedProduct>
                        </Grid>
                    )}
                </Grid>
            </div> :
            <div></div>
        }
    </div>

}

export default FeaturedProducts;