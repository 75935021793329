import React, { useEffect, useState, createRef } from 'react';
import { useDispatch } from 'react-redux';

// Material UI
import { makeStyles, Grid, Card, Button, Typography,
    Divider, TableContainer, Table, TableHead, TableRow, 
    TableCell, TableBody, Paper, Modal, Fade,
    Backdrop, Snackbar } from '@material-ui/core/';

// React Router
import { useHistory } from 'react-router-dom';

// React to pdf
import Pdf from 'react-to-pdf';

// axios
import axios from 'axios';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import ErrorModal from '../Product/ErrorModal/errorModal';

// Store
import { clearCart } from '../../../../store/cart/cart';

// Images
import pukinnLogo from '../../../../assets/pukinnlogo.png';
import pukinnlogo_porter from '../../../../assets/pukinnlogo_porter.png';

const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid grey',
        paddingTop: '3%'   
    },
    img: {
        width: '50%',
        alignItems: 'center'
    },
    divider: {
        width:'95%', 
        margin:'3%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ReceiptProd = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const ref = createRef();

    const [customer, setCustomer] = useState({})
    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [openSnackBar, setOpenSnackBar] = useState(false)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        var c = window.localStorage.getItem('pukinn_customer_prod')
        if(c !== null){
            window.localStorage.removeItem('pukinn_cart')
            dispatch(clearCart())
            var cParsed = JSON.parse(c)
            setCustomer(cParsed)
            axios({
                method: 'post',
                url: 'https://us-central1-pukinn2.cloudfunctions.net/sendReceiptV2',
                data: cParsed,
            }).then(function (response) {
                setOpenSnackBar(true)
                window.localStorage.removeItem('pukinn_customer_prod')
                setTimeout(()=>{setOpenSnackBar(false)},3000)
                console.log(response);
            }).catch(function (error) {
                setErrorMsg('COULD NOT SENT THE RECEIPT TO YOUR EMAIL. PLEASE SCROLL DOWN AND DOWNLOAD THE PDF RECEIPT.')
                setErrorMsgBool(true)
            });
        } else {
            // history.push('/')
        }
    },[])

    function handleClose(){
        window.localStorage.removeItem('pukinn_customer_prod')
        history.push('/')
    }

    return <div style={{marginTop: props._marginTop}}>
        <Modal
            className={classes.modal}
            open={errorMsgBool}
            onClose={()=>setErrorMsgBool(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        {Object.keys(customer).length > 0 ?
            <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
                <Grid item lg={10} xs={10}>
                    <Card className={classes.container}>
                        <div style={{width: '100%', height: '100%'}} ref={ref}>
                            <Grid container direction='column' justify='center' alignItems='center'>
                                <Grid container direction='row' justify='center' alignItems='center'>
                                <Grid item lg={6} xs={6}>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={12} xs={12}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.billfrom' 
                                                    defaultMessage='BILL FROM'
                                                /></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='payment.fullname' 
                                                    defaultMessage='NAME'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>Púkinn ehf.</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.address' 
                                                    defaultMessage='ADDRESS'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>Mörkin 5, 108 Reykjavík, Iceland</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='delivery.email' 
                                                    defaultMessage='EMAIL'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>pukinn@pukinn.com</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item item lg={6} xs={6}>
                                        <img className={classes.img} src={pukinnLogo} />
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider}/>
                                <Grid container direction='row' justify='center' alignItems='flex-start'>
                                    <Grid item lg={6} xs={6}>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={12} xs={12}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.billto' 
                                                    defaultMessage='BILL TO'
                                                /></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='payment.fullname' 
                                                    defaultMessage='NAME'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.name}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.address' 
                                                    defaultMessage='ADDRESS'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.address}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='delivery.email' 
                                                    defaultMessage='EMAIL'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>KENNITALA:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.kennitala}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.orderid' 
                                                    defaultMessage='ORDER ID: '
                                                /></Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>#{customer.orderId}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.orderdate' 
                                                    defaultMessage='ORDER DATE: '
                                                /></Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.dateOrder}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider}/>
                                <Grid container justify='center' alignItems='center'>
                                    <Grid item xs={10} lg={10}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{backgroundColor:'#212121'}}>
                                                    <TableRow> 
                                                        <TableCell style={{color:'white'}}>Item</TableCell>
                                                        <TableCell style={{color:'white'}} align='right'><FormattedMessage 
                                                            id='receipt.size' 
                                                            defaultMessage='Size'
                                                        /></TableCell>
                                                        <TableCell style={{color:'white'}} align='right'><FormattedMessage 
                                                            id='receipt.quantity' 
                                                            defaultMessage='Quantity'
                                                        /></TableCell>
                                                        <TableCell style={{color:'white'}} align='right'><FormattedMessage 
                                                            id='receipt.price' 
                                                            defaultMessage='Price'
                                                        /></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {customer.products.map((prod, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell component='t' scope='row'>
                                                            {prod.description}
                                                        </TableCell>
                                                        <TableCell align='right'>{prod.size}</TableCell>
                                                        <TableCell align='right'>{prod.qty}</TableCell>
                                                        <TableCell align='right'>{prod.barcode} isk</TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider}/>
                                <Grid container direction='row' justify='center' alignItems='flex-start'>
                                    <Grid item lg={6} xs={6}>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='receipt.shipping' 
                                                    defaultMessage='SHIPPING'
                                                />:</Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.payThrough.toLowerCase() === 'kort' ?
                                                    '1500 isk' :
                                                    '0 isk'
                                                }</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item lg={6} xs={6}>
                                                <Typography><FormattedMessage 
                                                    id='cart.total' 
                                                    defaultMessage='TOTAL'
                                                />: </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>{customer.totalPrice} {customer.currency === '352' ? 'isk' : 'eur'}</Typography>
                                                {customer.giftcardBookId !== null && customer.giftcardBookId !== undefined ?
                                                    <Typography>{customer.giftcardcredit} isk <FormattedMessage 
                                                        id='receipt.giftcardapplied' 
                                                        defaultMessage='Giftcard Applied'
                                                    /> {customer.giftcardBookId}</Typography> : <div></div>
                                                }    
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider}/>
                                <Grid container direction='row' justify='center' alignItems='center'>
                                    <Grid item>
                                    <img style={{width:'10%'}} src={pukinnlogo_porter} />
                                    <Typography>Púkinn ehf. All rights reserved</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <Pdf targetRef={ref} filename={`Receipt-${customer.kennitala}`} onComplete={handleClose} scale={0.65}>
                            {({ toPdf }) => <Button onClick={toPdf} variant='contained' color='secondary'><FormattedMessage 
                                id='receipt.downloadpdf' 
                                defaultMessage='DOWNLOAD IN PDF'
                            /></Button>}
                        </Pdf>
                    </Card>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSnackBar}
                    message='RECEIPT SENT TO YOUR EMAIL'
                />
            </Grid> :
            <div></div>
        }
    </div>
}

export default ReceiptProd;