import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { searchApiCall } from '../actions';

const slice = createSlice({
    name: 'search',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        searchRequested: (state, action) => {
            state.loading = true
        },

        searchRequestFailed: (state, action) => {
            state.loading = false
        },

        searchReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, query, skip, searchRequested, searchRequestFailed, searchReceived } = slice.actions
export default slice.reducer;

export const getFilteredSearch = createSelector(
    state => state.search,
    (search) => search.list,
)

export const loadSearch = (client, db, query, skip) => (dispatch, getState) => {
    dispatch(
        searchApiCall({
            client: client,
            db: db,
            query: query,
            skip: skip,
            onStart: searchRequested.type,
            onSuccess: searchReceived.type,
            onError: searchRequestFailed.type
        })
    )
}