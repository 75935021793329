import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles, Grid, Typography, Card, FormControlLabel, 
    Checkbox, Button, Modal, Backdrop, Fade,
    Divider } from '@material-ui/core';

// Components
import ProductsInCart from './ProductInCart/productInCart';
import TermsAndConditions from '../../TermsAndConditions/termsAndConditions';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    helpingCard: {
        padding: '2%'
    },
    deliveryButton: {
        width: '100%'
    },
    terms: {
        color: 'blue',
        cursor: 'pointer'
    },
    greenText: {
        color: 'green'
    }
}));

const Cart = (props) =>{
    const classes = useStyles();
    const intl = useIntl();

    const [showTermsAndCond, setShowTermsAndCond] = useState(false);
    const [termCheck, setTermCheck] = useState(true);

    function handleChange(event){
        setTermCheck(event.target.checked);
    };

    return <Grid container direction='row' justify='space-evenly' alignItems='flex-start'>
        <Modal
            className={classes.modal}
            open={showTermsAndCond}
            onClose={()=>setShowTermsAndCond(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showTermsAndCond}>
                <Card style={{margin: '5%', maxHeight: '50%', overflow: 'auto'}}><TermsAndConditions></TermsAndConditions></Card>
            </Fade>
        </Modal>

        <Grid item>
            <Grid container direction='column' justify='center' alignItems='start'>
                {props._productsInCart.map((val, i)=>{
                    return <Grid item key={i}>
                        <ProductsInCart _product={val} _index={i}></ProductsInCart>
                    </Grid>
                })}
            </Grid>
        </Grid>
        <Grid item>
            <Card className={classes.helpingCard}>
                <Typography variant='h5' gutterBottom>
                    <FormattedMessage id='cart.total' 
                        defaultMessage='TOTAL'/>: <span className={classes.greenText}>{format(props._totalPrice, {
                        currency: 'isk',
                        showDecimals: 'NEVER',
                        thousandSeparator: ' '
                    })}</span>
                </Typography>
                <Divider/>
                <Typography><FormattedMessage id='cart.needhelp' 
                    defaultMessage='NEED HELP WITH YOUR ORDER?'/></Typography>
                <Typography>pukinn@pukinn.com</Typography>
                <Typography>PÚKINN, MÖRKIN 4 REYKJAVÍK, {intl.formatMessage({ id: 'iceland', defaultMessage: 'ICELAND' })}</Typography>
                <Typography>(+354)566 6820</Typography>
                <Typography className={classes.terms} onClick={()=>setShowTermsAndCond(true)}>
                    <FormattedMessage id='footer.column4.terms' 
                        defaultMessage='TERMS AND CONDITIONS'/>
                    </Typography>
                <FormControlLabel
                    control={<Checkbox checked={termCheck} onChange={handleChange} name='checkedA' />}
                    label={intl.formatMessage({ id: 'cart.termsmsg', defaultMessage: 'By checking the box you agree to our terms and conditions' })}
                />
                <Button 
                    className={classes.deliveryButton} 
                    variant='contained' 
                    color='secondary' 
                    disabled={!termCheck}
                    onClick={props._handleSetDeliveryPage}
                >
                    <FormattedMessage id='cart.continue' 
                        defaultMessage='CONTINUE TO DELIVERY'/>
                </Button>
            </Card>
        </Grid>
    </Grid>
}

export default Cart;