import * as actions from '../actions';

//Mongo Stitch
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';

const cart = ({ dispatch }) => next => action => {
    // console.log(action.cartCall)
    if(action.type !== actions.cartCall.type) return next(action)
    const { product, onStart } = action.payload

    // dispatch(actions.cartCallSucess(product))
    dispatch({ type: onStart, payload: product})
    return next(action)
}

export default cart;