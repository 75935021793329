import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Router
import { useLocation } from "react-router-dom";

//Mongo Stitch
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Material UI
import { makeStyles, Grid, FormControl, MenuItem, Select,
    InputLabel, Checkbox, ListItemText, Input } from '@material-ui/core/';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Store
import { getBrandsByProducts, loadBrandsByProducts } from '../../../../../store/brandsByProducts/brandsByProducts';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters = (props) => {
    const classes = useStyles();
    const { search } = useLocation();
    const dispatch = useDispatch();
    var brandsByProduct = useSelector(getBrandsByProducts);

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('lager');
            var q = {}
            if(props._category !== null){
                q['category'] = {};
                q['category'][props._category] = props._category
            }
            if(props._subCat !== null){
                q['subcategory'] = props._subCat;
            }
            dispatch(loadBrandsByProducts(client, db, q));
        }
    },[search, props._mongoClient])

    useEffect(()=>{
        props._handleChangeBrand([])
    },[brandsByProduct])

    return <Grid container spacing={1} justify='center'>
        <Grid item style={{width: '33%'}}>
            <FormControl className={classes.formControl}>
                <InputLabel><FormattedMessage 
                    id='filter.for' 
                    defaultMessage='FOR'
                /></InputLabel>
                <Select
                    value={props._gender}
                    onChange={props._handleChangeGender}
                >
                    <MenuItem value={'all'}><FormattedMessage 
                        id='filter.all' 
                        defaultMessage='ALL'
                    /></MenuItem>
                    <MenuItem value={'unisex'}><FormattedMessage 
                        id='filter.unisex' 
                        defaultMessage='UNISEX'
                    /></MenuItem>
                    <MenuItem value={'men'}><FormattedMessage 
                        id='filter.men' 
                        defaultMessage='MEN'
                    /></MenuItem>
                    <MenuItem value={'women'}><FormattedMessage 
                        id='filter.women' 
                        defaultMessage='WOMEN'
                    /></MenuItem>
                    <MenuItem value={'kids'}><FormattedMessage 
                        id='filter.kids' 
                        defaultMessage='KIDS'
                    /></MenuItem>
                </Select>
            </FormControl>
        </Grid>

        <Grid item style={{width: '33%'}}>
            <FormControl className={classes.formControl}>
                <InputLabel><FormattedMessage 
                    id='filter.price' 
                    defaultMessage='PRICE'
                /></InputLabel>
                <Select
                    value={props._priceRange}
                    onChange={props._handleChangePriceRange}
                >
                    <MenuItem value={1}><FormattedMessage 
                        id='filter.lowhigh' 
                        defaultMessage='LOW - HIGH'
                    /></MenuItem>
                    <MenuItem value={-1}><FormattedMessage 
                        id='filter.highlow' 
                        defaultMessage='HIGH - LOW'
                    /></MenuItem>
                </Select>
            </FormControl>
        </Grid>

        <Grid item style={{width: '33%'}}>
            <FormControl className={classes.formControl}>
                <InputLabel><FormattedMessage 
                    id='filter.brand' 
                    defaultMessage='BRAND'
                /></InputLabel>
                <Select
                    multiple
                    value={props._chosenBrands}
                    onChange={(e)=>props._handleChangeBrand(e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ').toUpperCase()}
                    MenuProps={MenuProps}
                >
                {brandsByProduct.map((brand, i) => {
                    if(brand.brand !== null) {
                        return <MenuItem key={i} value={brand.brand}>
                            <Checkbox checked={props._chosenBrands.indexOf(brand.brand) > -1} />
                            <ListItemText primary={brand.brand.toUpperCase()} />
                        </MenuItem>
                    }
                })}
                </Select>
            </FormControl>
        </Grid>
    </Grid>

}

export default Filters;