import React, { useState, useEffect } from 'react';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Material UI
import { makeStyles, Card, TextField, Grid,
    Button, Typography} from '@material-ui/core/';

// Axios
import axios from 'axios';

// Forms Utils
import { _submit, _send } from './forms_utils';

// reCaptcha Challenge
import ReCAPTCHA from 'react-google-recaptcha';
import recaptchaV2_site_key from '../../../config';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '25%'
    },
    padding: {
        padding: '1%'
    },
    fullWidth: {
        width: '100%'
    },
    redText: {
        color: 'red'
    }
}));

const IdeaForm = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const [email, setEmail] = useState('');
    const [idea, setIdea] = useState('')
    const [success, setSuccess] = useState(false)
    const [err, setErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [showCaptcha, setShowCaptcha] = useState(false)
    const [disableButton, setDisableButton] = useState(false)

    function closeError()
    {
        return setTimeout(()=>{
            setErrMsg('')
            setErr(false)
        }, 5000)
    }

    function submit() {
        var result = _submit(email, idea)
        if(result === 'invalid_email'){
            setErrMsg(<FormattedMessage 
                id='contactus.emailinvalid' 
                defaultMessage='EMAIL IS INVALID'
            />)
            setErr(true)
            closeError()
        } else if(result === 'invalid_body') {
            setErrMsg(<FormattedMessage 
                id='contactus.msgshort' 
                defaultMessage='MESSAGE IS TOO SHORT'
            />)
            setErr(true)
            closeError()
        } else {
            setShowCaptcha(true)
        }
    }

    function onChangeCaptcha(val) {
        var options = {
            "token": val
        }
        axios({
            method: 'post',
            url: 'https://us-central1-pukinn2.cloudfunctions.net/verifyRecaptcha',
            data: options
        }).then((res)=>{
            if(res.data.success){
                setDisableButton(true)
                var mailOptions = {
                    "title": props._title,
                    "body": idea,
                    "email": email,
                }
                axios({
                    method: 'post',
                    url: 'https://us-central1-pukinn2.cloudfunctions.net/sendIdea',
                    data: mailOptions
                }).then((res)=>{
                    setDisableButton(false)
                    setSuccess(true)
                    setTimeout(()=>{ props._closeForm() },5000)
                }).catch((err)=>{
                    setDisableButton(false)
                    setErrMsg('COULD NOT SEND THE MAIL. PLEASE TRY AGAIN')
                    setErr(true)
                    closeError()
                })
            } else {
                setErrMsg('CAPTCHA DID NOT WORK. PLEASE TRY AGAIN')
                setErr(true)
                closeError()
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    return <Card className={classes.root}>
        {!success ?
            <Grid container className={classes.padding} direction='column' justify='center' alignItems='center' spacing={1}>   
                <Grid item className={classes.fullWidth}>
                    <TextField label={<FormattedMessage 
                            id='contactus.youremail' 
                            defaultMessage='Your Email'
                        />}
                        type='email'
                        fullWidth
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <TextField
                        type='text'
                        multiline
                        fullWidth
                        placeholder={props._title+'...'}
                        rows={4}
                        value={idea}
                        onChange={(e)=>setIdea(e.target.value)}
                        helperText={`${intl.formatMessage({ id: 'contactus.mincharacters' })} ${idea.length} / 25`}
                    />
                </Grid>
                {err ?
                    <Grid item>
                        <Typography className={classes.redText} variant='body2' gutterBottom>{errMsg}</Typography>
                    </Grid> :
                    <div></div>
                }
                {
                    showCaptcha ?
                    <Grid item>
                        <ReCAPTCHA
                            sitekey={recaptchaV2_site_key}
                            onChange={onChangeCaptcha}
                        />
                    </Grid> :
                    <div></div>
                }
                <Grid item className={classes.fullWidth}>
                    <Button className={classes.fullWidth} variant='contained' color='secondary' onClick={submit} disabled={disableButton}>
                        {disableButton ? 'LOADING...': <FormattedMessage 
                            id='contactus.send' 
                            defaultMessage='SEND'
                        />}
                    </Button>
                </Grid>
            </Grid> :
            <Grid container justify='center' alignItems='center'>
                <Grid item className={classes.padding}>
                    <Typography variant='body2' gutterBottom>EMAIL SENT! THANK YOU FOR YOUR FEEDBACK!</Typography>
                </Grid>
            </Grid>
        }
    </Card>
}

export default IdeaForm;