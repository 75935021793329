import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl'; 

// Material UI
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Badge, MenuItem, 
  Menu, Fade, Modal, Backdrop, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreIcon from '@material-ui/icons/MoreVert';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import GroupIcon from '@material-ui/icons/Group';
import HelpIcon from '@material-ui/icons/Help';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import HomeIcon from '@material-ui/icons/Home';

// Moment
import moment from 'moment';

// Store
import { getFilteredCart } from '../../store/cart/cart';
import { getFilteredGiftCard } from '../../store/giftCard/giftCard';

// React Router
import { useHistory, Link } from 'react-router-dom';

// Pukinn Logo
import pukinnLogoWhite from '../../assets/pukinnlogowhite.png';

//Componentes
import CategoriesNav from './CategoriesNav/categoriesNav';
import Cart from './Cart/cart';
import SearchBar from './SearchBar/searchBar';
import DeactivateGiftCard from '../LandingPage/Pages/GiftCard/DeactivateGifCard/deactivateGiftCard';
import Languages from '../../languages/languages';

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    logo: {
      width: '15%',
      height: '100%',
      cursor: 'pointer'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    toolbar: {
      backgroundColor: '#212121',
    },
    infoText: {
      fontSize: '12px',
      marginRight: '2%',
      cursor: 'pointer'
      // whiteSpace: 'nowrap'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    mobileIcon: {
      color: 'black',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
}));

const MainNavBar = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [giftCardComponent, setGiftCardComponent] = useState({})
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBool, setErrorMsgBool] = useState(false)
  
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    var productsInCart = useSelector(getFilteredCart)
    var giftCard = useSelector(getFilteredGiftCard)
    useEffect(()=>{},[productsInCart])

    // useEffect(()=>{
    //   var giftCard = window.localStorage.getItem('pukinn_giftcard')
    //   console.log(giftCard)
    // },[window.localStorage.getItem('pukinn_giftcard')])

    useEffect(()=>{ 
      if(Object.keys(giftCard).length > 0) {
        setGiftCardComponent(giftCard)
      } else {
        setGiftCardComponent({})
      }
    },[giftCard])
  
    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };
  
    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };

    const showGiftCardInfo = () => {
      var eventdate = moment(giftCard.exp);
      var todaysdate = moment();
      var daysLeft = eventdate.diff(todaysdate, 'days');
      setErrorMsg(`GIFT CARD ACTIVE! CREDIT: ${giftCardComponent.credit} isk. GIFT CARD EXPIRES AFTER ${daysLeft} DAYS.`)
      setErrorMsgBool(true)
    }

    const giftCardC = () => {
      if(Object.keys(giftCardComponent).length > 0) {
        return <IconButton color='inherit' onClick={showGiftCardInfo}>
          <Badge max={1000000} badgeContent={giftCardComponent.credit} color='secondary'>
            <CardGiftcardIcon />
          </Badge>
        </IconButton> 
      } else {
        return  <div></div>
      }      
    }
  
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    );
  
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* <MenuItem
          onClick={props._handleDrawerOpen}
        >
          <IconButton 
            color='inherit'
          >
            <Badge badgeContent={productsInCart.length} color='secondary'>
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
          <p>CART</p>
        </MenuItem> */}
        <Link to='/' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <HomeIcon />
              </Badge>
            </IconButton>
            <p>HOME</p>
          </MenuItem>
        </Link>
        <Link to='/fitmybike' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <MotorcycleIcon />
              </Badge>
            </IconButton>
            <p>FIT MY BIKE</p>
          </MenuItem>
        </Link>
        <Link to='/bikebooking' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <DirectionsBikeIcon />
              </Badge>
            </IconButton>
            <p>BIKE BOOKING</p>
          </MenuItem>
        </Link>
        <Link to='/about' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <HelpIcon />
              </Badge>
            </IconButton>
            <p>ABOUT US</p>
          </MenuItem>
        </Link>
        <Link to='/teamPukinn' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <GroupIcon />
              </Badge>
            </IconButton>
            <p><FormattedMessage 
                id='teampukinn.teampukinn1' 
                defaultMessage='TEAM '
            /><FormattedMessage 
                id='teampukinn.teampukinn2' 
                defaultMessage=' PUKINN'
            /></p>
          </MenuItem>
        </Link>
        <Link to='/location' className={classes.mobileIcon} onClick={handleMobileMenuClose}>
          <MenuItem>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <LocationOnIcon />
              </Badge>
            </IconButton>
            <p>LOCATION</p>
          </MenuItem>
        </Link>
        {/* <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircleIcon />
          </IconButton>
          <p>SIGN IN</p>
        </MenuItem> */}
      </Menu>
    );

    return(
      <div className={classes.grow}>
        <Modal
          className={classes.modal}
          open={errorMsgBool}
          onClose={()=>setErrorMsgBool(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}
        >
          <Fade in={errorMsgBool}>
            <DeactivateGiftCard _message={errorMsg} _handleCloseGiftCardModal={()=>setErrorMsgBool(false)}></DeactivateGiftCard>
          </Fade>
        </Modal>
        <AppBar position='fixed'>
          <Toolbar className={classes.toolbar} variant='dense'>
              {props._isMobile ? <div></div>  : 
                <img onClick={()=> history.push('/')} className={classes.logo} src={pukinnLogoWhite}/>
              }
              <SearchBar _mongoClient={props._mongoClient}></SearchBar>
              {giftCardC()}
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                  <Languages></Languages>
                  <Link to='/fitmybike'><p className={classes.infoText}>FIT MY BIKE</p></Link>
                  <Link to='/teamPukinn'><p className={classes.infoText}><FormattedMessage 
                    id='teampukinn.teampukinn1' 
                    defaultMessage='TEAM '
                  /><FormattedMessage 
                    id='teampukinn.teampukinn2' 
                    defaultMessage=' PUKINN'
                  /></p></Link>
                  <Link to='/bikebooking'><p className={classes.infoText}><FormattedMessage 
                    id='bikebooking.bikebooking' 
                    defaultMessage='BIKE BOOKING'
                  /></p></Link>
                  <Link to='/about'><p className={classes.infoText}><FormattedMessage 
                    id='aboutus.aboutus' 
                    defaultMessage='ABOUT US'
                  /></p></Link>
                  <IconButton 
                    color='inherit'
                    onClick={props._handleDrawerOpen}
                  >
                    <Badge badgeContent={productsInCart.length} color='secondary'>
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                  <Link to='/location'>
                    <IconButton color='inherit'>
                        <Badge color='secondary'>
                            <LocationOnIcon />
                        </Badge>
                    </IconButton>
                  </Link>
                  {/* <IconButton
                      edge='end'
                      aria-label='account'
                      aria-controls={menuId}
                      aria-haspopup='true'
                      onClick={handleProfileMenuOpen}
                      color='inherit'
                  >
                      <AccountCircleIcon />
                  </IconButton> */}
              </div>
              <div className={classes.sectionMobile}>
                  <IconButton
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'
                  >
                    <MoreIcon />
                  </IconButton>
              </div>
          </Toolbar>
          <CategoriesNav 
            _isMobile={props._isMobile}
            _categories={props._categories}
          ></CategoriesNav>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Cart _openDrawer={props._openDrawer} _handleDrawerClose={props._handleDrawerClose}></Cart>
      </div>
    )
}

export default MainNavBar;