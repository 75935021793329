import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { deliveryCall } from '../actions';

const slice = createSlice({
    name: 'delivery',
    initialState: {
        list: {
            firstName: '',
            lastName: '',
            email: '',
            streetName: '',
            streetNumber: '',
            city: '',
            phone: '',
            country: 'ICELAND',
            district: 'HÖFUÐBORGASVÆÐI',
            zip: ''
        },
        loading: false,
        lastFetch: null
    },
    reducers: {
        deliveryAdded: (state, action) => {
            state.list = action.payload
            // window.localStorage.setItem('pukinn_delivery', JSON.stringify(action.payload));
        },
        deliveryRequested: (state, action) => {
            state.loading = true
        },

        deliveryRequestFailed: (state, action) => {
            state.loading = false
        },

        deliveryReceived: (state, action) => {
            var localDelivery = window.localStorage.getItem('pukinn_delivery');
            if(localDelivery !== null){
                // Object.keys(JSON.parse(localDelivery)).length > 0
                state.list = JSON.parse(localDelivery)
            } else {
                state.list = action.payload;
            }
            state.loading = false;
        },
    }
})
export const { deliveryAdded, deliveryRequested, deliveryRequestFailed, deliveryReceived } = slice.actions
export default slice.reducer;

export const getFilteredDelivery= createSelector(
    state => {
        var localDelivery = window.localStorage.getItem('pukinn_delivery');
        if(localDelivery !== null){
            // Object.keys(JSON.parse(localDelivery)).length > 0
            return JSON.parse(localDelivery)
        } else {
            return state.delivery
        }
    },
    (delivery) => { 
        var localDelivery = window.localStorage.getItem('pukinn_delivery');
        if(localDelivery !== null){
            // JSON.parse(localDelivery).length > 0
            return JSON.parse(localDelivery)
        } else {
            return delivery.list 
        }
    },
)

export const updateDelivery = (deliveryInfo) => (dispatch, getState) => {
    dispatch(
        deliveryCall({
            delivery: deliveryInfo,
            onStart: deliveryAdded.type,
        })
    )
}