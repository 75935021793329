import React, { useState } from 'react';

// FormatJS (Internationalization)
import { IntlProvider } from 'react-intl';
import English from './en-US.json';
import Icelandic from './is-IS.json';

export const Context = React.createContext();

const locale = navigator.language;

var lang;
if(locale === 'en-GB'){
    lang = English
} else {
    lang = Icelandic
}

const Wrapper = (props) => {
    const [locale1, setLocale] = useState(locale);
    const [messages, setMessages] = useState(lang);

    function selectLang(e) {
        const newLocale = e
        setLocale(newLocale);
        if(newLocale === 'is-IS') {
            setMessages(Icelandic)
        } else {
            setMessages(English)
        }
    }

    return <Context.Provider value={{locale1, selectLang}}>
        <IntlProvider messages={messages} locale={locale1}>
            {props.children}
        </IntlProvider>
    </Context.Provider>
}

export default Wrapper;