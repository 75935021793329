import React, { useState, useEffect } from 'react';

// Material UI
import { makeStyles, Button, Grid, Box, Fade } from '@material-ui/core/';

// Firebase
import * as firebase from 'firebase';

// React Router
import { useHistory, Link } from 'react-router-dom';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Components
import GiftCard from './GiftCard/giftCard';
import FeaturedProducts from './FeaturedProducts/featuredProducts';
import Game from '../../../Game/src/Game';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#212121',
        '&:hover': {
            backgroundColor: '#3a3a3a',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        color: 'white'
    },
    griditem: {
        padding: '10%'
    },
}));

const MainPage = (props) => {
    const classes = useStyles();

    const [client, setClient] = useState(null)
    const [db, setDB] = useState(null)
    const [landingImage, setLandingImage] = useState('')
    const [reload, setReload] = useState(1)
    const [fadeImage, setFadeImage] = useState(false)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        // const client = props._mongoClient
        // const db = props._mongoClient.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
        var landingPhoto = 'landing_desktop'
        if(props._isMobile){
            landingPhoto = 'landing_mobile'
        }
        firebase.storage().ref(`website_photos/${landingPhoto}`).getDownloadURL()
            .then((res)=>{
                setLandingImage(res)
                classes.backgroundImage = landingImage
                setFadeImage(true)
                // setGame(false)
            })
            .catch((e)=>{
                console.log('error')
            })
    },[props])

    return(
        <div>
            {/* {navigator.onLine ? */}
            <Grid container direction='column'>
                <Grid item>
                    <Fade in={fadeImage} timeout={3000}>
                        <Grid style={{
                                backgroundImage: `url("${landingImage}")`,
                                height: '100vh',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }} 
                            container 
                            direction='row' 
                            justify='flex-start' 
                            alignItems='flex-end'
                        >
                            <Grid item className={classes.griditem}>
                                <Link to='/brands'>
                                    <Button className={classes.button} size='large' variant='contained'>
                                    <FormattedMessage 
                                        id='mainpage.bybrands' 
                                        defaultMessage='SHOP BY BRANDS '
                                    /></Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
                {/* <Grid item>
                    <FeaturedProducts _mongoClient={props._mongoClient}></FeaturedProducts>
                </Grid> */}
                <Grid item>
                    <GiftCard></GiftCard>
                </Grid>
            </Grid>
        </div>
    )
}

export default MainPage;