import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles, Grid, Card, Typography, Divider,
    Button, TextField, FormControl, Select, InputLabel,
    MenuItem, Modal, Fade, Backdrop, FormGroup,
    FormControlLabel, Checkbox } from '@material-ui/core';

// Email Validator
import * as EmailValidator from 'email-validator';

// FormatJS (Internationalization)
import { FormattedMessage, useIntl } from 'react-intl';

// Store
import { getFilteredDelivery, updateDelivery } from '../../../../../store/delivery/delivery';

// Component
import ErrorModal from '../../Product/ErrorModal/errorModal';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    helpingCard: {
        padding: '2%'
    },
    paymentButton: {
        width: '100%'
    },
    greenText: {
        color: 'green'
    },
    redText: {
        color: 'red'
    },
    fullWidth: {
        width: '100%'
    },
    cotnainerPadding: {
        padding: '3%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Delivery = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    var deliveryInfo = useSelector(getFilteredDelivery)
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('')
    const [helperFirstName, setHelperFirstName] = useState('Missing First Name')
    const [validFirstName, setValidFirstName] = useState(false)

    const [lastName, setLastName] = useState('')
    const [helperLastName, setHelperLastName] = useState('Missing Last Name')
    const [validLasttName, setValidLastName] = useState(false)

    const [email, setEmail] = useState('')
    const [helperEmail, setHelperEmail] = useState('Invalid Email')
    const [validEmail, setValidEmail] = useState(false)

    const [streetName, setStreetName] = useState('')
    const [helperStreetName, setHelperStreetName] = useState('Missing Street Name')
    const [validStreetName, setValidStreetName] = useState(false)

    const [streetNumber, setStreetNumber] = useState('')
    const [helperStreetNumber, setHelperStreetNumber] = useState('Missing Street Number')
    const [validStreetNumber, setValidStreetNumber] = useState(false)

    const [city, setCity] = useState('')
    const [helperCity, setHelperCity] = useState('Missing City Name')
    const [validCity, setValidCity] = useState(false)

    const [tel, setTel] = useState('')
    const [helperTel, setHelperTel] = useState('Phone Number Mising')
    const [validTel, setValidTel] = useState(false)

    const [country, setCountry] = useState('ICELAND')
    const [district, setDistrict] = useState('HÖFUÐBORGASVÆÐI')

    const [zip, setZip] = useState('')
    const [helperZip, setHelperZip] = useState('Missing Zip/Postal Code')
    const [validZip, setValidZip] = useState(false)

    const [errorMsgBool, setErrorMsgBool] = useState(false)
    const [errorMsg, setErrMessage] = useState('')

    const [saveDeliveryInfo, setSaveDeliveryInfo] = useState(false)

    useEffect(()=>{
        var stored_delivery_info = window.localStorage.getItem('pukinn_delivery')
        console.log(stored_delivery_info)
        setFirstName(deliveryInfo.firstName)
        if(deliveryInfo.firstName.length > 0){
            setValidFirstName(true)
            setHelperFirstName('')
        }
        setLastName(deliveryInfo.lastName)
        if(deliveryInfo.lastName.length > 0){
            setValidLastName(true)
            setHelperLastName('')
        }
        setEmail(deliveryInfo.email)
        if(EmailValidator.validate(deliveryInfo.email)){
            setValidEmail(true)
            setHelperEmail('')
        }
        setStreetName(deliveryInfo.streetName)
        if(deliveryInfo.streetName.length > 0){
            setValidStreetName(true)
            setHelperStreetName('')
        }
        setStreetNumber(deliveryInfo.streetNumber)
        if(deliveryInfo.streetNumber.length > 0){
            setValidStreetNumber(true)
            setHelperStreetNumber('')
        }
        setCity(deliveryInfo.city)
        if(deliveryInfo.city.length > 0){
            setValidCity(true)
            setHelperCity('')
        }
        setTel(deliveryInfo.phone)
        if(deliveryInfo.phone.length > 0){
            setValidTel(true)
            setHelperTel('')
        }
        setCountry(deliveryInfo.country)
        setDistrict(deliveryInfo.district)
        setZip(deliveryInfo.zip)
        if(deliveryInfo.zip.length > 0){
            setValidZip(true)
            setHelperZip('')
        }
    },[window.localStorage.getItem('pukinn_delivery')])

    function handleSetPayment(){
        if(validFirstName && validLasttName && validEmail && validStreetName && validStreetNumber && validCity && validTel && validZip){
            var deliveryObject = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                streetName: streetName,
                streetNumber: streetNumber,
                city: city,
                phone: tel,
                country: country,
                district: district,
                zip: zip
            }
            dispatch(updateDelivery(deliveryObject))
            if(saveDeliveryInfo){
                window.localStorage.setItem('pukinn_delivery', JSON.stringify(deliveryObject))
                // dispatch(updateDelivery(deliveryObject))
            }
            props._handleSetPaymentPage()
        } else {
            setErrorMsgBool(true)
            setErrMessage('Some Fields Are Missing')
        }
    }

    function handleChange(name, value) {
        if(name === 'firstName') {
            setFirstName(value)
            if(value.length > 1 && !value.trim() === false) {
                setHelperFirstName('')
                setValidFirstName(true)
            } else {
                setHelperFirstName('Missing First Name')
                setValidFirstName(false)
            }
        } else if(name === 'lastName') {
            setLastName(value)
            if(value.length > 2 && !value.trim() === false) {
                setHelperLastName('')
                setValidLastName(true)
            } else {
                setHelperLastName('Missing Last Name')
                setValidLastName(false)
            }
        } else if(name === 'email') {
            setEmail(value)
            if(!value.trim() === false && EmailValidator.validate(email)) {
                setHelperEmail('')
                setValidEmail(true)
            } else {
                setHelperEmail('Invalid Email')
                setValidEmail(false)
            }
        } else if(name === 'streetName') {
            if(value.match(/^[a-zA-Z, æ, Æ, ð, Ð, ö, Ö, í, Í, ó, Ó, þ, Þ, á, Á, é, É, ú, Ú, ý, Ý, ´]+$/) || value === ''){
                setStreetName(value)
            }
            if(value.length > 2 && !value.trim() === false) {
                setHelperStreetName('')
                setValidStreetName(true)
            } else {
                setHelperStreetName('Missing Street Name')
                setValidStreetName(false)
            }
        } else if(name === 'streetNumber') {
            if(value.match(/^[0-9]+$/) || value === ''){
                setStreetNumber(value)
            }
            if(value.length > 0 && !value.trim() === false) {
                setHelperStreetNumber('')
                setValidStreetNumber(true)
            } else {
                setHelperStreetNumber('Missing Street Number')
                setValidStreetNumber(false)
            }
        } else if(name === 'city') {
            if(value.match(/^[a-zA-Z, æ, Æ, ð, Ð, ö, Ö, í, Í, ó, Ó, þ, Þ, á, Á, é, É, ú, Ú, ý, Ý, ´]+$/) || value === ''){
                setCity(value)
            }
            if(value.length > 0 && !value.trim() === false) {
                setHelperCity('')
                setValidCity(true)
            } else {
                setHelperCity('Missing City Name')
                setValidCity(false)
            }
        } else if(name === 'tel') {
            if(value.match(/^[0-9+]+$/) || value === ''){
                setTel(value)
            }
            if(value.length > 6 && !value.trim() === false) {
                setHelperTel('')
                setValidTel(true)
            } else {
                setHelperTel('Missing Phone Number')
                setValidTel(false)
            }
        } else if(name === 'zip') {
            if(value.match(/^[0-9]/) || value === ''){
                setZip(value)
            }
            if(value.length > 2 && !value.trim() === false) {
                setHelperZip('')
                setValidZip(true)
            } else {
                setHelperZip('Missing Zip/Postal Code')
                setValidZip(false)
            }
        }
    }

    return <Grid container direction='row' justify='space-evenly' alignItems='flex-start'>
        <Modal
            className={classes.modal}
            open={errorMsgBool}
            onClose={()=>setErrorMsgBool(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={errorMsgBool}>
                <ErrorModal _message={errorMsg}></ErrorModal>
            </Fade>
        </Modal>
        <Grid item lg={6} xs={12}>
            <Grid container justify='center' alignItems='center' className={classes.cotnainerPadding}>
                <Grid item lg={6} xs={12}>
                    <TextField 
                        value={firstName}
                        onChange={(e)=>handleChange('firstName', e.target.value)} 
                        type='text'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.firstname', defaultMessage: 'FIRST NAME' })}
                        helperText={<span className={classes.redText}>{helperFirstName}</span>}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField
                        value={lastName}
                        onChange={(e)=>handleChange('lastName', e.target.value)} 
                        type='text'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.lastname', defaultMessage: 'LAST NAME' })} 
                        helperText={<span className={classes.redText}>{helperLastName}</span>}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField 
                        value={email}
                        onChange={(e)=>handleChange('email', e.target.value)} 
                        type='email'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.email', defaultMessage: 'EMAIL' })}
                        helperText={<span className={classes.redText}>{helperEmail}</span>}
                    />
                </Grid>
                <Grid item lg={4} xs={6}>
                    <TextField 
                        value={streetName}
                        onChange={(e)=>handleChange('streetName', e.target.value)} 
                        type='text'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.streetname', defaultMessage: 'STREET NAME' })}
                        helperText={<span className={classes.redText}>{helperStreetName}</span>}
                    />
                </Grid>
                <Grid item lg={2} xs={6}>
                    <TextField 
                        value={streetNumber}
                        onChange={(e)=>handleChange('streetNumber', e.target.value)} 
                        type='number'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.streetnumber', defaultMessage: 'STREET NUMBER' })}
                        helperText={<span className={classes.redText}>{helperStreetNumber}</span>}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField 
                        value={city}
                        onChange={(e)=>handleChange('city', e.target.value)}  
                        type='text'
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.city', defaultMessage: 'CITY' })}
                        helperText={<span className={classes.redText}>{helperCity}</span>} 
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField 
                        value={tel}
                        onChange={(e)=>handleChange('tel', e.target.value)}  
                        className={classes.fullWidth} 
                        label={intl.formatMessage({ id: 'delivery.phone', defaultMessage: 'PHONE NUMBER' })}
                        type='tel'
                        helperText={<span className={classes.redText}>{helperTel}</span>} 
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <FormControl className={classes.fullWidth}>
                        <InputLabel><FormattedMessage 
                            id='delivery.country' 
                            defaultMessage='COUNTRY'
                        /></InputLabel>
                        <Select
                            value={country}
                            onChange={(e)=>setCountry(e.target.value)}
                        >
                            <MenuItem value={'ICELAND'}><FormattedMessage 
                                id='iceland' 
                                defaultMessage='ICELAND'
                            /></MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                { country === 'ICELAND' ?
                <Grid item lg={6} xs={12}>
                    <FormControl className={classes.fullWidth}>
                        <InputLabel><FormattedMessage 
                            id='delivery.region' 
                            defaultMessage='REGION'
                        /></InputLabel>
                        <Select
                            value={district}
                            onChange={(e)=>setDistrict(e.target.value)}
                        >
                            <MenuItem value={'AUSTURLAND'}>AUSTURLAND</MenuItem>
                            <MenuItem value={'HÖFUÐBORGASVÆÐI'}>HÖFUÐBORGASVÆÐI</MenuItem>
                            <MenuItem value={'NORÐURLAND EYSTRA'}>NORÐURLAND EYSTRA</MenuItem>
                            <MenuItem value={'NORÐURLAND VESTRA'}>NORÐURLAND VESTRA</MenuItem>
                            <MenuItem value={'SUÐURLAND'}>SUÐURLAND</MenuItem>
                            <MenuItem value={'VESTFIRÐIR'}>VESTFIRÐIR</MenuItem>
                            <MenuItem value={'VESTURLAND'}>VESTURLAND</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> : <div></div> }
                <Grid item lg={12} xs={12}>
                    <TextField 
                        value={zip}
                        onChange={(e)=>handleChange('zip', e.target.value)} 
                        className={classes.fullWidth} 
                        label='ZIP / POSTAL CODE' 
                        type='number'
                        helperText={<span className={classes.redText}>{helperZip}</span>} 
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item lg={6} xs={12} className={classes.cotnainerPadding}>
            <Card className={classes.helpingCard}>
                <Typography><FormattedMessage id='cart.needhelp' 
                    defaultMessage='NEED HELP WITH YOUR ORDER?'/></Typography>
                <Typography>pukinn@pukinn.com</Typography>
                <Typography>PÚKINN, MÖRKIN 4 REYKJAVÍK, {intl.formatMessage({ id: 'iceland', defaultMessage: 'ICELAND' })}</Typography>
                <Typography>(+354)566 6820</Typography>
                <Divider/>
                <Typography variant='h5' gutterBottom><FormattedMessage id='cart.total' 
                    defaultMessage='TOTAL'/>: <span className={classes.greenText}>{format(props._totalPrice, {
                        currency: 'isk',
                        showDecimals: 'NEVER',
                        thousandSeparator: ' '
                    })}</span>
                </Typography>
                <Divider/>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={saveDeliveryInfo} onChange={(e)=>setSaveDeliveryInfo(e.target.checked)} />} 
                    label={intl.formatMessage({ id: 'delivery.savedeliveryinfo', defaultMessage: 'Save Delivery Info for future purchases'})}/>
                </FormGroup>
                <Button 
                    className={classes.paymentButton} 
                    variant='contained' 
                    color='secondary' 
                    onClick={handleSetPayment}
                >
                    <FormattedMessage id='delivery.continue' 
                    defaultMessage='CONTINUE TO PAYMENT'/>
                </Button>
            </Card>
        </Grid>
    </Grid>
}

export default Delivery;