import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { modelsApiCall } from '../actions';

const slice = createSlice({
    name: 'models',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        modelsRequested: (state, action) => {
            state.loading = true
        },

        modelsRequestFailed: (state, action) => {
            state.loading = false
        },

        modelsReceived: (state, action) => {
            state.list = action.payload;
            state.loading = false;
        },
    }
})
export const { client, db, query, modelsRequested, modelsRequestFailed, modelsReceived } = slice.actions
export default slice.reducer;

export const getFilteredModels = createSelector(
    state => state.models,
    (models) => models.list,
)

export const loadModels = (client, db, query) => (dispatch, getState) => {
    dispatch(
        modelsApiCall({
            client: client,
            db: db,
            query: query,
            onStart: modelsRequested.type,
            onSuccess: modelsReceived.type,
            onError: modelsRequestFailed.type
        })
    )
}