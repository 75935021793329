import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl';

// Material UI
import { makeStyles, Grid, Typography, Card, CardContent,
        CardMedia, Box } from '@material-ui/core/';

// Store
import { loadWorkers, getFilteredWorkers } from '../../../../store/workers/workers';

// MongoDB Atlas
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

// Components
import MummiSpecialCard from './MummiSpecialCard/mummiSpecialCard';
import Intro from './Intro/intro';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '5%',
    },
    text: {
        color: 'black',
    },
    textRed: {
        color: 'red'
    },
    card: {
        height: '100%',
    },
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
}));

const About = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const workers = useSelector(getFilteredWorkers)

    useEffect(()=>{window.scrollTo(0, 0)})

    useEffect(()=>{
        const client = props._mongoClient
        if(client !== null) {
            const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('website');
            dispatch(loadWorkers(client, db));
        }
    },[props])

    return <div>
        <Intro _isMobile={props._isMobile}></Intro>
        <div className={classes.root}>
            <Grid container justify='center'>
                <Grid item xs={10}>  
                    <Box textAlign="left">
                        <Typography gutterBottom variant="h5" component="h2" className={classes.text}>
                            <FormattedMessage 
                                id='aboutus.our' 
                                defaultMessage='OUR '
                            />
                            <span className={classes.textRed}><FormattedMessage 
                                id='aboutus.awesome' 
                                defaultMessage=' AWESOME '
                            /></span> <FormattedMessage 
                                id='aboutus.team' 
                                defaultMessage=' TEAM'
                            />
                        </Typography>
                    </Box>
                    <Box textAlign="left">
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                            <FormattedMessage 
                                id='aboutus.welcome' 
                                defaultMessage='WELCOME! WE ARE HERE FOR YOU ;)'
                            />
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <MummiSpecialCard></MummiSpecialCard>
                        {workers.map((worker, i)=>{
                            return <Grid item xs={12} sm={4} key={i}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={worker.photo}
                                        title={worker.name}
                                    />
                                    <CardContent>
                                        <Box textAlign="left">
                                            <Typography gutterBottom variant="h5" component="h2" className={classes.text}>
                                                {worker.name.toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box textAlign="left">
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                                                {worker.position.toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box textAlign="left">
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                                                {worker.sign.toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box textAlign="left">
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                                                {worker.description.toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box textAlign="left">
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                                                { worker.favfood.length > 0 
                                                    ? <span>FAVORITE FOOD: { worker.favfood.toUpperCase() }</span> 
                                                    : <span></span> }
                                            </Typography>
                                        </Box>
                                        <Box textAlign="left">
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                                                { worker.favfood.length > 0 
                                                    ? <span><span className={classes.textRed}>SLOGAN:</span> { worker.slogan.toUpperCase() }</span> 
                                                    : <span></span> }
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> 
                        })}
                    </Grid> 
                </Grid>
            </Grid>
        </div>
    </div>
}

export default About;

