import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

// FormatJS (Internationalization)
import { FormattedMessage } from 'react-intl'; 

const useStyles = makeStyles((theme) => ({
    paper: {
        // backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
        color: 'black'
        // margin: '35%'
    },
    redText: {
        color: 'red'
    },
    price: {
        fontWeight: 'bold'
    }
}));

const ReadMore = () => {
    const classes = useStyles();
    return <div className={classes.paper}>
        <Typography align='left'>
            <FormattedMessage 
                id='bikebooking.infoline1' 
                defaultMessage='YOU CAN BOOK A BIKE FOR 24 HOURS. MONDAYS UNTIL SATURDAYS!'
            />
        </Typography>
        <Typography align='left'>
            <FormattedMessage 
                id='bikebooking.infoline2' 
                defaultMessage='YOU CAN PICK YOUR BIKE UP AT 10am AND RETURN IT THE NEXT DAY AT 10am.'
            />
        </Typography>
        {/* <Typography>
            You can find all about the bike <a className={classes.redText} href='https://www.ghost-bikes.com/en/bikes/fully/bike/sl-amr-27-al/'>here</a>.
        </Typography>
        <Typography>
            You can find all about the bike <a className={classes.redText} href='https://www.ghost-bikes.com/en/bikes/fully/bike/sl-amr-27-al/'>here</a>.
        </Typography> */}
        <Typography align='left'>
            <FormattedMessage 
                id='bikebooking.infoline3' 
                defaultMessage='YOU CAN BOOK A BIKE FOR 24 HOURS FOR '
            />
            <span className={classes.price}>12.000 isk</span>.
        </Typography>
        <Typography align='left'>
            <FormattedMessage 
                id='bikebooking.infoline4' 
                defaultMessage='PICK UP AND PAYMENT IS DONE AT PÚKINN, MÖRKIN 5.'
            />
        </Typography>
        <Typography align='left'>
            <FormattedMessage 
                id='bikebooking.infoline5' 
                defaultMessage='PLEASE RETURN THE BIKE A DAY AFTER AT 10am AT PÚKINN, MÖRKIN 5.'
            />
        </Typography>
    </div>
}

export default ReadMore;