import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles, Grid, Typography, Card, CardContent, 
    CardActions, CardHeader, Paper } from '@material-ui/core/';

// Knight Demon
import knightdemon from '../../../../../../assets/icons/knight_demon.png';
import rifan from '../../../../../../assets/rifan_litil-min.png';

// React Router
import { Link } from "react-router-dom";

import StarBorderIcon from '@material-ui/icons/StarBorder';

// Price Format
const { format } = require('number-currency-format');

const useStyles = makeStyles((theme) => ({
    imgContainer: {
        position: 'relative'
    },
    media: {
        height: '15rem',
        objectFit: 'contain'
    },
    rifan: {
        height: '10rem',
        objectFit: 'contain',
        position: 'absolute',
        top:'35%',
        left: '0%'
    },
    price: {
        color: 'red'
    },
    greenPrice: {
        color: 'green'
    },
    redPrice: {
        color: 'red',
        textDecoration: 'line-through'
    },
    discount: {
        backgroundColor: '#ED1C24',
        borderRadius: '0% 0% 0% 50%',
        color: 'white',
        textAlign: 'center',
        display: 'table-cell',
        bottom: '75%',
        right: '0%',
        height: '25%',
        width: '25%',
        margin: 'auto'
    },
    cartActionText: {
        margin: 'auto',
        color: 'red',
    },

    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const FeaturedProduct = (props) => {
    const classes = useStyles();

    return <Card>
        <CardHeader
            action={props._details.discount > 0 ?
                <div className={classes.discount}><p>{props._details.discount}%</p></div> :
                <div></div>
            }
            title={
                <Typography variant='subtitle1' gutterBottom>{props._details.description.toUpperCase()}</Typography>
            }
            subheader={
                props._details.discount > 0 ?
                    <Grid container direction='row' justify='center' spacing={2}>
                        <Grid item>
                            <Typography variant='subtitle1' gutterBottom>{
                                <span className={classes.redPrice}>{format(props._details.barcode, {
                                    showDecimals: 'NEVER',
                                    thousandSeparator: ' '
                                })}</span>
                            }</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1' gutterBottom>{
                                <span className={classes.greenPrice}>{format(Math.ceil(parseInt(props._details.barcode) - (parseInt(props._details.barcode) * props._details.discount / 100)), {
                                    currency: 'isk',
                                    showDecimals: 'NEVER',
                                    thousandSeparator: ' '
                                })}</span>}
                            </Typography>
                        </Grid>
                    </Grid> :
                    <Typography className={classes.greenPrice} variant='subtitle1' gutterBottom>{
                    format(props._details.barcode, {
                        currency: 'isk',
                        showDecimals: 'NEVER',
                        thousandSeparator: ' '
                    })}</Typography>
                
            }
        />
        <Link to={`/product?id=${props._details._id}`}>
            <div className={classes.imgContainer}>
                {props._details.images.length > 0 ?
                    <img className={classes.media} src={props._details.images[0]}/> :
                    <img className={classes.media} src={knightdemon}/>
                }
                <img className={classes.rifan} src={rifan}/>
            </div>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props._details.itemdescription.replaceAll('°', '').substring(0, 100) + '...'}
                </Typography>
            </CardContent>
        </Link>
        { props._details.package !== null ?
            <div>{ props._details.package.length > 0 ?
                <CardActions disableSpacing style={{backgroundColor: 'yellow'}}>
                    <Typography variant="body2" component="p" className={classes.cartActionText}>
                        COMES AS PACKAGE
                    </Typography>
                </CardActions> :
                <div></div>
            }</div> : <div></div>
        }
    </Card>
}

export default FeaturedProduct;