import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// FormatJS (Internationalization)
import { Context } from '../../../../languages/Wrapper';

// Material UI
import Grid from '@material-ui/core/Grid';

// React Spring for Animation
import { useSpring, animated } from 'react-spring';

// React Router
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    list: {
        display: 'inline'
    },
    container: {
        backgroundColor: '#e0e0e0',
    },
    tag: {
        color: '#ED1C24',
        marginTop: '5%',
        zIndex: -10
    },
    subcategory: {
        color: 'black',
        fontSize: '15px',
        cursor: 'pointer',
        marginTop: '3%',
        zIndex: -10
    }
}));

const SubCategories = (props) => {
    const classes = useStyles();
    const context = useContext(Context);
    const history = useHistory();
    const [subCategories, setSubCategories] = useState([])
    const [category, setCategory] = useState('');

    //React Spring Props
    const springProps = useSpring({from: {opacity: 0, marginLeft: -500}, to: {opacity: 1, marginLeft: 0}})

    useEffect(()=>{
        setCategory(props._category)
        // setSubCategories(props._subCategories);
        var sortedSubCats = []
        if(props._subCategories.length > 0) {
            if(context.locale1.includes('en')) {
                sortedSubCats = props._subCategories.slice().sort((a, b) => a.name.localeCompare(b.name))
            } else {
                sortedSubCats = props._subCategories.slice().sort((a, b) => a.nameis.localeCompare(b.nameis))
            }
        }
        setSubCategories(sortedSubCats);
    },[props])

    function handleRouting(cat, subc){
        history.push(`/shop?category=${cat}&subCat=${subc.replace(/\s/g, '').toLowerCase()}`);
        props._closeSubcategories()
    }

    return(
        <Grid className={classes.container} container justify='space-around'>
            {subCategories.map(e => e['tag'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter((e) => subCategories[e]).map((e, indexOfTags) => {
                    return <div key={indexOfTags}>
                        <Grid item className={classes.tag}>
                            { context.locale1.includes('en') ? subCategories[e].tag.toUpperCase() : subCategories[e].tagis.toUpperCase()}
                        </Grid>
                        {subCategories.map((subcategory, indexOfSubcategory)=>{
                            if(subcategory.tag === subCategories[e].tag){
                                return <Grid 
                                    key={indexOfSubcategory} 
                                    className={classes.subcategory}
                                    onClick={()=>handleRouting(category, subcategory.name)}
                                >
                                    { context.locale1.includes('en') ? subcategory.name.toUpperCase() : subcategory.nameis.toUpperCase()}
                                </Grid>
                            }
                        })}
                    </div>
                })
            }
        </Grid>
    )
}

export default SubCategories;