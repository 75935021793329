import { createAction } from '@reduxjs/toolkit';

export const categoriesApiCall = createAction('categoriesApi/Call')
export const categoriesApiCallSucess = createAction('categoriesApi/CallSucess')
export const categoriesApiCallFailed = createAction('categoriesApi/CallFailed')

export const faqApiCall = createAction('faq/Call')
export const faqApiCallSucess = createAction('faq/CallSucess')
export const faqApiCallFailed = createAction('faq/CallFailed')

export const workersApiCall = createAction('workers/Call')
export const workersApiCallSucess = createAction('workers/CallSucess')
export const workersApiCallFailed = createAction('workers/CallFailed')

export const membersApiCall = createAction('members/Call')
export const membersApiCallSucess = createAction('members/CallSucess')
export const membersApiCallFailed = createAction('members/CallFailed')

export const brandsApiCall = createAction('brands/Call')
export const brandsApiCallSucess = createAction('brands/CallSucess')
export const brandsApiCallFailed = createAction('brands/CallFailed')

export const brandsByProductsApiCall = createAction('brandsByProducts/Call')
export const brandsByProductsApiCallSucess = createAction('brandsByProducts/CallSucess')
export const brandsByProductsApiCallFailed = createAction('brandsByProducts/CallFailed')

export const productsApiCall = createAction('products/Call')
export const productsApiCallSucess = createAction('products/CallSucess')
export const productsApiCallFailed = createAction('products/CallFailed')

export const productApiCall = createAction('product/Call')
export const productApiCallSucess = createAction('product/CallSucess')
export const productApiCallFailed = createAction('product/CallFailed')

export const cartCall = createAction('cart/Call')
export const cartCallSucess = createAction('cart/CallSucess')
export const cartApiCallFailed = createAction('cart/CallFailed')

export const deliveryCall = createAction('delivery/Call')
export const deliveryCallSucess = createAction('delivery/CallSucess')
export const deliveryApiCallFailed = createAction('delivery/CallFailed')

export const makesApiCall = createAction('makes/Call')
export const makesApiCallSucess = createAction('makes/CallSucess')
export const makesApiCallFailed = createAction('makes/CallFailed')

export const modelsApiCall = createAction('models/Call')
export const modelsApiCallSucess = createAction('models/CallSucess')
export const modelsApiCallFailed = createAction('models/CallFailed')

export const yearsApiCall = createAction('years/Call')
export const yearsApiCallSucess = createAction('years/CallSucess')
export const yearsApiCallFailed = createAction('years/CallFailed')

export const fitsApiCall = createAction('fits/Call')
export const fitsApiCallSucess = createAction('fits/CallSucess')
export const fitsApiCallFailed = createAction('fits/CallFailed')

export const searchApiCall = createAction('search/Call')
export const searchApiCallSucess = createAction('search/CallSucess')
export const searchApiCallFailed = createAction('search/CallFailed')

export const packagePricingApiCall = createAction('packagePricing/Call')
export const packagePricingApiCallSucess = createAction('packagePricing/CallSucess')
export const packagePricingApiCallFailed = createAction('packagePricing/CallFailed')

export const bikeBookingApiCall = createAction('bikeBooking/Call')
export const bikeBookingApiCallSucess = createAction('bikeBooking/CallSucess')
export const bikeBookingApiCallFailed = createAction('bikeBooking/CallFailed')

export const giftCardApiCall = createAction('giftCard/Call')
export const giftCardApiCallSucess = createAction('giftCard/CallSucess')
export const giftCardApiCallFailed = createAction('giftCard/CallFailed')

export const featuredProductsApiCall = createAction('featuredProducts/Call')
export const featuredProductsApiCallSucess = createAction('featuredProducts/CallSucess')
export const featuredProductsApiCallFailed = createAction('featuredProducts/CallFailed')

